import { Router } from 'vue-router';

const routes = [
  {
    path: '/offices',
    name: 'offices',
    component: () => import('@/views/RoadBOOK/selectOfficeVue.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
      },
    },
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import('@/views/RoadBOOK/LanguagesVue.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
        haveRoadbook: true,
        haveOffice: true,
      },
    },
  },
  {
    path: '/select',
    name: 'roadbook_select',
    component: () => import('@/views/RoadBOOK/RoadBOOKSelection/RoadbookSelection.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
        haveRoadbook: true,
        haveOffice: true,
      },
    },
  },
  {
    path: '/edit',
    name: 'roadbook_edit',
    component: () => import('@/views/RoadBOOK/RoadbookEdit/RoadbookEdit.vue'),
    meta: {
      previous: '/languages',
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
        haveRoadbook: true,
        haveOffice: true,
      },
    },
  },
  {
    path: '/clientInfo',
    name: 'client_info',
    component: () => import('@/views/RoadBOOK/ClientInfo/ClientInfo.vue'),
    meta: {
      previous: '/edit',
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
        haveRoadbook: true,
        haveOffice: true,
      },
    },
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('@/views/RoadBOOK/Preview/PreviewVue.vue'),
    meta: {
      previous: '/clientInfo',
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
        haveRoadbook: true,
        haveOffice: true,
      },
    },
  },
  {
    path: '/updateUser',
    name: 'updateUser',
    component: () => import('@/views/Advisors/components/RcUpdateUser.vue'),
    meta: {
      previous: '/languages',
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
        haveRoadbook: true,
        haveOffice: true,
      },
    },
  },
  {
    path: '/mobileRoadBookPreview',
    name: 'mobileRoadBookPreview',
    component: () => import('@/views/RoadBOOK/Preview/mobileRoadBookPreview.vue'),
    meta: {
      previous: '/edit',
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
        haveRoadbook: true,
        haveOffice: true,
      },
    },
  },
  {
    path: '/looker',
    name: 'looker',
    component: () => import('@/views/Report/RcLooker.vue'),
    meta: {
      previous: '/init',
      must: {
        beLogged: true,
        havePermission: true,
      },
    },
  },
];

export default function (router: Router) {
  routes.map((value) => router.addRoute(value));
}
