<template lang="pug">
.optin-field
  label.optin-checkbox-wrapper(:for="`optin-cb-${index}-${indexInRow}`" :class="{'disabled': disabled}")
    div(v-if="label") {{ $t('client_info.newsletter_optin', needTranslate) }} {{ entityTitle }}
    input.optin-cb.checkbox(@click="(e) => clickEnabled(e)" :id="`optin-cb-${index}-${indexInRow}`" type="checkbox" :checked="valueMutable" :disabled="disabled")
    .checkbox-indicator
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import mixin from '@/utils/mixins/RcMixin.vue';
import { useConfigStore } from '@/store/config';
import { useRoadbookStore } from '@/store/roadbook';

export default defineComponent({
  mixins: [mixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    indexInRow: {
      type: Number,
      default: 0,
    },
  },
  emits: ['input'],
  data() {
    return {
      valueMutable: false as boolean,
    };
  },
  computed: {
    entityTitle(): string {
      useConfigStore().config.feeds?.forEach((feed) => {
        if (feed.lang == this.language && feed.entity_id == useRoadbookStore().currentEntity.id) {
          return `${this.$t('from')} ${feed.title}`;
        }
      });
      return '';
    },
  },
  watch: {
    value(): void {
      this.valueMutable = this.value;
    },
  },
  mounted(): void {
    this.valueMutable = this.value;
  },
  methods: {
    clickEnabled(e: MouseEvent): void {
      if (this.disabled) {
        e.preventDefault();
      } else {
        this.$emit('input', (e.target as HTMLInputElement).checked);
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.optin-field {
  .optin-checkbox-wrapper {
    @apply h-full items-center big-checkbox;

    .checkbox-indicator {
      @apply rounded-[0] scale-[0.7];
    }
    &.disabled {
      @apply text-middle-grey;
      cursor: initial;
    }
  }
}
</style>
