<template lang="pug">
.menu-container(v-if="user")
      transition(name="fade")
        .menu-container-top(v-if="!channelListIsOpen")
          transition-group(name="fade")
            .menu-buttons-container(:class="route.name")
              rc-logout-button(v-if="route.name === 'init'")
              .previous-route-button-container.click(v-if="route.name !== 'offices'" @click="changeRoute")
                .arrow-left-container
                  rc-svg.arrow-left(name="left")
                span {{ $t('back', native)}}
      .menu-item-container.hide-on-short-devices(@click="goToRoadbookPro")
        rc-svg(name="edit_space")
        span {{ $t('toogle_pro.edit_space-menu-item') }}
      .menu-separator-container.hide-on-short-devices
      .menu-item-container(@click="goToRoadbookPro")
        rc-svg(name="support")
        span {{ $t('toogle_pro.support-menu-item') }}
      .menu-separator-container
      .menu-item-container(@click="goToQrcode")
        rc-svg(name="qrcode")
        span {{ $t('toogle_pro.qr_code-menu-item') }}
      .menu-separator-container
      .menu-item-container(@click="openLogoutModal")
        rc-svg(name="logout")
        span {{ $t('toogle_pro.logout-menu-item') }}
      .menu-separator-container
      .menu-user-account-container(@click="gotoMyAccount")
        img.user-account-avatar.round(:src="currentUser?.image_url?.img || currentUser?.image_url ||  user.image_url || defaultImage")
        rc-poppers.poppers(:type="'text'" :content="`${user.first_name} ${user.last_name}`")

      rc-alert(
        :show="isOpen" :title-text="$t('logout_title', native)" :icon-name="'off'"
        :description="$t('logout_prompt', native) + ' </br><b>' + $t('logout_prompt_span', native) + '</b>'"
        :footer-confirm-button-text="$t('validate', native)" :footer-cancel-button-text="$t('cancel', native)" :icon-large="false"
        :icon-stroke="true" @confirm="logout" @cancel="closeLogoutModal")
</template>

<script setup lang="ts">
import RcSvg from '@/components/medias/RcSvg.vue';
import RcLogoutButton from '@/components/inputs/RcLogoutButton.vue';
import RcAlert from '@/components/feedbacks/RcAlert.vue';
import RcPoppers from '@/components/data_displays/RcPoppers.vue';
import defaultImage from '@/assets/avatar-default-man.svg';
import { useMixin } from '@/utils/mixins/useMixin.mixin';
import { useUserStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';
import { ref, computed } from 'vue';

const router = useRouter();
const route = useRoute();
const { native } = useMixin();

// Data
const channelListIsOpen = ref<boolean>(false);
const isOpen = ref<boolean>(false);

// Computed
const user = computed(() => useUserStore().$state.user);

// Methods
const changeRoute = (): void => {
  if (typeof route.meta.previous == 'undefined') {
    router.push('/offices');
  } else {
    router.push(route.meta.previous as RouteLocationRaw);
  }
};

function goToRoadbookPro() {
  if (window.location.origin.includes('localhost')) window.open(`http://localhost:3006/#/`);
  if (window.location.origin.includes('rc-preprod'))
    window.open('https://pro.roadbook.rc-preprod.com/#/');
  if (window.location.origin.includes('app.roadbook.travel'))
    window.open('https://pro.roadbook.travel/#/');
}

const goToQrcode = (): void => {
  router.push('/qr-code');
};

const gotoMyAccount = (): void => {
  router.push('/advisors');
};

const openLogoutModal = (): void => {
  isOpen.value = true;
};

const closeLogoutModal = (): void => {
  isOpen.value = false;
};

const logout = async (): Promise<void> => {
  useUserStore().logout();
};
</script>

<style lang="postcss">
.menu-container {
  @apply flex flex-col w-full items-center justify-end h-full;

  .menu-container-top {
    @apply mb-auto flex flex-col items-center w-full;

    .menu-buttons-container {
      @apply w-full py-[15px] px-0 rounded-tl-normal box-border flex justify-center items-center flex-col bg-white whitespace-nowrap;
      row-gap: 15px;

      .previous-route-button-container {
        @apply flex flex-col items-center justify-center gap-2 w-full transition duration-300 text-lg leading-4 font-medium;

        .arrow-left-container {
          @apply border border-solid border-grey rounded-[99px] h-[26px] w-[26px] opacity-80 flex items-center justify-center outline-[0.5px] outline-transparent transition duration-500 ease-in-out;

          &:hover {
            @apply outline-[0.5px] outline-grey opacity-100;
          }

          .arrow-left {
            @apply text-[45px];
          }

          svg {
            width: 6px !important;
            @apply mr-2.5 align-middle box-content;

            path {
              stroke: theme('colors.grey') !important;
              @apply stroke-[4px];
            }
          }
        }

        span {
          @apply text-[11px] font-light text-center overflow-hidden text-ellipsis;

          @media screen and (max-height: theme('screens.sm-desktop')) {
            @apply hidden;
          }
        }
      }
    }
  }

  .menu-item-container {
    @apply w-full flex flex-col items-center justify-center gap-3 py-5 px-3 text-primary cursor-pointer;

    @media screen and (max-height: theme('screens.md-height')) {
      @apply p-4 gap-2.5;
    }

    .svg-icon {
      @apply fill-primary h-6;
    }

    span {
      @apply text-[10px] font-normal text-center overflow-hidden text-ellipsis leading-3;

      @media screen and (max-height: theme('screens.sm-desktop')) {
        @apply hidden;
      }
    }

    &:hover {
      @apply text-secondary-blue transition-colors;

      .svg-icon {
        @apply fill-secondary-blue transition-colors;
      }
    }
  }

  .menu-separator-container {
    @apply bg-grey opacity-40 w-10 h-[1px] min-h-[1px];
  }

  .hide-on-short-devices {
    @media (min-width: 1301px) {
      @apply hidden bg-red-500;
    }
  }

  .menu-user-account-container {
    @apply flex flex-col w-full items-center cursor-pointer mt-standard;

    @media screen and (max-height: theme('screens.md-height')) {
      @apply mt-4;
    }

    .user-account-avatar {
      @apply w-10 h-10 border-2 border-solid border-grey cursor-pointer;

      &:hover {
        @apply border-primary;
      }
    }
  }
}
</style>
