import { defineStore } from 'pinia';
import settings from '@/utils/settings';
import { useRoadbookStore } from './roadbook';
import apiEsService from '@/services/api/esService';
import { Sheet, discriminant } from '@/models/interfaces/Sheet.interface';
import licenceProService from '@/services/api/licenceProService';

export const useSheetsStore = defineStore({
  id: 'sheets',
  state: () => ({
    search: null as string | null,
    loading: false as boolean,
    sheets: [] as Array<Sheet>,
    metadata: null as any,
    page: 0 as number,
    filters: {cities: [], bordereaux_interne: [], sheet_ids: [], products: []} as any,
    discriminant: {
      //old
      start:[],
      expert: [],
      //tipy
      fix: [] as Array<discriminant> | null,
      variable: [] as Array<discriminant> | null,
    },
    context: [],
  }),
  actions: {
    nextPage() {
      this.page++;
    },
    resetPage() {
      this.page = 0;
    },
    setLoading(value: boolean) {
      this.loading = value;
    },
    setMetadata(metadata: any) {
      this.metadata = metadata;
    },
    setSheets(sheets: Array<Sheet>) {
      this.sheets = sheets;
    },
    addSheets(sheets: Array<Sheet>) {
      this.sheets = [...this.sheets, ...sheets];
    },
    setSearch(search: string) {
      this.resetPage;
      this.search = search?.length ? search : null;
    },
    async getSheets() {
      this.setLoading(true);
      const page = this.page;
      const search = this.search;
      const start = page * settings.pageSize;
      const lang = useRoadbookStore().metadata.visitor.language;
      const sheets = await apiEsService.searchSheets({
        start,
        size: settings.pageSize,
        search,
        lang,
      });
      if (page === 0) {
        this.setSheets(sheets.data);
      } else {
        this.addSheets(sheets.data);
      }
      this.setMetadata(sheets.metadata);
      this.setLoading(false);
    },
    resetBordereau(){
      this.filters.bordereaux_interne = [];
    },
    resetCities(){
      this.filters.cities = [];
    },
    toggleCities(insee) {
      if(this.filters?.cities.includes(insee)){
        this.filters.cities = this.filters.cities.filter((v) => v !== insee);
        return;
      }
      return this.filters.cities.push(insee);
    },
    toggleBordereau(bordereau) {
      if(this.filters?.bordereaux_interne.includes(bordereau)){
        this.filters.bordereaux_interne = this.filters.bordereaux_interne.filter((v) => v !== bordereau);
        return;
      }
      return this.filters.bordereaux_interne.push(bordereau);
    },
    toggleOnboard() {
      if(this.filters.products.length) {
        this.filters.products = [];
      } else {
        this.filters.products = [
          'roadbook_pro_start',
          'roadbook_pro_premium',
          'roadbook_pro_conciergerie',
          'tipy_pro_fix',
          'tipy_pro_variable'
        ]
      }
    },
    async getSheetsPro() {
      this.setLoading(true);
      const page = this.page;
      const search = this.search;
      const filters = this.filters;
      const start = page * settings.pageSize;
      const lang = useRoadbookStore().metadata.visitor.language;
      const sheets = await apiEsService.searchSheetsPro({
        start,
        size: settings.pageSize,
        search,
        filters,
        lang,
        onboarding: true
      });
      if (page === 0) {
        this.setSheets(sheets.data);
      } else {
        this.addSheets(sheets.data);
      }
      this.setMetadata(sheets.metadata);
      await this.getContext()
      this.setLoading(false);
    },
    async getTipyDiscriminant() {
      this.discriminant = await licenceProService.getDiscriminant();
    },
    async getContext() {
      this.context = []
      for(const sheet of this.sheets) {
        if(sheet.sheet_id && sheet.onboarded){
          const res = await licenceProService.getContext(sheet.sheet_id)
          this.context.push(res.data);
        }
      }
    }
  },
});
