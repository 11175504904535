import { createLoader } from "../functions/longLoader";

export function loader(app, i18n) {
    app.directive('rc-loader', {
        beforeMount(el, binding) {
          el.classList.add('load-wrapper');
          const pendingDiv = document.createElement('div');
          pendingDiv.classList.add('isPending');
          pendingDiv.style['--topOffset'] = `calc(50% + ${el.scrollTop}px)`;
          // Li words when slow loading
          if (
            el.getAttribute('id') == 'results-preview-wrapper' ||
            el.classList.contains('sheet-scroll-loading')
          ) {
            const div = document.createElement('div');
            div.classList.add('long-load-content');
            const p = document.createElement('p');
            p.innerText = i18n.global.t('waiting_for');
            p.style.opacity = '0';
            div.appendChild(p);
            const apisList = document.createElement('div');

            for (let i = 0; i < window.engines?.length; i++) {
              const img: HTMLImageElement = document.createElement('img');;
              if (i == 0) {
                img.classList.add('active');
              }
              img.height = 40;
              apisList.appendChild(img);
            }

            div.appendChild(apisList);
            pendingDiv.appendChild(div);
            setTimeout(() => {
              p.style.opacity = '1';
            }, 2000);

            if (window.engines?.length > 1) {
              setInterval(() => {
                //Carousel-like behaviour: should create a component RcCarousel ? or juste keep it in directive
                for (let i = 0; i < apisList.children.length; i++) {
                  if (apisList.children[i].classList.contains('active')) {
                    apisList.children[i < apisList.children.length - 1 ? i + 1 : 0].classList.add(
                      'active',
                    );
                    apisList.children[i].classList.add('out');
                    apisList.children[i].classList.remove('active');
                    setTimeout(() => {
                      apisList.children[i].classList.remove('out');
                    }, 2000);
                    break;
                  }
                }
              }, 3000);
            }
          }

          if (binding.value.loading) {
            if (pendingDiv && pendingDiv.style) {
              pendingDiv.style.setProperty('--topOffset', `calc(50% + ${el.scrollTop}px)`);
            }
            el.classList.add('load-content');
          } else {
            el.classList.remove('load-content');
          }
          el.append(pendingDiv);
          createLoader(el, binding);
        },
        updated(el, binding) {
          createLoader(el, binding);
          if (binding.value.loading) {
            const pendingDiv = el.querySelectorAll(':scope > .isPending');
            pendingDiv[0]?.style.setProperty('--topOffset', `calc(50% + ${el.scrollTop}px)`);
            el.classList.add('load-content');
            if(binding.value.message) {
              const p = document.createElement('p');
              p.innerText = i18n.global.t(binding.value.message);
              p.style.opacity = "1";
              pendingDiv[0]?.appendChild(p);
            }
          } else {
            clearTimeout(window.timeoutLongLoading);
            const pendingDiv = el.querySelectorAll(':scope > .isPending');
            if (pendingDiv[1]) {
              pendingDiv[1].parentNode.removeChild(pendingDiv[1]);
            }
            el.classList.remove('load-content');

            if (
              el.getAttribute('id') == 'results-preview-wrapper' ||
              el.classList.contains('sheet-scroll-loading')
            ) {
              el.querySelector(':scope > .isPending > .long-load-content').classList.remove('active');
            }
          }
        },
      });
}
