<template lang="pug">
#accommodation-field
  .accommodation-category.click(
    v-for="category in accommodation_categories"
    :key="category.slug"
    :class="{ selected: category.slug === selectedCategory }"
    @click="selectCategory(category, category.slug !== selectedCategory)"
  )
    .category-icon.round
      rc-svg(:name="getIcon(category)" :slug="category.slug")
    .category-name {{ (category.name[language]) || category.name.fr }}

</template>

<script lang="ts">
import { defineComponent } from 'vue';

import mixin from '@/utils/mixins/RcMixin.vue';
import RcSvg from '@/components/medias/RcSvg.vue';
import { useConfigStore, useRoadbookStore } from '@/store';
import { Category } from '@/models/interfaces';

export default defineComponent({
  components: {
    RcSvg,
  },
  mixins: [mixin],
  data() {
    return {
      icons: {
        autre: 'flag' as string,
        camping: 'camping' as string,
        'camping-car-van': 'bus' as string,
        'chambre-dhote': 'key' as string,
        hotel: 'hotel' as string,
        'location-meublee': 'bed' as string,
        'residence-de-meubles': 'house' as string,
        'village-vacance-famille': 'sun' as string,
        'residence-secondaire': 'key' as string,
      },
    };
  },
  computed: {
    accommodation_categories(): Array<Category> {
      return useConfigStore().config.accommodation_categories || [];
    },
    selectedCategory(): string {
      return useRoadbookStore().metadata.trip.accommodation;
    },
  },
  methods: {
    selectCategory(category: Category, value: string): void {
      useRoadbookStore().setRoadbookModifiedDate();
      useRoadbookStore().updateMetadata({
        category: 'trip',
        field: 'accommodation',
        value: value ? category.slug : '',
      });
    },
    getIcon(category: Category): string {
      if (category.icon) return category.icon;

      if (Object.keys(this.icons).includes(category.slug || '')) {
        return this.icons[category.slug || ''];
      }

      if (category.slug?.includes('camping')) return 'camping';
      if (category.slug?.includes('hotel') || category.slug?.includes('hebergement'))
        return 'hotel';
      if (category.slug?.includes('gite') || category.slug?.includes('hote')) return 'bed';
      if (category.slug?.includes('proche') || category.slug?.includes('ami'))
        return 'user-friends';
      if (category.slug?.includes('centre')) return 'house';
      if (category.slug?.includes('location')) return 'key';

      return 'hotel';
    },
  },
});
</script>

<style lang="postcss" scoped>
#accommodation-field {
  @apply mt-[8px] flex flex-wrap my-0 mx-[-16px] transition duration-500 ease-in-out;

  .accommodation-category {
    @apply my-0 mx-[16px] mt-[16px] w-[72px] flex flex-col items-center text-center;

    @media (hover: hover) {
      &:hover {
        @apply text-primary;

        .category-icon {
          @apply bg-primary;
          .svg-icon {
            @apply text-white;
          }
        }

        .category-name {
          @apply text-primary;
        }
      }
    }

    .category-icon {
      @apply transition duration-300 ease-in-out border-solid border-middle-grey border-[1px] text-white w-[52px] h-[52px] flex justify-center items-center;

      @media screen and (max-width: 950px) {
        @apply w-[45px] h-[45px];
      }
      .svg-icon {
        @apply text-middle-grey h-[45%];
      }
    }
    .category-name {
      @apply transition duration-300 ease-in-out font-light mt-[16px] text-[14px] text-grey;
      @media screen and (max-width: 950px) {
        @apply text-[12px];
      }
    }

    &.selected {
      .category-name {
        @apply text-primary;
      }

      .category-icon {
        @apply bg-primary;
        .svg-icon {
          @apply text-white;
        }
      }
    }
  }
}
</style>
