const isProd = window.location.origin == 'https://app.roadbook.travel';
const idpDomain = isProd ? 'connect.raccourci.fr' : 'connect.rc-preprod.com';

export default {
  getLoginUrl() {
    return `https://${idpDomain}/authorize?client_id=roadbook&response_type=code&scope=openid%20profile&redirect_uri=${window.location.origin}/v2/roadbook/idp/callback?version=${__APP_VERSION__}`;
  },
  getLogoutUrl(redirectUri: string = window.location.origin) {
    return `${window.location.origin}/v2/roadbook/idp/logout?redirect_uri=${encodeURI(
      redirectUri,
    )}`;
  },
};
