<template lang="pug">
.email-field(
  :class="{'focus': focus}"
)
  .email-input(:class="isBorder ? 'border-[1px] border-solid border-light-grey rounded-large' : ''")
    .input-with-icon(:class="{'!rounded-large': isBorder}")
      input.emailInput(
        :placeholder="placeholder"
        :class="{invalid}"
        @input="e => { inputHandler(e.target.value) }"
        @paste="e => { inputHandler(e.target.value) }"
        @blur="toggleFocus"
        @focus="toggleFocus"
        :value="value"
      )
      rc-svg.checked(
        v-if="value"
        :class="{'error-checked': !isValid(value), invalid}"
        :name="invalid ? 'croix' : 'check'")
  .email-append-btns(v-if="!checkOnDevice()[0]" :class="{ 'disabled' : disabled }" )
    .email-append-btn.click(
      v-for="provider in mailProviders"
      :key="provider"
      @click="appendProvider(provider)"
    ) {{ provider }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import RcSvg from '@/components/medias/RcSvg.vue';
import { useRoadbookStore } from '@/store/roadbook';
import { Contact } from '@/models/interfaces/Contact.interface';
import mixin from '@/utils/mixins/RcMixin.vue';

export default defineComponent({
  components: {
    RcSvg,
  },
  mixins: [mixin],
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    debounce: {
      type: Boolean,
      default: true,
    },
    isToSave: {
      type: Boolean,
      default: false,
    },
    isMetadata: {
      type: Boolean,
      default: false,
    },
    valueInput: {
      type: String,
      default: '',
    },
    store: {
      type: Boolean,
      default: false,
    },
    removing: {
      type: Boolean,
      required: false,
    },
    invalid: {
      type: Boolean,
      required: false,
    },
    isBorder: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      mailProviders: ['@gmail.com', '@outlook.fr', '@orange.fr'] as Array<string>,
      disabled: true as boolean,
      valid: false as boolean,
      focus: false as boolean,
      value: null as string | null,
    };
  },
  computed: {
    contact(): Contact {
      return useRoadbookStore().metadata.contact.filter((contact) => contact.email != null)[0];
    },
    email(): string | null {
      return this.valueInput ? this.valueInput : this.contact ? this.contact.value : null;
    },
  },
  watch: {
    valueInput: {
      handler(): void {
        this.value = this.valueInput;
      },
      deep: true,
    },
  },
  mounted(): void {
    if (this.email && !this.empty && !this.removing) {
      this.value = this.email;
      this.inputHandler(this.email);
    }
  },
  methods: {
    toggleFocus(): void {
      this.focus = !this.focus;
    },
    inputHandler(value: string): string {
      this.value = value;
      const firstPart: string = value ? value.split('@')[0] : '';
      const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@/;
      this.disabled = firstPart.length < 1 || regex.test(firstPart);
      if (this.isToSave) {
        if (this.isValid(value)) {
          this.$emit('input', value);
        } else if (value == '') {
          this.$emit('input', null);
        }
      } else {
        this.$emit('input', value);
      }
      return value;
    },
    isValid(): boolean {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      this.valid = regex.test(this.value);

      return this.valid;
    },
    appendProvider(provider: string): void {
      this.inputHandler(`${this.value.split('@')[0]}${provider}`);
      setTimeout(() => {
        this.$emit('input', this.value);
      }, 100);
    },
  },
});
</script>

<style lang="postcss" scoped>
.email-field {
  @apply flex flex-row w-full items-center gap-2;
  .email-input {
    @apply w-full relative;

    .input-with-icon {
      @apply relative bg-white-grey rounded-md flex items-center border-[2px] border-solid border-white-grey h-[38px];
      &.focus {
        @apply border-none;
      }
      input {
        @apply font-extralight pr-[35px] pl-3;
        background-color: theme('colors.white-grey');

        &::placeholder {
          @apply font-extralight;
        }
        &.invalid {
          @apply border-error-color border border-solid;
        }
      }
      .checked {
        @apply absolute fill-primary h-[12px] transition-[fill] duration-300 ease-in-out;

        @media screen and (max-width: 1400px) {
          @apply right-[15px];
        }

        &.error-checked,
        &.invalid {
          @apply fill-error-color;
        }
      }

      input {
        @apply box-border border-none rounded-md w-full;
      }
    }
  }
  .email-append-btns {
    @apply flex gap-2;

    .email-append-btn {
      @apply text-center w-full box-border py-0 px-[11px] rounded-normal border-[1px] border-solid border-grey text-grey transition duration-300 h-[41px] flex items-center justify-center;

      @media (hover: hover) {
        &:hover:not(.selected) {
          @apply bg-primary border-primary text-white;
        }
      }
    }

    &.disabled {
      @apply pointer-events-none duration-300;

      .email-append-btn {
        @apply opacity-20 duration-300;
      }
    }
  }
}

.emailInput {
  @apply h-full w-full text-ellipsis;
}

@media screen and (min-width: 950px) and (max-width: 1400px) {
  .email-field {
    .email-append-btns {
      @apply hidden;
    }
  }
}
@media screen and (max-width: 950px) {
  .email-field {
    @apply flex flex-col;
    .email-append-btns {
      @apply visible w-full;
    }
  }
}
</style>
