import { defineStore } from 'pinia';
import { useUserStore } from './user';
import { watch } from 'vue';

export const useErrorHandlerStore = defineStore({
  id: 'errorHandler',
  state: () => ({
    error: {} as { title?: string; detail?: string; code?: string },
    countdown: 0,
  }),
  actions: {
    setError(error) {
      window.parent.postMessage('error', '*');
      this.error = error;
    },

    throwError(error) {
      if (error.originalError && error.originalError.status === 412) {
        useUserStore().noActiveSessions(error.originalError.data);
      } else if (!useUserStore().noSessions) {
        this.setError(error);
      }
    },

    throwDefaultError(e) {
      if (e.response && e.response.data && e.response.data.error) {
        const { error } = e.response.data;
        const renderedError = { code: error.code, title: error.text, detail: error.message };
        this.setError(renderedError);
      } else {
        const renderedError = {
          code: e.status || 500,
          title: e.statusText || 'Une erreur inconnue est survenue',
          detail: e.data || null,
        };
        this.setError(renderedError);
      }
    },

    resetState() {
      this.error = {};
      this.countdown = 0;
    },

    fireRedirect(path) {
      this.countdown = 6;
      const interval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(interval);
        }
      }, 1000);

      watch(
        () => this.countdown,
        () => {
          if (this.countdown <= 0) {
            this.resetState();
            this.router.push(path);
          }
        },
      );
    },
  },
});
