import { useUserStore, useConfigStore, useLeafletsStore, useRoadbookStore } from '@/store';

export const initStore = async () => {

  const isInitialized = !!useConfigStore().config && JSON.stringify(useConfigStore().config) !== '{}';

  if(!isInitialized) {

    if (useRoadbookStore().id) await useRoadbookStore().restart();

    return Promise.all([
      useConfigStore().getConfig(),
      useUserStore().getUsers(),
      useUserStore().getUsersWithoutPassword(),
      useUserStore().getUsersWithoutRoadBookRole(),
      useLeafletsStore().getLeaflets(),
    ]);
  }
};