import client from './apiConnector';
import { useErrorHandlerStore } from '@/store/errorHandler';
import { useRoadbookStore } from '@/store/roadbook';
// import { User } from '@/models/interfaces/User.interface';
import { Roadbook } from '@/models/interfaces/Roadbook.interface';
import { Webhook, Webhooks } from '@/models/interfaces/Webhook.interface';
import { Entity } from '@/models/interfaces/Entity.interface';
import type { LastRoadbooksResponse } from '@/models/interfaces';

export default {
  async search(identifier: string, type?: string): Promise<Roadbook | boolean> {
    try {
      const response = await client().get(
        `/roadbook/search?search=${identifier.replace('+', '%2B')}&type=${type}`,
      );
      return response.data;
    } catch (e: any) {
      console.error(`API Error Search Roadbook with ID ${identifier}: ${e}`);
      return false;
    }
  },
  async getLastRoadbooks(requestBody?: object): Promise<LastRoadbooksResponse> {
    try {
      const response = await client().post(`/roadbook/lasts`, requestBody);
      return response.data;
    } catch (e: any) {
      return { size: 0, roadbooks: [] };
    }
  },
  async sendByEmail(
    url: string,
    printUrl: string,
    lang: string,
    contact: {
      is_optin: boolean;
      is_optin_parent: boolean;
      is_optin_pro: boolean;
      type: string;
      value: string;
      first_name: string | null;
      last_name: string | null;
    },
    entity: Entity,
  ) {
    const idRoadbook = useRoadbookStore().id;
    const period = useRoadbookStore().metadata.trip.period;
    try {
      await client().post('/notification/mail', {
        roadbook_link: url,
        roadbook_print_link: printUrl,
        roadbook_id: idRoadbook,
        lang,
        contact,
        entity,
        period,
      });
      return true;
    } catch (e: any) {
      console.error(`API Error Send By Email: ${e}`);
      return false;
    }
  },

  async sendByPhone(
    url: string,
    lang: string,
    contact: {
      is_optin: boolean;
      is_optin_parent: boolean;
      is_optin_pro: boolean;
      type: string;
      value: string;
      first_name: string | null;
      last_name: string | null;
    },
    entity: Entity,
  ) {
    const idRoadbook = useRoadbookStore().id;
    try {
      await client().post('/notification/sms', {
        roadbook_link: url,
        roadbook_id: idRoadbook,
        lang,
        contact,
        entity,
      });
      return true;
    } catch (e: any) {
      console.error(`API Error Send By Phone: ${e}`);
      return e.response;
    }
  },

  async create(params: any): Promise<Roadbook | null> {
    try {
      const configResponse = await client().post('/roadbook', params);
      return configResponse.data;
    } catch (e: any) {
      useErrorHandlerStore().throwError({
        title: "Echec de l'envoi des données",
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return null;
    }
  },

  async edit(roadbookId: string, params: Roadbook): Promise<undefined | null> {
    try {
      // Check du payload precedent pour eviter les requête qui n'edit pas le roadbook
      const payload = params.lastPayload;
      delete params.lastPayload;
      if (params !== payload) {
        const configResponse = await client().put(`/roadbook/${roadbookId}`, params);
        return configResponse.data;
      }
    } catch (e: any) {
      console.error(`API Error Edit Roadbook with ID ${roadbookId}: ${e}`);
      useErrorHandlerStore().throwError({
        title: "Echec de l'envoi des données",
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return null;
    }
  },

  async delete(roadbookId: string): Promise<void> {
    try {
      await client().delete(`/roadbook/${roadbookId}`);
    } catch (e: any) {
      console.error(`API Error Delete Roadbook with ID ${roadbookId}: ${e}`);
    }
  },

  async getWebhooks(): Promise<Webhooks | null> {
    try {
      const configResponse = await client().get(`/pulsar/webhook`);
      return configResponse.data.response;
    } catch (e: any) {
      console.error(`API Error Get Webhooks: ${e}`);
      useErrorHandlerStore().throwError({
        title: "Echec de l'envoi des données",
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return null;
    }
  },
  async getFeed(roadbookId: string): Promise<any> {
    try {
      const response = await client().get(`/roadbook/${roadbookId}/feeds`);
      return response;
    } catch (e: any) {
      console.error(`API Error Get Feeds: ${e}`);
      return e.response;
    }
  },
  async updateWebhook(webhookList: Array<Webhook>, webhookType: string): Promise<any> {
    try {
      const configResponse = await client().post(`/pulsar/webhook`, {
        webhooks: webhookList,
        webhook_type: webhookType,
      });
      return configResponse.data;
    } catch (e: any) {
      console.error(`API Error Update Webhooks: ${e}`);
      useErrorHandlerStore().throwError({
        title: "Echec de l'envoi des données",
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return null;
    }
  },
};
