import { Category, Entity, Feed, Leaflet, User } from "@/models/interfaces";
import roadbookService from "@/services/api/roadbookService";
import { useLeafletsStore, usePlaylistStore, useRoadbookStore, useTagManagerStore, useUserStore } from "@/store";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export function useMixin() {
  const { t } = useI18n();
  const $t: any = t;
  const router = useRouter();

  // Computed
  const language = computed(() => useRoadbookStore().metadata.visitor.language)

  const native = computed(() => {
    let lang = 'fr';
    const user: User = useUserStore().users.filter((user: User) => {
      return user.contact_id == useUserStore().user.contact_id;
    })[0];

    if (user && user.original_language_code) {
      lang = user.original_language_code;
    }
    return lang;
  })

  const isEmptyRoadbook = computed(() => {
    const all: Array<Feed | Leaflet> = [];
    const entities = useRoadbookStore().entities;
    if (entities) {
      for (let i = 0; i < entities.length; i += 1) {
        const currentEntity: Entity = entities[i];
        if (currentEntity.feeds) {
          for (let y = 0; y < currentEntity.feeds.length; y += 1) {
            const currentFeed: Feed = currentEntity.feeds[y];
            if (currentEntity.feeds && currentFeed) {
              all.push(currentFeed);
              if (currentFeed.objects) {
                for (let z = 0; z < currentFeed.objects.length; z += 1) {
                  all.push(currentFeed.objects[z]);
                }
              }
            }
          }
        }
      }
    }
    return all.length === 1;
  })

  const hasLeaflets = computed(() => {
    const currentFeedContent: Feed = useRoadbookStore().currentFeed;
    return currentFeedContent && currentFeedContent.objects
      ? currentFeedContent.objects.filter((object) => {
          return object.status !== false;
        }).length
      : 0;
  })

  // Methods
  function needTranslate(): string {
      const user: User = useUserStore().users.filter((user: User) => {
        return user.contact_id == useUserStore().user.contact_id;
      })[0];
      if (!user) {
        // Error in storage -> can't get lang data
        return native.value;
      } else if (
        user.original_language_code == language.value ||
        (user.other_language_codes && user.other_language_codes.includes(language as any))
      ) {
        return language.value;
      } else {
        return native.value;
      }
  }

  function formatRoles(roles: Array<string>): Array<string> {
    const formattedRoles = [] as Array<string>;
    roles?.forEach((role: string) => {
      const formattedItem: string = $t(`advisors.roles.${role}`, native);
      if (!formattedRoles.includes(formattedItem)) {
        formattedRoles.push(formattedItem);
      }
    });
    return formattedRoles.sort();
  }

  function checkOnDevice(): Array<boolean> {
    const onDevice: boolean = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    const onLandscape: boolean = window.matchMedia('(orientation: landscape)').matches;
    return [onDevice, onLandscape];
  }

  function goToStudio(): void {
    useUserStore().logout();
  }

  function getLogoUrl(channel: Category): string {
    let icon = '';

    switch (channel.slug) {
      case 'chat':
      case 'tchat':
        icon = 'chat';
        break;
      case 'email':
      case 'mail':
        icon = 'email';
        break;
      case 'guichet':
      case 'pit-desk':
        icon = 'guichet';
        break;
      case 'telephone':
        icon = 'tel-empty';
        break;
      case 'mobile':
        icon = 'tel';
        break;
      case 'site-internet':
      case 'internet':
      case 'web':
        icon = 'globe';
        break;
      case 'hors-les-murs':
        icon = 'flag';
        break;
      case 'courrier':
        icon = 'email';
        break;
      case 'reseaux-sociaux':
        icon = 'email';
        break;
      case 'taka':
        icon = 'taka';
        break;
      default:
        icon = 'flag';
        break;
    }

    return icon;
  }

  function isTouchEnabled(): boolean {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      (navigator as any).msMaxTouchPoints > 0
    );
  }

  function touchApparition(e: Event): void {
    if (isTouchEnabled()) {
      e.stopPropagation();
    }
  }

  async function deleteRoadbook(): Promise<void> {
    const roadbookId = useRoadbookStore().id || '';
    useTagManagerStore().sendEvent({
      event: 'roadbook_abandon',
    });
    useRoadbookStore().resetRoadbook();
    usePlaylistStore().emptyDatePresets();
    useLeafletsStore().setLastDatePicked({ from: '', to: '' });
    await roadbookService.delete(roadbookId);
    router.push('languages');
  }

  return {
    isEmptyRoadbook,
    hasLeaflets,
    native,
    language,
    needTranslate,
    formatRoles,
    goToStudio,
    isTouchEnabled,
    touchApparition,
    checkOnDevice,
    deleteRoadbook,
    getLogoUrl
  }
}
