import { defineStore } from 'pinia';
import apiConfigService from '@/services/api/configService';
import { Config } from '@/models/interfaces/Config.interface';
import { Office } from '@/models/interfaces/Office.interface';

export const useConfigStore = defineStore({
  id: 'config',
  state: () => ({
    config: {} as Config,
    office: {} as Office,
    editLoading: false as boolean,
    editingAtIndex: -1 as number,
    phoneCodes: {
      AFG: '+93',
      ALA: '+358',
      ALB: '+355',
      DZA: '+213',
      ASM: '+1-684',
      AND: '+376',
      AGO: '+244',
      AIA: '+1-264',
      ATA: '+672',
      ATG: '+1-268',
      ARG: '+54',
      ARM: '+374',
      ABW: '+297',
      SHN: '+290',
      AUS: '+61',
      AUT: '+43',
      AZE: '+994',
      BHS: '+1-242',
      BHR: '+973',
      BGD: '+880',
      BRB: '+1-246',
      BLR: '+375',
      BEL: '+32',
      BLZ: '+501',
      BEN: '+229',
      BMU: '+1-441',
      BTN: '+975',
      BOL: '+591',
      BES: '+599',
      BIH: '+387',
      BWA: '+267',
      BVT: '+47',
      BRA: '+55',
      IOT: '+246',
      VGB: '+1-284',
      BRN: '+673',
      BGR: '+359',
      BFA: '+226',
      BDI: '+257',
      KHM: '+855',
      CMR: '+237',
      CAN: '+1',
      CPV: '+238',
      CYM: '+1-345',
      CAF: '+236',
      TCD: '+235',
      CHL: '+56',
      CHN: '+86',
      CXR: '+61',
      CCK: '+61',
      COL: '+57',
      COM: '+269',
      COG: '+242',
      COD: '+243',
      COK: '+682',
      CRI: '+506',
      HRV: '+385',
      CUB: '+53',
      CUW: '+599',
      CYP: '+357',
      CZE: '+420',
      DNK: '+45',
      DJI: '+253',
      DMA: '+1-767',
      DOM: '+1-809',
      ECU: '+593',
      EGY: '+20',
      SLV: '+503',
      GNQ: '+240',
      ERI: '+291',
      EST: '+372',
      ETH: '+251',
      FLK: '+500',
      FRO: '+298',
      FJI: '+679',
      FIN: '+358',
      FRA: '+33',
      GUF: '+594',
      PYF: '+689',
      ATF: '+262',
      GAB: '+241',
      GMB: '+220',
      GEO: '+995',
      DEU: '+49',
      GHA: '+233',
      GIB: '+350',
      GRC: '+30',
      GRL: '+299',
      GRD: '+1-473',
      GLP: '+590',
      GUM: '+1-671',
      GTM: '+502',
      GGY: '+44-1481',
      GIN: '+224',
      GNB: '+245',
      GUY: '+592',
      HTI: '+509',
      HMD: '+61',
      VAT: '+379',
      HND: '+504',
      HKG: '+852',
      HUN: '+36',
      ISL: '+354',
      IND: '+91',
      IDN: '+62',
      CIV: '+225',
      IRN: '+98',
      IRQ: '+964',
      IRL: '+353',
      IMN: '+44-1624',
      ISR: '+972',
      ITA: '+39',
      JAM: '+1-876',
      JPN: '+81',
      JEY: '+44-1534',
      JOR: '+962',
      KAZ: '+7',
      KEN: '+254',
      KIR: '+686',
      KWT: '+965',
      KGZ: '+996',
      LAO: '+856',
      LVA: '+371',
      LBN: '+961',
      LSO: '+266',
      LBR: '+231',
      LBY: '+218',
      LIE: '+423',
      LTU: '+370',
      LUX: '+352',
      MAC: '+853',
      MKD: '+389',
      MDG: '+261',
      MWI: '+265',
      MYS: '+60',
      MDV: '+960',
      MLI: '+223',
      MLT: '+356',
      MHL: '+692',
      MTQ: '+596',
      MRT: '+222',
      MUS: '+230',
      MYT: '+262',
      MEX: '+52',
      FSM: '+691',
      MDA: '+373',
      MCO: '+377',
      MNG: '+976',
      MNE: '+382',
      MSR: '+1-664',
      MAR: '+212',
      MOZ: '+258',
      MMR: '+95',
      NAM: '+264',
      NRU: '+674',
      NPL: '+977',
      NLD: '+31',
      NCL: '+687',
      NZL: '+64',
      NIC: '+505',
      NER: '+227',
      NGA: '+234',
      NIU: '+683',
      NFK: '+672',
      PRK: '+850',
      MNP: '+1-670',
      NOR: '+47',
      OMN: '+968',
      PAK: '+92',
      PLW: '+680',
      PSE: '+970',
      PAN: '+507',
      PNG: '+675',
      PRY: '+595',
      PER: '+51',
      PHL: '+63',
      PCN: '+870',
      POL: '+48',
      PRT: '+351',
      PRI: '+1-787',
      QAT: '+974',
      KOS: '+383',
      REU: '+262',
      ROU: '+40',
      RUS: '+7',
      RWA: '+250',
      BLM: '+590',
      KNA: '+1-869',
      LCA: '+1-758',
      MAF: '+590',
      SPM: '+508',
      VCT: '+1-784',
      WSM: '+685',
      SMR: '+378',
      STP: '+239',
      SAU: '+966',
      SEN: '+221',
      SRB: '+381',
      SYC: '+248',
      SLE: '+232',
      SGP: '+65',
      SXM: '+1-721',
      SVK: '+421',
      SVN: '+386',
      SLB: '+677',
      SOM: '+252',
      ZAF: '+27',
      SGS: '+500',
      KOR: '+82',
      SSD: '+211',
      ESP: '+34',
      LKA: '+94',
      SDN: '+249',
      SUR: '+597',
      SJM: '+47',
      SWZ: '+268',
      SWE: '+46',
      CHE: '+41',
      SYR: '+963',
      TWN: '+886',
      TJK: '+992',
      TZA: '+255',
      THA: '+66',
      TLS: '+670',
      TGO: '+228',
      TKL: '+690',
      TON: '+676',
      TTO: '+1-868',
      TUN: '+216',
      TUR: '+90',
      TKM: '+993',
      TCA: '+1-649',
      TUV: '+688',
      UGA: '+256',
      UKR: '+380',
      ARE: '+971',
      GBR: '+44',
      USA: '+1',
      UMI: '+1-808',
      VIR: '+1-340',
      URY: '+598',
      UZB: '+998',
      VUT: '+678',
      VEN: '+58',
      VNM: '+84',
      WLF: '+681',
      ESH: '+212',
      YEM: '+967',
      ZMB: '+260',
      ZWE: '+263',
      CAQC: '+1-418',
    },
    cca2Codes: {
      ABW: 'AW',
      AFG: 'AF',
      AGO: 'AO',
      AIA: 'AI',
      ALA: 'AX',
      ALB: 'AL',
      AND: 'AD',
      ARE: 'AE',
      ARG: 'AR',
      ARM: 'AM',
      ASM: 'AS',
      ATA: 'AQ',
      ATF: 'TF',
      ATG: 'AG',
      AUS: 'AU',
      AUT: 'AT',
      AZE: 'AZ',
      BDI: 'BI',
      BEL: 'BE',
      BEN: 'BJ',
      BES: 'BQ',
      BFA: 'BF',
      BGD: 'BD',
      BGR: 'BG',
      BHR: 'BH',
      BHS: 'BS',
      BIH: 'BA',
      BLM: 'BL',
      BLR: 'BY',
      BLZ: 'BZ',
      BMU: 'BM',
      BOL: 'BO',
      BRA: 'BR',
      BRB: 'BB',
      BRN: 'BN',
      BTN: 'BT',
      BVT: 'BV',
      BWA: 'BW',
      CAF: 'CF',
      CAN: 'CA',
      CAQC: 'QC',
      CCK: 'CC',
      CHE: 'CH',
      CHL: 'CL',
      CHN: 'CN',
      CIV: 'CI',
      CMR: 'CM',
      COD: 'CD',
      COG: 'CG',
      COK: 'CK',
      COL: 'CO',
      COM: 'KM',
      CPV: 'CV',
      CRI: 'CR',
      CUW: 'CW',
      CXR: 'CX',
      CYM: 'KY',
      CYP: 'CY',
      CZE: 'CZ',
      DEU: 'DE',
      DJI: 'DJ',
      DMA: 'DM',
      DNK: 'DK',
      DOM: 'DO',
      DZA: 'DZ',
      ECU: 'EC',
      EGY: 'EG',
      ESH: 'EH',
      ESP: 'ES',
      EST: 'EE',
      ETH: 'ET',
      FIN: 'FI',
      FJI: 'FJ',
      FLK: 'FK',
      FRA: 'FR',
      FRO: 'FO',
      GBR: 'GB',
      GAB: 'GA',
      GGY: 'GG',
      GHA: 'GH',
      GIB: 'GI',
      GIN: 'GN',
      GLP: 'GP',
      GMB: 'GM',
      GNB: 'GW',
      GNQ: 'GQ',
      GRC: 'GR',
      GRD: 'GD',
      GRL: 'GL',
      GTM: 'GT',
      GUF: 'GF',
      GUM: 'GU',
      GUY: 'GY',
      HKG: 'HK',
      HMD: 'HM',
      HND: 'HN',
      HRV: 'HR',
      HTI: 'HT',
      HUN: 'HU',
      IDN: 'ID',
      IMN: 'IM',
      IND: 'IN',
      IOT: 'IO',
      IRL: 'IE',
      IRN: 'IR',
      IRQ: 'IQ',
      ISL: 'IS',
      ISR: 'IL',
      ITA: 'IT',
      JAM: 'JM',
      JEY: 'JE',
      JOR: 'JO',
      JPN: 'JP',
      KAZ: 'KZ',
      KEN: 'KE',
      KGZ: 'KG',
      KHM: 'KH',
      KIR: 'KI',
      KNA: 'KN',
      KOR: 'KR',
      KOS: 'XK',
      KWT: 'KW',
      LAO: 'LA',
      LBN: 'LB',
      LBR: 'LR',
      LBY: 'LY',
      LCA: 'LC',
      LIE: 'LI',
      LKA: 'LK',
      LSO: 'LS',
      LTU: 'LT',
      LUX: 'LU',
      LVA: 'LV',
      MAC: 'MO',
      MAF: 'MF',
      MAR: 'MA',
      MCO: 'MC',
      MDA: 'MD',
      MDG: 'MG',
      MDV: 'MV',
      MEX: 'MX',
      MHL: 'MH',
      MKD: 'MK',
      MLI: 'ML',
      MLT: 'MT',
      MMR: 'MM',
      MNE: 'ME',
      MNG: 'MN',
      MNP: 'MP',
      MOZ: 'MZ',
      MRT: 'MR',
      MSR: 'MS',
      MTQ: 'MQ',
      MUS: 'MU',
      MWI: 'MW',
      MYS: 'MY',
      MYT: 'YT',
      NAM: 'NA',
      NCL: 'NC',
      NER: 'NE',
      NFK: 'NF',
      NGA: 'NG',
      NIC: 'NI',
      NIU: 'NU',
      NLD: 'NL',
      NOR: 'NO',
      NPL: 'NP',
      NRU: 'NR',
      NZL: 'NZ',
      OMN: 'OM',
      PAK: 'PK',
      PAN: 'PA',
      PCN: 'PN',
      PER: 'PE',
      PHL: 'PH',
      PLW: 'PW',
      PNG: 'PG',
      POL: 'PL',
      PRI: 'PR',
      PRK: 'KP',
      PRT: 'PT',
      PRY: 'PY',
      PSE: 'PS',
      PYF: 'PF',
      QAT: 'QA',
      REU: 'RE',
      ROU: 'RO',
      RUS: 'RU',
      RWA: 'RW',
      SAU: 'SA',
      SDN: 'SD',
      SEN: 'SN',
      SGP: 'SG',
      SGS: 'GS',
      SHN: 'SH',
      SJM: 'SJ',
      SLB: 'SB',
      SLE: 'SL',
      SLV: 'SV',
      SMR: 'SM',
      SOM: 'SO',
      SPM: 'PM',
      SRB: 'RS',
      SSD: 'SS',
      STP: 'ST',
      SUR: 'SR',
      SVK: 'SK',
      SVN: 'SI',
      SWE: 'SE',
      SWZ: 'SZ',
      SYC: 'SC',
      SYR: 'SY',
      TCA: 'TC',
      TCD: 'TD',
      TGO: 'TG',
      THA: 'TH',
      TJK: 'TJ',
      TKL: 'TK',
      TKM: 'TM',
      TLS: 'TL',
      TON: 'TO',
      TTO: 'TT',
      TUN: 'TN',
      TUR: 'TR',
      TUV: 'TV',
      TWN: 'TW',
      TZA: 'TZ',
      UGA: 'UG',
      UKR: 'UA',
      UMI: 'UM',
      URY: 'UY',
      USA: 'US',
      UZB: 'UZ',
      VAT: 'VA',
      VCT: 'VC',
      VEN: 'VE',
      VGB: 'VG',
      VIR: 'VI',
      VNM: 'VN',
      VUT: 'VU',
      WLF: 'WF',
      WSM: 'WS',
      YEM: 'YE',
      ZAF: 'ZA',
      ZMB: 'ZM',
      ZWE: 'ZW',
    },
    fairguestReviews: {
      '99.21.04.01': {
        name: 'Médiocre',
        icon: 'avis2',
        color: '#ee622d',
      },
      '99.21.04.02': {
        name: 'Moyen',
        icon: 'smiley6',
        color: '#ffc001',
      },
      '99.21.04.03': {
        name: 'Bon',
        icon: 'avis3',
        color: '#9fbf04',
      },
      '99.21.04.04': {
        name: 'Très bon',
        icon: 'avis4',
        color: '#71a63e',
      },
      '99.21.04.05': {
        name: 'Excellent',
        icon: 'avis5',
        color: '#277712',
      },
    },
    swissCantons: {
      AG: 'Aargau',
      AI: 'Appenzell Innerrhoden',
      AR: 'Appenzell Ausserrhoden',
      BE: 'Bern / Berne',
      BL: 'Basel-Landschaft',
      BS: 'Basel-Stadt',
      FR: 'Fribourg / Freiburg',
      GE: 'Genève',
      GL: 'Glarus',
      GR: 'Graubünden / Grigioni / Grischun',
      JU: 'Jura',
      LU: 'Luzern',
      NE: 'Neuchâtel',
      NW: 'Nidwalden',
      OW: 'Obwalden',
      SH: 'Schaffhausen',
      SZ: 'Schwyz',
      SO: 'Solothurn',
      SG: 'St. Gallen',
      TG: 'Thurgau',
      TI: 'Ticino',
      UR: 'Uri',
      VS: 'Valais / Wallis',
      VD: 'Vaud',
      ZG: 'Zug',
      ZH: 'Zürich',
    },
    mainCantons: {
      VD: 'Vaud',
      GE: 'Genève',
      VS: 'Valais',
    },
    belgRegions: {
      values: [
        {
          label: {
            default: 'Anvers',
            fr: 'Anvers',
          },
          value: 'anvers',
        },
        {
          label: {
            default: 'Flandre occidentale',
            fr: 'Flandre occidentale',
          },
          value: 'flandre-occidentale',
        },
        {
          label: {
            default: 'Flandre orientale',
            fr: 'Flandre orientale',
          },
          value: 'flandre-orientale',
        },
        {
          label: {
            default: 'Hainaut',
            fr: 'Hainaut',
          },
          value: 'hainaut',
        },
        {
          label: {
            default: 'Liège',
            fr: 'Liège',
          },
          value: 'liege',
        },
        {
          label: {
            default: 'Luxembourg',
            fr: 'Luxembourg',
          },
          value: 'luxembourg',
        },
        {
          label: {
            default: 'Namur',
            fr: 'Namur',
          },
          value: 'namur',
        },
        {
          label: {
            default: 'Brabant flamand',
            fr: 'Brabant flamand',
          },
          value: 'brabant-flamand',
        },
        {
          label: {
            default: 'Brabant wallon',
            fr: 'Brabant wallon',
          },
          value: 'brabant-wallon',
        },
        {
          label: {
            default: 'Limbourg',
            fr: 'Limbourg',
          },
          value: 'limbourg',
        },
        {
          label: {
            default: 'Bruxelles',
            fr: 'Bruxelles',
          },
          value: 'bruxelles',
        },
      ],
    },
    espRegions: {
      values: [
        {
          label: {
            default: 'Navarre',
            fr: 'Navarre',
          },
          value: 'navarre',
        },
        {
          label: {
            default: 'Pays Basque',
            fr: 'Pays Basque',
          },
          value: 'pays-basque',
        },
        {
          label: {
            default: 'Aragon',
            fr: 'Aragon',
          },
          value: 'aragon',
        },
        {
          label: {
            default: 'Catalogne',
            fr: 'Catalogne',
          },
          value: 'catalogne',
        },
      ],
    },
    canRegions: {
      //From https://fr.wikipedia.org/wiki/Provinces_et_territoires_du_Canada
      values: [
        {
          label: {
            default: 'Québec',
            fr: 'Québec',
          },
          value: 'quebec',
        },
        {
          label: {
            default: 'Alberta',
            fr: 'Alberta',
          },
          value: 'alberta',
        },
        {
          label: {
            default: 'Colombie-Britannique',
            fr: 'Colombie-Britannique',
          },
          value: 'colombie-britannique',
        },
        {
          label: {
            default: 'Ile du Prince Édouard',
            fr: 'Ile du Prince Édouard',
          },
          value: 'ile-du-prince-edouard',
        },
        {
          label: {
            default: 'Manitoba',
            fr: 'Manitoba',
          },
          value: 'manitoba',
        },
        {
          label: {
            default: 'Nouveau-Brunswick',
            fr: 'Nouveau-Brunswick',
          },
          value: 'nouveau-brunswick',
        },
        {
          label: {
            default: 'Nouvelle-Écosse',
            fr: 'Nouvelle-Écosse',
          },
          value: 'nouvelle-ecosse',
        },
        {
          label: {
            default: 'Ontario',
            fr: 'Ontario',
          },
          value: 'ontario',
        },
        {
          label: {
            default: 'Saskatchewan',
            fr: 'Saskatchewan',
          },
          value: 'saskatchewan',
        },
        {
          label: {
            default: 'Terre-Neuve-et-Labrador',
            fr: 'Terre-Neuve-et-Labrador',
          },
          value: 'terre-neuve-et-labrador',
        },
      ],
    },
  }),
  persist: {
    storage: window.sessionStorage,
  },
  actions: {
    setEditingAtIndex(index: number) {
      this.editingAtIndex = index;
    },
    setConfig(config: Config) {
      this.config = config;
      if (config.ga_id) {
        window.ga_id = config.ga_id;
      }
    },
    updateCustomClientInfos(customInfos: any) {
      this.config.custom_client_infos = customInfos;
    },
    setOffice(officeId: Office) {
      this.office = officeId;
    },
    setEditLoading(value: boolean) {
      this.editLoading = value;
    },
    async getConfig() {
      const config: Config = await apiConfigService.getConfig();
      this.setConfig(config);
    },
  },
});
