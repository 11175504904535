import { createI18n } from 'vue-i18n';
import { fr, en, es, de } from '@/i18n'

export const i18n = createI18n({
    locale: import.meta.env.VITE_I18N_LOCALE || 'en',
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
    globalInjection: true,
    formatFallbackMessages: true,
    silentFallbackWarn: true,
    messages: { fr, de, en, es }, // loadLocaleMessages(),
    fallbackWarn: false,
    legacy: false,
  });
