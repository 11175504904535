import { defineStore } from 'pinia';
import { useRoadbookStore } from './roadbook';
import { useUserStore } from './user';
import { useConfigStore } from './config';

export const useTagManagerStore = defineStore({
  id: 'tagManager',
  state: () => ({
    lastEvent: {},
  }),
  actions: {
    setLastEvent(event) {
      this.lastEvent = event;
    },
    sendEvent(data : any) {
      this.setLastEvent(data.event);
      const advisor = `${useUserStore().user.first_name} ${
        useUserStore().user.last_name
      } (${useUserStore().user.contact_id})`;
      const officeId = `${useConfigStore().office.name} (${
        useConfigStore().office.id
      })`;
      const destination = useConfigStore().config.sitekey;
      const channel = useRoadbookStore().currentChannel;
      const visitorType = useRoadbookStore().metadata.visitor.type;

      const prod = window.location.href.match('app.roadbook.travel');
      const preprod = window.location.href.match('app.roadbook.rc-preprod.com');
      
      if (((prod && prod?.length !== 0) || (preprod && preprod?.length !== 0) ) && process.env.NODE_ENV !== 'development') {
        const dataLayer = window.dataLayer || [] ; 
        const dataLayerRaccourci = window.dataLayerRaccourci || [] ;

        dataLayer.push({
          event: data.event,
          destination: destination,
          advisor: advisor,
          office: officeId,
          channel: channel,
          visitorType: visitorType,
          ...data,
        });

        dataLayerRaccourci.push({
          event: data.event,
          destination: destination,
          advisor: advisor,
          office: officeId,
          channel: channel,
          visitorType: visitorType,
          ...data,
        });
      }
    },
  },
});
