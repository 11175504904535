<template lang="pug">
#request-types-field
  .request-type(v-for="category in categories" :key="category.slug")
    label.request-checkbox-wrapper(:for="`request-cb-${category.slug}`") {{ category.name[native] }}
      input.checkbox(:id="`request-cb-${category.slug}`" type="checkbox" @input="e => inputHandler(e.target.checked, category)" :checked="selectedTypes.includes(category.slug)")
      .checkbox-indicator
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import mixin from '@/utils/mixins/RcMixin.vue';
import { useConfigStore, useRoadbookStore } from '@/store';

export default defineComponent({
  mixins: [mixin],
  computed: {
    categories(): Array<any> {
      return useConfigStore().config.visitor_categories as Array<any>;
    },
    selectedTypes(): Array<string> {
      return (useRoadbookStore().metadata.visitor.type || []) as Array<any>;
    },
  },
  methods: {
    inputHandler(checked: boolean, category: any) {
      const newTypes: Array<string> = this.selectedTypes;
      if (checked) {
        category.slug ? newTypes.push(category.slug) : null;
      } else {
        category.slug ? newTypes.splice(newTypes.indexOf(category.slug), 1) : null;
      }
      useRoadbookStore().setRoadbookModifiedDate();
      useRoadbookStore().updateMetadata({
        category: 'visitor',
        field: 'type',
        value: newTypes,
      });
    },
  },
});
</script>

<style lang="postcss" scoped>
#request-types-field {
  @apply flex flex-wrap pt-[4px];

  .request-type {
    @apply mr-standard mb-[30px];

    .request-checkbox-wrapper {
      @apply big-checkbox ml-[16px];
    }
  }
}
</style>
