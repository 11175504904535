<template lang="pug">
teleport(to="#app")
  transition(name='fade')
    #modal-container(v-if="show" @click.self="close()")
      #modal-wrapper(:class="[{'flex': isFlex},{'no-wrap': noWrap}]" :style="{'width': width}")
        #close(@click="close")
            rc-svg(name="croix")
        slot( name="header")
            #modal-header
                #modal-icon(v-if="iconNameMutable" :class="[{'large': iconLarge}, {'no-stroke': !iconStroke}]")
                    rc-svg(:name="iconNameMutable")
                #title(v-if="titleTextMutable" :class="{'centered-title': centeredTitle}") {{ titleTextMutable }}
        slot( name="content")
            #content
                .description(v-html="description")
        slot( name="footer")
            #footer(v-if="!hideFooter && (footerConfirmButtonText || footerCancelButtonText)" :class="[{'centered-btn': centeredFooter},{'inline-btn': inlineFooter}]" )
                slot( name="confirm-btn")
                    RdbkButton(:mode="footerConfirmButtonDisableMutable ? 'disabled' : 'important'" :icon-name="'next'" :label="footerConfirmButtonText" @is-clicked="confirm")
                slot( name="cancel-btn")
                    #cancel-btn(v-if="footerCancelButtonText" @click="cancel") {{ footerCancelButtonText }}
</template>

<script setup lang="ts">
import RcSvg from '@/components/medias/RcSvg.vue';
import RdbkButton from '@/components/inputs/RdbkButton.vue';
import { ref, watch } from 'vue';

//General
const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  centeredFooter: {
    type: Boolean,
    default: false,
  },
  noWrap: {
    type: Boolean,
    default: false,
  },
  iconName: {
    type: String,
    default: '',
  },
  centeredTitle: {
    type: Boolean,
    default: false,
  },
  inlineFooter: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: '',
  },
  titleText: {
    type: String,
    default: '',
  },
  closeIcon: {
    type: Boolean,
    default: true,
  },
  description: {
    type: String,
    default: '',
  },
  footerConfirmButtonText: {
    type: String,
    default: 'Oui',
  },
  footerCancelButtonText: {
    type: String,
    default: 'Non',
  },
  isFlex: {
    type: Boolean,
    default: false,
  },
  iconStroke: {
    type: Boolean,
    default: false,
  },
  iconLarge: {
    type: Boolean,
    default: false,
  },
  footerConfirmButtonDisable: {
    type: Boolean,
    default: false,
  },
  detectClickOutside: {
    type: Boolean,
    default: true,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'cancel', 'confirm']);

//Data
const footerConfirmButtonDisableMutable = ref(props.footerConfirmButtonDisable);
const titleTextMutable = ref(props.titleText);
const iconNameMutable = ref(props.iconName);
const loading = ref(false);
//Watch
watch(
  () => props.footerConfirmButtonDisable,
  () => {
    footerConfirmButtonDisableMutable.value = props.footerConfirmButtonDisable;
  },
);

watch(
  () => props.iconName,
  () => {
    iconNameMutable.value = props.iconName;
  },
);

watch(
  () => props.titleText,
  () => {
    titleTextMutable.value = props.titleText;
  },
);

watch(
  () => props.show,
  () => {
    if (props.show === true) {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
      }, 500);
    }
  },
);

//Methods

function close(): void {
  if (!loading.value) emit('close');
}

function cancel(): void {
  emit('cancel');
}

function confirm(): void {
  if (!footerConfirmButtonDisableMutable.value) {
    emit('confirm');
  }
}
</script>

<style lang="postcss" scoped>
#modal-container {
  @apply absolute top-0 w-full h-full z-[15] flex flex-col backdrop-blur-[5px] bg-real-black/50 p-[theme('margin.standard')];

  @media screen and (max-height: 800px) {
    #modal-header {
      #modal-icon {
        svg {
          @apply !w-[40px] !h-[40px];
        }
        &.large {
          svg {
            @apply !w-[100px] !h-[100px];
          }
        }
      }
    }
  }

  #modal-wrapper {
    position: relative;
    margin: auto;
    height: fit-content;
    max-height: calc(100% - theme('margin.standard') * 6);
    max-width: calc(100% - theme('margin.standard') * 1);
    padding: theme('padding.standard');
    border-radius: theme('borderRadius.normal');
    background-color: theme('colors.white');
    display: flex;
    flex-direction: column;
    min-width: fit-content;

    @media screen and (min-width: theme('screens.md')) {
      max-width: calc(100% - theme('margin.standard') * 8);
    }

    &.flex {
      @apply flex-1;
    }
    &.no-wrap {
      #title {
        margin: 40px 20px 20px 20px !important;
      }
    }
    .ps {
      @apply flex-1 flex flex-col mt-standard p-standard;
      height: calc(100% - 20px);
      .ps__rail-x {
        @apply hidden;
      }
      .ps__rail-y {
        @apply my-standard;
      }
    }
    #close {
      @apply absolute cursor-pointer right-standard top-standard z-[300];
      @media (hover: hover) {
        &:hover {
          @apply transition-[transform] duration-300 ease-out;
          transform: rotate(90deg);
        }
      }
      svg {
        @apply stroke-white stroke-[1.5px] h-[20px];
      }
    }
    #modal-header {
      @apply flex flex-col;

      #modal-icon {
        @apply mx-auto my-standard;
        &.no-stroke {
          @apply stroke-white;
        }
        svg {
          @apply w-[50px] h-[50px] fill-light-grey;
        }
        &.large {
          svg {
            @apply w-[110px] h-[110px];
          }
        }
      }
      #title {
        @apply text-left font-semibold text-primary mx-standard mt-0 tracking-normal text-lg;

        @media screen and (min-width: theme('screens.md')) {
          @apply text-[30px] mb-standard;
        }

        &.centered-title {
          @apply text-center;
        }
      }
    }
    #content {
      @apply flex flex-1;

      .description {
        @apply text-center font-light text-[21px] tracking-normal text-grey p-[40px] w-full;
      }
    }
    #footer {
      @apply flex flex-col justify-center items-end m-4;

      &.centered-btn {
        justify-content: center !important;
        @apply items-center;
      }
      &.inline-btn {
        @apply justify-start flex-row-reverse;

        #cancel-btn {
          @apply mx-[20px] my-auto;
        }
      }

      .rdbkButton {
        @apply px-[35px] py-[10px];
      }

      @media screen and (max-width: 950px) {
        &.inline-btn {
          @apply w-full justify-center m-0 pt-2;
          .rdbkButton {
            @apply px-2 py-2 text-[14px];
          }
          #cancel-btn {
            @apply text-[14px];
          }
        }
      }

      #cancel-btn {
        @apply text-[15px] m-[20px] cursor-pointer transition duration-[400] ease-in-out;

        &:hover {
          @apply text-primary;
        }
      }
    }
  }
}

.modal-wrapper {
  @apply justify-center flex items-center flex-1 h-full;
}

.modal-container {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  @apply block mx-[40px] my-0 p-standard bg-white rounded-normal transition duration-300 ease-in-out overflow-hidden;

  .wrapper {
    @media screen and (max-width: 500px) {
      width: 100% !important;
      height: 90vh !important;
    }
  }

  .close {
    @apply cursor-pointer absolute right-0;

    @media (hover: hover) {
      &:hover {
        @apply transition duration-300 ease-in-out;
        transform: rotate(90deg);
      }
    }

    svg {
      @apply m-[10px] stroke-white stroke-[1.5px];
    }
  }
}

.cadre {
  @apply relative flex-1;
}

#last-roadbooks {
  .modal-container {
    @apply px-0 py-standard;
  }
}
</style>
