<template lang="pug">
.rdbkButton(@click="e => isClicked(e)" :class="`rdbkButton--${mode}`")
  RcSvg.left(v-if="iconName !== '' && iconPosition === 'before'" :name="iconName")
  span.rdbkButton__content {{label}}
  RcSvg.right(v-if="iconName !== '' && iconPosition === 'after'" :name="iconName")
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RcSvg from '@/components/medias/RcSvg.vue';

export default defineComponent({
  components: {
    RcSvg,
  },
  props: {
    mode: {
      type: String,
      default: 'normal', // 3 types : outline (secondary-button), active (active-button), important (big-button), normal (primary-button), disabled
    },
    label: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'after',
    },
  },
  emits: ['isClicked'],
  data() {
    return {};
  },
  methods: {
    isClicked(e) {
      this.mode == 'active' ? this.$emit('isClicked', e) : this.$emit('isClicked');
    },
  },
});
</script>

<style lang="postcss">
.rdbkButton {
  @apply cursor-pointer flex justify-center items-center border-solid border-2 text-white font-bold text-base text-center border-primary bg-primary px-6 py-2.5 rounded-large;
  @apply transition duration-300;

  .svg-icon {
    @apply fill-white h-[15px];
    @apply transition duration-300 ease-in-out;

    &.right {
      @apply ml-3.5;
    }

    &.left {
      @apply mr-3.5;
    }
  }

  &--disabled {
    @apply cursor-not-allowed opacity-40;
  }

  &--outline {
    @apply bg-white text-primary border-primary;

    .svg-icon {
      @apply fill-primary;
    }

    @media (hover: hover) {
      &:hover {
        @apply text-white bg-primary;

        .svg-icon {
          @apply fill-white;
        }
      }
    }
  }

  &--outline-error {
    @apply border-error-color text-error-color bg-white;

    .svg-icon {
      @apply fill-error-color;
    }

    @media (hover: hover) {
      &:hover {
        @apply bg-error-color text-white;

        .svg-icon {
          @apply fill-white;
        }
      }
    }
  }

  &--outline-abandon {
    @apply border-red-500 text-red-500 bg-white;

    .svg-icon {
      @apply fill-red-500;
    }

    @media (hover: hover) {
      &:hover {
        @apply bg-red-500 text-white;

        .svg-icon {
          @apply fill-white;
        }
      }
    }
  }

  &--active {
    @apply bg-white text-primary border-primary;

    .svg-icon {
      @apply fill-primary;
    }
  }

  &--normal {
    @media (hover: hover) {
      &:hover {
        @apply border-2 border-solid border-primary;

        .svg-icon {
          @apply fill-primary;
        }
      }
    }
  }

  &--important {
    @media (hover: hover) {
      &:hover {
        @apply text-white bg-secondary-blue border-secondary-blue;

        .svg-icon {
          @apply fill-white;
        }
      }
    }
  }
}
</style>
