<template lang="pug">
#trip-duration-field
  .trip-duration.select-box.click(
    v-for="duration in trip_durations"
    :key="duration.slug"
    :class="{ selected: duration.slug === selectedDuration }"
    @click="selectCategory(duration, duration.slug !== selectedDuration)"
  ) {{ duration.name[language] || duration.name[native] || duration.name.fr }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import mixin from '@/utils/mixins/RcMixin.vue';
import { useConfigStore, useRoadbookStore } from '@/store';
import { Category } from '@/models/interfaces';

export default defineComponent({
  mixins: [mixin],
  computed: {
    trip_durations(): Array<Category> {
      return useConfigStore().config.trip_durations || [];
    },
    selectedDuration(): string {
      return useRoadbookStore().metadata.trip.duration_slug || '';
    },
  },
  methods: {
    selectCategory(duration: Category, value: boolean) {
      useRoadbookStore().setRoadbookModifiedDate();
      useRoadbookStore().updateMetadata({
        category: 'trip',
        field: 'duration_slug',
        value: value ? duration.slug : '',
      });
    },
  },
});
</script>

<style lang="postcss" scoped>
#trip-duration-field {
  @apply flex flex-wrap;

  .select-box {
    @apply text-center flex items-center justify-center mb-[15px];
  }
}
</style>
