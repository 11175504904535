<template lang="pug">
#department-field
  p.main-department.select-box.click(
    v-for="department in Object.entries(main_departments).slice(0, 8)"
    :key="department[0]"
    :class="{ selected: department[0] === selectedDepartment }"
    @click="selectDepartment({ key: department[0], label: department[1] }, department[0] !== selectedDepartment)"
  ) {{ department[0] }}
  v-select#client-department-select(
    :options="departments"
    :model-value="departments.find(it => it.key === selectedDepartment) || null"
    :placeholder="props.placeholder"
    :filter-by="filter"
    @update:model-value="val => selectDepartment(val || null, true)"
  )
    template(#selected-option="option")
      span {{ option.label }}
    template(#option="option")
      span {{ option.label }} ({{ option.key }})
    template(#no-options)
      span {{ $t('no_results') }}
</template>

<script setup lang="ts">
import { useConfigStore, useRoadbookStore } from '@/store';
import { computed } from 'vue';

const props = defineProps({
  placeholder: {
    type: String,
    default: null,
  },
});

const main_departments = computed(() => useConfigStore().config.main_departments);
const departments = computed(
  () =>
    Object.entries(useConfigStore().config.departments as any) // : {01: "Ain", 02: "Aisne", ...}
      .sort((a, b) => (a[1] as string).localeCompare(b[1] as string))
      .map((it) => {
        return { key: it[0], label: it[1] };
      }) || [],
);
const selectedDepartment = computed(() => {
  return useRoadbookStore().metadata.visitor.department || null;
});

function filter(option: { key: number; label: string }, label: string, search: string): boolean {
  return `${option.label} ${option.key}`
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .includes(
      search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase(),
    );
}
function selectDepartment(
  department: { key: number; label: string },
  value: { key: number; label: string },
): void {
  useRoadbookStore().setRoadbookModifiedDate();
  useRoadbookStore().updateMetadata({
    category: 'visitor',
    field: 'department',
    value: value && department ? department.key : '',
  });
}
</script>

<style lang="postcss">
#department-field {
  @apply flex flex-wrap items-center;

  .main-department {
    @apply mb-[16px] w-[40px] text-center flex justify-center;
  }

  #client-department-select {
    @apply ml-[8px] mb-[16px] flex-[0];
  }
  .v-select {
    @apply v-select-layer;
    .vs__actions svg path {
      stroke: theme('colors.white') !important;
    }
  }
}
</style>
