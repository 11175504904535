import { defineStore } from 'pinia';
import apiRoadbookService from '@/services/api/roadbookService';
import apiEsService from '@/services/api/esService';
import { Metadata, parsePhoneNumberFromString } from 'libphonenumber-js';
import { useUserStore } from './user';
import { useConfigStore } from './config';
import type {
  Entity,
  Feed,
  Traduction,
  Facet,
  Leaflet,
  Commune,
  Contact,
  Historic,
  Roadbook,
} from '@/models/interfaces';
import cloneDeep from 'clone-deep';
import { HttpResponse } from '@capacitor/core';

interface writeHistoryInterface extends Historic {
  object: Leaflet;
  index: number;
}

const formatLeaflet = ({
  ref,
  id_conf_hawwwai,
  name,
  type,
  facets,
  kind,
  urls,
  site_key,
  shared,
  img,
  facetContent,
}) => {
  return { id: ref, id_conf_hawwwai, name, type, facets, kind, urls, site_key, shared, img, facetContent};
};
const formatComment = ({ title, comment, kind }) => {
  return { title, comment, kind };
};
const formatSheetlist = ({ title, sheetlist, kind }) => {
  return { title, sheetlist, kind };
};

const formatNewObject = (object) => {
  switch (object.kind) {
    case 'leaflet':
      object = formatLeaflet(object);
      break;
    case 'comment':
      object = formatComment(object);
      break;
    case 'sheetlist':
      object = formatSheetlist(object);
      break;
    default:
  }
  object.entityId = useUserStore().initialUser.company_id;
  object.office = useConfigStore().office.id;
  object.authors = [useUserStore().user.contact_id];
  object.modified = true; // Important if channel is modified later
  object.channel = useRoadbookStore().currentChannel;
  object.last_modified_date = new Date().toISOString();
  object.status = true;
  object.history = [];
  return object;
};

function isEmpty(obj: Array<Entity>) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
}

// TODO: very ugly feeds load and creation (multiple method with currentfeed and currententity looking duplicated) + no well explained -> to refactor

export const useRoadbookStore = defineStore({
  id: 'roadbook',
  state: () => ({
    id: null as string | null,
    metadata: {
      trip: {
        duration_slug: '' as string,
        accommodation: '' as string,
        period: {
          from: null as string | null,
          to: null as string | null,
        },
        type_request : [] as Array<string>
      },
      visitor: {
        country: '' as string,
        region: '' as string,
        department: '' as string,
        language: '' as string,
        type: [] as Array<string>,
        zip_code: '' as string,
        custom_client_infos: {},
        weather: '' as string,
        hour: '' as string,
        number: null as number | null,
        mobility_category: '' as string,
        notes: '' as string,
      },
      contact: [] as Array<Contact>,
      recipients: [] as Array<Contact>,
      is_finished: false as boolean,
      created_date: '' as string,
      office: null as number | null,
      channel: '' as string,
      author: null as number | null,
      entity_id: null as number | null,
      is_qualif: false as boolean,
    },
    entities: [] as Array<Entity>,
    currentEntity: {id: null, feeds: [], urls: []} as Entity,
    currentFeed: {
      id: null,
      last_modified_date: '' as string,
      objects: [] as Array<Facet>,
      name: [] as Array<Traduction>,
      img: '' as string,
      urls: undefined,
    } as Feed,
    currentChannel: '' as string,
    woodyUrl: '' as string,
    url: '' as string,
    isEditingRoadbook: false as boolean,
    displayEditModal: false as boolean,
    last_modified_date: "" as string, // Only to check if the edition has expired
    communes: {} as Array<Commune>,
    lastPayload: {} as Roadbook,
    oldRoadbook: {} as Roadbook,
  }),
  persist: {
    storage: window.sessionStorage,
  },
  actions: {
    setId(id : string) {
      this.id = id;
    },
    //TODO: is this useless ?
    setMetadata({ metadata, country, language, created_date, office, channel, author, entity_id }: {metadata: Metadata, country: string, language: string, created_date: string, office?: number, channel?: string, author?: string, entity_id?: number, is_qualif?: boolean}) {
      if (metadata) this.metadata = metadata;
      if (country) this.metadata.visitor.country = country;
      if (language) this.metadata.visitor.language = language;
      if (created_date) this.metadata.created_date = created_date;
      if (office) this.metadata.office = office;
      if (channel) this.metadata.channel = channel;
      if (author) this.metadata.author = author;
      if (entity_id) this.metadata.entity_id = entity_id;
    },
    setEntities(entities: Array<Entity>) {
      this.entities = entities;
    },
    setRoadbook(value: Roadbook) {
      this.metadata = value.metadata;
      this.entities = value.entities;
      this.currentEntity = value.currentEntity;
      this.currentFeed = value.currentFeed;
      this.currentChannel = value.currentChannel;
      this.isEditingRoadbook = value.isEditingRoadbook;
      this.last_modified_date = value.last_modified_date;
      this.communes = value.communes;
      this.lastPayload = value.lastPayload;
      this.oldRoadbook = value;
    },
    setOldRoadbook(value: Roadbook) {
      this.oldRoadbook = value;
    },
    setCurrentEntityFeeds(feeds: Array<Feed>) {
      if (this.currentEntity) {
        this.currentEntity.feeds = feeds;
        if (!this.entities[0]) {
          this.entities[0] = this.currentEntity;
        }
        this.entities[0].feeds = feeds;
      }
    },
    setCurrentEntity(entityId: number) {
      if (this.currentEntity) {
        const newEntity = this.entities.find((entity: Entity) => entity.id === entityId);
        this.currentEntity = newEntity ? newEntity : {};
        if (!this.currentEntity) {
          this.entities = [
            {
              id: entityId,
              feeds: [],
            },
            ...this.entities,
          ];
          this.currentEntity = this.entities[0];
        }
      }
    },
    setDisplayEditModal(value: boolean) {
      this.displayEditModal = value;
    },
    setIsQualif(value: boolean) {
      this.metadata.is_qualif = value;
    },
    updateContactList(array: Array<Contact>) {
      this.metadata.contact = [...array];
      this.persistRoadbook();
    },
    setCurrentFeed(currentFeedArray: {entityId?: number, feedId?: number, name?: Traduction | string, img?: string | undefined, urls?: Traduction | undefined}) {

      const feedId = useConfigStore().office.feed_id;
      const feeds = useConfigStore().config.feeds;
      const now = new Date().toISOString();

      if(feeds?.length == 1) {
        const feed = feeds[0] as Feed;
        const newFeed = {
          id: feedId,
          last_modified_date: now,
          objects: [],
          name: feed?.name,
          img: feed?.img,
          urls: feed?.urls,
        } as Feed;
        this.entities = [{
          id: currentFeedArray.entityId,
          feeds: [newFeed],
        }]
        this.currentFeed = newFeed;
        return;
      }
      const feed = feeds?.find((feed: Feed) => feed?.post_id === feedId) as Feed;
      const newFeed = {
        id: feedId,
        last_modified_date: now,
        objects: [],
        name: feed?.name,
        img: feed?.img,
        urls: feed?.urls,
      } as Feed;

      this.entities = [{
        id: currentFeedArray.entityId,
        feeds: [newFeed],
      }]
      this.currentFeed = newFeed;
    },
    updateTrip({ field, value }) {
      this.metadata.trip[field] = value;
    },
    updateVisitor({ field, value }) {
      this.metadata.visitor[field] = value;
    },
    updateCustomInfo({ key, value }) {
      this.metadata.visitor.custom_client_infos[key] = value;
    },
    setRoadbookModifiedDate() {
      const now : string = new Date().toISOString();
      this.currentFeed.last_modified_date = now;
    },
    updateContact({ type, value, is_optin }: {type: string, value: string | null, is_optin: boolean}) {
      if (value == null && type !== 'optin') {
        const contactIndex = this.metadata.contact.findIndex(
          (contact: Contact) => contact[type],
        );
        this.metadata.contact.splice(contactIndex, 1);
      } else {
        if (value) {
          if (type === 'optin') {
            const contact = this.metadata.contact.filter(
              (contact: Contact) => contact.phone === value || contact.email === value,
            )[0];
            if (contact) {
              contact['is_optin'] = is_optin;
            }
          } else {
            const contact = this.metadata.contact.filter(
              (contact: Contact) => contact.phone === value || contact.email === value,
            )[0];
            if (contact === undefined) {
              const other_contact = this.metadata.contact.filter(
                (contact: Contact) => contact.phone !== value && contact.email !== value,
              )[0];
              this.metadata.contact = [
                ...this.metadata.contact,
                {
                  type,
                  value,
                  is_optin: other_contact ? other_contact.is_optin : is_optin,
                },
              ];
            } else {
              contact['type'] = type;
              contact['value'] = value;
            }
          }
        }
      }
    },
    updateRecipients(value: { is_optin: boolean, type: string, value: string, first_name: string | null, last_name: string | null}) {
      this.metadata.recipients = [...this.metadata.recipients, value];
    },
    setUrl(url: string) {
      this.url = url;
    },
    setWoodyUrl(url: string) {
      this.woodyUrl = url;
    },
    updateFinished(value: boolean) {
      this.metadata.is_finished = value;
    },
    setObjects(entitiesObjects: Array<Facet>) {
      if (this.currentFeed) {
        this.currentFeed.objects = entitiesObjects;
      }
    },
    addObjectAction(object: Facet) {
      if (object.last_modified_date) {
        object.last_modified_date = new Date().toISOString();
      }
      if (this.currentFeed && this.currentFeed.objects) {
        this.currentFeed.objects = [...this.currentFeed.objects, object]; // Insert new object (even if current_entity's objects is empty)
        this.currentFeed.last_modified_date = object.last_modified_date;
      }
      this.last_modified_date = object.last_modified_date;
    },
    addExistingObject(object: Leaflet) {
      object.status = true;
      object.last_modified_date = new Date().toISOString();
      //object.modified = true; // Important if channel is modified later
      if (this.currentChannel !== '') object.channel = this.currentChannel; // Always change current channel
      this.last_modified_date = object.last_modified_date;
    },
    addFacetPro(facet: Leaflet) {
      const existingEntities = this.entities.filter((entity: Entity) => entity.id == facet.partner_id);
      const now = new Date().toISOString();
      const newFeed = {
        id: facet.ref,
        last_modified_date: now,
        objects: [],
        name: facet.name,
        img: facet.img,
        urls: facet.urls,
      };
      if (existingEntities?.length > 0) {
        const existingFeed = existingEntities[0].feeds?.filter((feed: Feed) => feed.id == facet.ref);
        if (existingFeed?.length === 0) {
          this.entities.filter((entity: Entity) => entity.id == facet.partner_id)[0].feeds?.push(newFeed);
        }
      } else {
        this.entities.push({
          id: facet.partner_id,
          feeds: [newFeed],
        });
      }
      this.persistRoadbook();
    },
    addFeed(newFeed: {entity_id: number, feed_id: number, post_id: number, name: Traduction, img: string, urls: Traduction}) {
      const entityIndex = this.entities.findIndex((entity: Entity) => entity.id == newFeed.entity_id)

      if(entityIndex >= 0) {
        const feedIndex = this.entities[entityIndex]?.feeds.findIndex((feed: Feed) => feed.id == newFeed.feed_id || feed.id == newFeed.post_id);
        if(feedIndex >= 0) Object.assign(this.entities[entityIndex].feeds[feedIndex], newFeed);

      }
    },
    removeFacetPro(needed: {entityId: number, feedId: number}) {
      const oldRoadbook = cloneDeep(this.$state);
      this.setOldRoadbook(oldRoadbook);
      const entity = this.entities
        .find((entity: Entity) => entity.id == needed.entityId)
        .feeds.filter((feed: Feed) => feed.id != needed.feedId);
      if (entity.length === 0) {
        this.entities = this.entities.filter((entity: Entity) => entity.id !== needed.entityId);
      } else {
        if (this.entities && this.entities.find((entity: Entity) => entity.id === needed.entityId)  && this.entities.find((entity: Entity) => entity.id === needed.entityId)) this.entities.find((entity: Entity) => entity.id === needed.entityId).feeds = entity;
      }
      this.persistRoadbook();
    },
    updateObjectAction({ index, newObject, authorId }: {index: number, newObject: Leaflet, authorId: number}) {
      // currentObject is a reference so changes are automatically applied in currentEntity.objects[index]
      const currentObject : Leaflet = (this.currentFeed && this.currentFeed.objects) ?   this.currentFeed.objects[index] : {};
      for (const [key, value] of Object.entries(newObject)) {
        currentObject[key] = value;
      }
      //currentObject.modified = true; // Important if channel is modified later
      if (this.currentChannel !== '') currentObject.channel = this.currentChannel; // Always change current channel
      // Add a new author if not already in list
      if (authorId && !(currentObject as Leaflet).authors?.includes(authorId)){
        (currentObject as Leaflet).authors = [...((currentObject as Leaflet).authors || []), authorId];
      }

      newObject.last_modified_date = new Date(newObject.last_modified_date || "").toISOString();
      if (this.currentFeed) this.currentFeed.last_modified_date = (newObject.last_modified_date || "");
      this.last_modified_date = newObject.last_modified_date;
    },
    deleteKeepingObject(index: number) {
      // This copy is needed for changes to be applied to the displayed list in RcItemsPreview.vue
      if (this.currentFeed && this.currentFeed.objects) {
        const object = JSON.parse(JSON.stringify(this.currentFeed.objects[index]));
        object.status = false;
        this.currentFeed.objects[index] = object;
      }
    },
    deletePermanentlyObject(index: number) {
      if (this.currentFeed && this.currentFeed.objects) this.currentFeed.objects.splice(index, 1);
    },
    writeHistory({ object, index, action, author }: writeHistoryInterface) {
      if (action !== undefined && this.currentFeed && this.currentFeed.objects) {
        // We can modify the history of a precised object OR of an object at a specified index
        if (!object) object = this.currentFeed.objects[index];
        const newHistory: Historic = {
          action, // add/delete/update/add_sheet/update_sheet/delete_sheet/add_facets
          author,
          date: new Date().toISOString(),
        };
        const objectHistory: Array<Historic> = object.history || [];
        object.history = [newHistory, ...objectHistory];
      }
    },
    updateEditing(value: boolean) {
      this.isEditingRoadbook = value;
    },
    setLastPayload(value: Roadbook) {
      this.lastPayload = value;
    },
    resetRoadbook() {
      this.id = null;
      this.metadata = {
        created_date: '',
        trip: {
          duration_slug: '',
          accommodation: '',
          period: {
            from: '',
            to: ''
          },
          type_request: []
        },
        visitor: {
          country: '',
          region: '',
          department: '',
          zip_code: '',
          language: '',
          type: [],
          custom_client_infos: new Object(),
        },
        contact: [],
        recipients: [],
        is_finished: false,
      };
      this.entities = [];
      this.currentEntity = {};
      this.currentFeed = {};
      this.url = "";
      this.woodyUrl = "";
      this.last_modified_date = new Date();;
    },
    saveRoadbook() {
      if (this.currentEntity) {
        this.entities = [
          ...this.entities.filter((entity: Entity) => entity.id !== this.currentEntity?.id),
          this.currentEntity,
        ];
      }
    },
    setCommunesFacet(value: Commune) {
      this.communes[value.id] = value;
    },
    async load(research: string, type = 'id') {
      const parsedPhoneNumber = parsePhoneNumberFromString(research, 'FR');
      let roadbooks;
      if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
        roadbooks = await apiRoadbookService.search(parsedPhoneNumber.number.toString(),type);
      } else {
        await apiRoadbookService.search(research, type).then((response) => {
          roadbooks = response;
        });
      }

      if (roadbooks) {
        const roadbook = roadbooks[0]; // We can change here in the future, if we let the user choose in the list of roadbooks returned

        if (!isEmpty(roadbook.entities) && roadbook.entities[0].feeds.length !== 0) {
          // Do current entity objects before all others because it needs time and we don't want to have to wait for it

          const currententities = roadbook.entities.find(
            (entity: Entity) => entity.id == useUserStore().initialUser.company_id,
          );
          if (currententities) {
            const newEntity : Promise<any> = Promise.all(
              currententities.feeds[currententities.feeds.length -1].objects.map(async (object: Leaflet) => {
                // Check if object is a leaflet and if he is active (≈ not deleted)
                if (object.kind === 'leaflet' && object.status && object.id) {
                  // Get all the data of a leaflet
                  const leafletData = await apiEsService.getLeaflet(
                    object.site_key ? `${object.site_key}_${object.id}`: object.id,
                  );
                  if (leafletData) {
                    object.type = leafletData.type;
                    object.name = leafletData.name;
                    if (leafletData.id_conf_hawwwai)
                      object.id_conf_hawwwai = leafletData.id_conf_hawwwai;
                    return object;
                  }
                }
                return object;
              }),
            );

            this.setCurrentEntityFeeds(await newEntity);
          }
        }

        this.updateUrlRoadbook(roadbook.metadata.visitor.language);
        this.setWoodyUrl(roadbook.woodyUrl);
        this.setMetadata({ metadata: roadbook.metadata });
        this.setEntities(roadbook.entities);
        const compagnyId : number | undefined = useUserStore().initialUser?.company_id;


        // Get link space id
        const feedId = useConfigStore().office.feed_id;

        const currentFeed = useConfigStore()
          .config.feeds?.filter((feed: Feed) => feed?.post_id == feedId) as Feed | null;

        if (!currentFeed) {
          throw new Error('Feed non trouvé -> id: ' + feedId);
        }
        const currentFeedArray = {
          entityId: compagnyId,
          feedId: currentFeed?.post_id,
          name: currentFeed.name ? currentFeed.name : { [currentFeed?.lang as string]: currentFeed?.title },
          img: currentFeed?.img,
        };

        const feeds = useConfigStore().config.feeds;
        const now = new Date().toISOString();
        const feed = feeds?.find((feed: Feed) => feed?.post_id === feedId) as Feed;
        const newFeed = {
          id: feedId,
          last_modified_date: now,
          objects: [],
          name: feed?.name,
          img: feed?.img,
          urls: feed?.urls,
        } as Feed;
        if (!roadbook.entities.find(
          (entity: Entity) => entity.id == useUserStore().initialUser.company_id,
        )) {
          this.entities.push({
            id: currentFeedArray.entityId,
            feeds: [newFeed],
          });
        } else if (!roadbook.entities.find(
          (entity: Entity) => entity.feeds?.filter((feed) => feed.id === feedId ).length
        )) {
          this.entities.find(
            (entity: Entity) => entity.id == useUserStore().initialUser.company_id,
          ).feeds.push(
            newFeed
          );
        }
        this.currentFeed = newFeed;
        const newEntity = this.entities.find((entity: Entity) => entity.id === compagnyId);
        this.currentEntity = newEntity ? newEntity : {};

        // Add previous object with this entity
        this.setObjects(newEntity.feeds.find((feed) => feed.id === feedId ).objects);

        // Get other entities feed names
        await this.updateFeedsName(roadbook.id);

        const oldRoadbook = cloneDeep(this.$state);
        this.setOldRoadbook(oldRoadbook);
        return true;
      } else {
        return false;
      }
    },
    async create(language: string) {
      const now : string = new Date().toISOString();
      const country = useConfigStore().config.main_countries?.[0] || 'FRA';
      const office = useConfigStore().office.id;
      const channel = this.currentChannel;
      const author = useUserStore().user.contact_id;
      const entityId = useUserStore().entityId;

      this.setMetadata({
        country,
        language,
        created_date: now,
        office,
        channel,
        author,
        entity_id: entityId
      });

      const roadbook = await apiRoadbookService.create({
        metadata: this.metadata,
      });
      this.setRoadbookModifiedDate();
      roadbook?.id ? this.setId(roadbook.id) : null;
      this.updateUrlRoadbook(language);
      this.setWoodyUrl(roadbook?.woodyUrl)
      const currentEntityId = useUserStore().initialUser?.company_id;

      // Get link space id
      const feedId = useConfigStore().office.feed_id;

      const currentFeed = useConfigStore()
        .config.feeds?.filter((feed: Feed) => feed?.post_id == feedId) as Feed | null;

      if (!currentFeed) {
        throw new Error('Feed non trouvé -> id: ' + feedId);
      }
      this.setEntities([
        {
          id: currentEntityId,
          feeds: [],
        },
      ]);
      const currentFeedArray = {
        entityId: currentEntityId,
        feedId: currentFeed?.post_id,
        name: currentFeed.name ? currentFeed.name : { [currentFeed?.lang as string]: currentFeed?.title },
        img: currentFeed?.img,
        urls: currentFeed?.urls,
      };
      this.setCurrentEntity(currentEntityId);
      this.setCurrentFeed(currentFeedArray);
      const oldRoadbook = cloneDeep(this.$state);
      this.setOldRoadbook(oldRoadbook);
    },
    /** Update specified field of Metadata */
    // Accepted field : language, trip_duration, accommodation, department, country,
    async updateMetadata({ category, field, value, key = null, is_optin = false }) {
      const oldRoadbook = cloneDeep(this.$state);
      this.setOldRoadbook(oldRoadbook);
      switch (category) {
        case 'trip':
          this.updateTrip({ field, value });
          break;
        case 'visitor':
          if (key) {
            this.updateCustomInfo({ key, value });
          } else {
            this.updateVisitor({ field, value });
          }
          break;
        case 'contact': // Accepted field : email, phone_number, optin
          this.updateContact({ type: field, value, is_optin });
          break;
        case 'recipients':
          this.updateRecipients(value);
          break;
        case 'finished':
          this.updateFinished(value);
          break;
        default:
      }
      await this.persistRoadbook();
    },
    async updateVisitorMetadata(value) {
      const oldRoadbook = cloneDeep(this.$state);
      this.setOldRoadbook(oldRoadbook);
      value.forEach((element) => {
        this.metadata.visitor[element.field] = element.value;
      });
      await this.persistRoadbook();
    },
    async setCurrentEntityAction(entityId: number) {
      if (entityId) this.setCurrentEntity(entityId);
    },
    /** Update current channel. Representing the way by which the visitor is interacting with the user */
    async updateChannel(channel: string) {
      /** Update the communication mode for the specified leaflet/comment/sheetlist
       * (only update when the leaflet/comment/sheetlist has been modified)
       */
      this.currentChannel = channel;
    },
    async reorderObjects(objects: Array<Leaflet>) {
      const oldRoadbook = cloneDeep(this.$state);
      this.setOldRoadbook(oldRoadbook);
      this.setObjects(formatNewObject(objects));
      await this.persistRoadbook();
    },
    /** Add a new leaflet for the current author */
    async addObject(object: Leaflet) {
      const oldRoadbook = cloneDeep(this.$state);
      this.setOldRoadbook(oldRoadbook);
      // If the object is already existing, we don't add it again but write history
      const existingObject : Leaflet = this.currentFeed && this.currentFeed.objects ? this.currentFeed.objects.find((it: Leaflet) => it.id === object.ref) : {};
      if (
        object.kind === 'leaflet' &&
        existingObject !== undefined &&
        object.type !== 'rdbk_playlist'
      ) {
        this.addExistingObject(existingObject);
        if (this.metadata.is_finished)
          this.writeHistory({
            object: existingObject,
            action: 'add',
            author: useUserStore().user.contact_id,
            modifications: {},
          } as Historic);
      } else {
        this.addObjectAction(formatNewObject(object));
        this.writeHistory({
          index: 0,
          action: 'add',
          author: useUserStore().user.contact_id,
          modifications: {},
        } as Historic);
      }
      await this.persistRoadbook();
    },
    async updateObject({ index, newObject }) {
      const oldRoadbook = cloneDeep(this.$state);
      this.setOldRoadbook(oldRoadbook);
      const currentObject : Leaflet = this.currentFeed?.objects ? this.currentFeed.objects[index] : {};
      let action = "";
      const modifications: {old_title?: string, old_facets?: string, old_comments?: string, old_comment?: string, old_sheetlist?: string} = {};
      switch (currentObject.kind) {
        case 'leaflet':
          if (currentObject.facets && Object.keys(currentObject.facets).length > Object.keys(newObject.facets).length) {
            action = 'delete_facets';
          } else if (
            currentObject.facets &&
            Object.keys(currentObject.facets).length < Object.keys(newObject.facets).length
          ) {
            action = 'add_facets';
          } else if (JSON.stringify(currentObject.facets) !== JSON.stringify(newObject.facets)) {
            action = 'update_facets';
          }
          // Next condition is important because we don't want to historize if the object haven't change
          if (action !== undefined && action !== "") {
            modifications.old_facets =  JSON.parse(JSON.stringify(currentObject.facets));
          }
          break;
        case 'comment':
          if (currentObject.title !== newObject.title) {
            action = 'update';
            modifications.old_title = JSON.parse(JSON.stringify(currentObject.title));
          }
          if (currentObject.comment !== newObject.comment) {
            action = 'update';
            modifications.old_comment = JSON.parse(JSON.stringify(currentObject.comment));
          }
          break;
        case 'sheetlist':
          if (currentObject.title !== newObject.title) {
            action = 'update';
            modifications.old_title = JSON.parse(JSON.stringify(currentObject.title));
          }
          if (currentObject.sheetlist.length > newObject.sheetlist.length) {
            action = 'delete_sheets';
          } else if (currentObject.sheetlist.length < newObject.sheetlist.length) {
            action = 'add_sheets';
          } else if (
            JSON.stringify(currentObject.sheetlist) !== JSON.stringify(newObject.sheetlist)
          ) {
            // When we delete and add sheets at the same time
            action = 'update_sheets';
          }
          // Next condition is important because we don't want to historize if the object haven't change
          if (action !== undefined && action !== 'update')
            modifications.old_sheetlist = JSON.parse(JSON.stringify(currentObject.sheetlist));
          break;
      }
      newObject.office = useConfigStore().office.id;
      newObject.last_modified_date = new Date().toISOString();
      this.updateObjectAction({
        index,
        newObject,
        authorId: useUserStore().user.contact_id,
      });
      // Writing history only when there is an action and RB is finished
      if (action !== undefined && this.metadata.is_finished)
        this.writeHistory({
          index,
          action,
          author: useUserStore().user.contact_id,
          modifications,
        } as Historic);
      await this.persistRoadbook();
    },
    async deleteObject(index: number) {
      const oldRoadbook = cloneDeep(this.$state);
      this.setOldRoadbook(oldRoadbook);
      this.currentFeed.last_modified_date = new Date().toISOString();
      if (this.metadata.is_finished) {
        this.deleteKeepingObject(index);
        this.writeHistory({
          index,
          action: 'delete',
          author: useUserStore().user.contact_id,
          modifications: {},
        } as Historic);
      } else {
        this.deletePermanentlyObject(index);
      }
      await this.persistRoadbook();
    },
    async persistRoadbook() {
      this.updateEditing(true);
      const newEntities = this.entities.map((entity: Entity) => {
        if (entity.id == this.currentEntity?.id && entity.feeds) {
          entity.feeds[entity.feeds.findIndex((feed: Feed) => {
            return feed.id == this.currentFeed.id;
          })] = this.currentFeed;
          return entity;
        } else {
          return entity;
        }
      });
      this.currentEntity.feeds?.map((feed: Feed) => {
        if (feed.id === this.currentFeed.id) {
          feed = this.currentFeed;
        }
      });
      this.entities = newEntities;
      const newState = {
        id: this.id,
        metadata: this.metadata,
        entities: this.entities,
        lastPayload: this.lastPayload,
      };
      const roadbook = JSON.parse(JSON.stringify(newState));
      roadbook.entities.map((entity: Entity) => {
        entity?.feeds?.map((feed: Feed) => {
          delete feed.name;
          delete feed.img;
          delete feed.urls;

          feed.objects?.map((object: Leaflet) => {
            if (object.kind === 'leaflet') {
              delete object.type;
              delete object.id_conf_hawwwai;
              delete object.modified;
            }
          })
        });
      });
      const roadbookUpdate: Roadbook | null | undefined = this.id ? await apiRoadbookService.edit(this.id, roadbook) : null;
      this.updateEditing(false);
      this.setLastPayload(roadbook);
      if (roadbookUpdate) {
        this.setWoodyUrl((roadbookUpdate as Roadbook).woodyUrl);
        this.setOldRoadbook(cloneDeep(this.$state));
      } else {
        this.setRoadbook(cloneDeep(this.oldRoadbook));
      }
    },
    async restart() {
      await this.persistRoadbook();
      this.resetRoadbook();
    },
    async updateFeedsName(id: string): Promise<void> {
      this.id = id;
      const response : HttpResponse = await apiRoadbookService.getFeed(id);
      const feeds: Array<Feed> = response.data;
      if (feeds) {
        feeds.forEach(async (feed) => {
          const newFeed = {
            entity_id: feed.partner_id,
            feed_id: feed.ref,
            name: feed.name,
            img: feed.img,
            urls: feed.urls,
          };
          await this.addFeed(newFeed);
        });
      }
    },
    langExtension(lang: string): string {
      return lang !== 'fr' && lang !== null ? ('/' + lang) : ''
    },
    updateUrlRoadbook(lang: string): void {
      const prod = window.location.href.match('app.roadbook.travel');
      if (prod && prod?.length !== 0) {
        this.setUrl(`https://roadbook.travel${this.langExtension(lang)}/r/`);
      } else {
        this.setUrl(`https://roadbook.wp.rc-preprod.com${this.langExtension(lang)}/r/`);
      }
    }
  }
});

