function psScrollTop(el: any): void {
    el.$el.classList.add('scroll-transition');
    el.$el.scrollTop = 0;
    el.$el.classList.remove('scroll-transition');
  }

  function psScrollTo(el, position) {
    el.$el.classList.add('scroll-transition');
    el.$el.scrollTop = position;
    // el.$el.scrollIntoView();
    el.$el.classList.remove('scroll-transition');
  }

  function psScrollToLeft(el, position) {
    el.$el.classList.add('scroll-transition');
    el.$el.scrollLeft = position;
    // el.$el.scrollIntoView();
    el.$el.classList.remove('scroll-transition');
  }

export {
    psScrollTo,
    psScrollTop,
    psScrollToLeft
}