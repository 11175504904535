<template lang="pug">
#country-field
  v-select#countrySelect(
    :options="countries"
    :model-value="countries.find(it => it.key === selectedCountry) || null"
    :placeholder="placeholder"
    @update:model-value="selectCountry"
  )
    template(#selected-option="option")
      div.country-flag(v-if="getCountryFlagUrl(option.key)" :style="{ 'background-image': `url(${getCountryFlagUrl(option.key)})` }")
      span {{ option.label }}
    template(#option="option")
      div.country-option(style="display: flex;")
        div.country-flag(v-if="getCountryFlagUrl(option.key)" :style="{ 'background-image': `url(${getCountryFlagUrl(option.key)})` }")
        span {{ option.label }}
    template(#no-options)
      span {{ $t('no_results') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useConfigStore, useRoadbookStore } from '@/store';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: null,
    },
  },
  computed: {
    countries(): Array<{ key: string; label: unknown | string }> {
      const countries: any = useConfigStore().config.countries || {}; // {ABW: "Aruba", AFG: "Afghanistan", ...}
      delete countries['CA-QC'];
      return (
        Object.entries(countries)
          .sort((a, b) => {
            const aIsMain = useConfigStore().config.main_countries?.includes(a[0]);
            const bIsMain = useConfigStore().config.main_countries?.includes(b[0]);
            if (aIsMain === bIsMain) {
              return (a[1] as any).localeCompare(b[1]);
            }
            return aIsMain ? -1 : 1;
          })
          .map((it) => {
            return { key: it[0], label: it[1] };
          }) || []
      );
    },
    selectedCountry(): string | null {
      return useRoadbookStore().metadata.visitor.country || null;
    },
  },
  methods: {
    selectCountry(country: { key: string; label: unknown | string }): void {
      const mainCountries: Array<string> = useConfigStore().config.main_countries || [];
      const arrayDataToUpdate = [
        {
          field: 'country',
          value: country ? country.key : mainCountries[0] || 'FRA',
        },
      ];
      if (country) {
        if (country.key !== 'CHE') {
          arrayDataToUpdate.push({ field: 'region', value: '' });
        }
        if (country.key !== 'FRA') {
          arrayDataToUpdate.push({ field: 'department', value: '' });
        }
      } else {
        arrayDataToUpdate.push({ field: 'region', value: '' });
        arrayDataToUpdate.push({ field: 'department', value: '' });
      }
      useRoadbookStore().setRoadbookModifiedDate();
      useRoadbookStore().updateVisitorMetadata(arrayDataToUpdate);
    },
    getCountryFlagUrl(country: string): string {
      const code = useConfigStore().cca2Codes[country.replaceAll('-', '')];
      return code ? new URL(`/src/assets/flags/countries/${code}.png`, import.meta.url).href : '';
    },
  },
});
</script>

<style lang="postcss">
#country-field {
  @apply flex m-[8px];

  option.empty {
    @apply opacity-0;
  }
}

#countrySelect,
#client-department-select,
#weatherSelect {
  @apply v-select-layer;
  .vs__clear {
    display: none;
  }

  .vs__actions svg path {
    stroke: theme('colors.white') !important;
  }

  &.vs--open {
    @apply z-20;
    .vs__selected {
      @apply top-[6px] z-10 flex absolute;
    }
  }
}
#phoneSelect {
  &.vs--open {
    .vs__selected {
      @apply top-[4px];
    }
  }
}
</style>
