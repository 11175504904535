<script lang="ts">
export default {
  methods: {
    rcDebounce(func, wait = 300) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        func();
      }, wait);
    },
    rcDebounce2(func, wait = 300) {
      if (!this.timeout) {
        this.timeout = setTimeout(() => {
          func();
          clearTimeout(this.timeout);
        }, wait);
      }
    },
  },
};
</script>
