import { defineStore } from 'pinia';
import apiEsService from '@/services/api/esService';
import settings from '@/utils/settings';
import { useRoadbookStore } from './roadbook';
import { Leaflet } from '@/models/interfaces/Leaflet.interface';

export const useLeafletsStore = defineStore({
  id: 'leaflets',
  state: () => ({
    leafletFilters: {} as any,
    search: null as string | null,
    loading: false as boolean,
    leaflets: [] as Array<Leaflet>,
    metadata: null as any,
    type: null as any,
    page: 0 as number,
    lastDatePicked: { from: "", to: "" } as {from: string, to: string} ,
  }),
  actions: {
    nextPage() {
      this.page++;
    },
    resetPage() {
      this.page = 0;
    },
    setLoading(value: boolean) {
      this.loading = value;
    },
    setMetadata(metadata: any) {
      this.metadata = metadata;
    },
    setLeaflets(leaflets: Array<Leaflet>) {
      this.leaflets = leaflets;
    },
    setType(type: string) {
      this.resetPage();
      this.type = type;
      if (type === 'feeds') {
        this.leafletFilters = {};
      }
    },
    addLeaflets(leaflets: Array<Leaflet>) {
      this.leaflets = [...this.leaflets, ...leaflets];
    },
    addLeafletCategory({ categoryId, tagId }) {
      this.resetPage();
      if (this.type === 'feeds') {
        this.type = null;
      }
      this.leafletFilters[categoryId] = [...(this.leafletFilters[categoryId] || []), tagId];
    },
    removeLeafletCategory({ categoryId, tagId }) {
      this.resetPage();
      if (this.type === 'feeds') {
        this.type = null;
      }
      this.leafletFilters[categoryId] = this.leafletFilters[categoryId]
        ? [...this.leafletFilters[categoryId].filter((it) => it !== tagId)]
        : [];
      if (!this.leafletFilters[categoryId].length) {
        delete this.leafletFilters[categoryId];
      }
    },
    resetCategory({ categoryId }) {
      this.resetPage();
      delete this.leafletFilters[categoryId];
    },
    setSearch(search: string, reset: boolean = false) {
      this.resetPage();
      this.search = search?.length ? search : null;
      reset ? this.setMetadata(null) : this.getLeaflets();
    },
    setLastDatePicked(value: { from: string, to: string }) {
      this.lastDatePicked = value;
    },
    async getLeaflets() {
      this.setLoading(true);
      const filters = this.leafletFilters;
      const page = this.page;
      const type = this.type;
      const search = this.search;
      const start = page * settings.pageSize;
      const lang = useRoadbookStore().metadata.visitor.language || 'fr';
      const leaflets: any = await apiEsService.searchLeaflets({
        start,
        size: settings.pageSize,
        filters,
        search,
        lang,
        type,
      });
      if (page === 0) {
        this.setLeaflets(leaflets.data);
      } else {
        this.addLeaflets(leaflets.data);
      }
      this.setMetadata(leaflets.metadata);
      this.setLoading(false);
    },
  },
});
