<template lang="pug">
transition(name='modal')
  .modal-mask(v-if="show")
    .modal-wrapper
      .modal-container#modal(:style="{ width, height }")
        div.cadre(style="display: flex; justify-content: center;")
          .close(@click="$emit('close')")
            rc-svg(name="croix")
          slot
</template>

<script setup lang="ts">
import RcSvg from '@/components/medias/RcSvg.vue';
import { onMounted, onUnmounted } from 'vue';

defineProps({
  width: {
    type: String,
    default: '800px',
  },
  height: {
    type: String,
    default: 'initial',
  },
  closeIcon: {
    type: String,
    default: 'enabled',
  },
  show: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

onMounted(() => {
  document.addEventListener('keydown', close);
  setTimeout(() => {
    document.addEventListener('click', close, true);
  }, 200);
});

onUnmounted(() => {
  document.removeEventListener('keydown', close);
  document.removeEventListener('click', close);
});

function close(e) {
  if (e.type === 'keydown' && e.key === 'Escape') {
    emit('close');
  } else if (e.type === 'click') {
    const modal = document.getElementById('modal');
    if (modal && !modal.contains(e.target) && e.target.tagName != 'SPAN') {
      emit('close');
    }
  }
}
</script>

<style lang="postcss" scoped>
@media screen and (max-width: 800px) {
  .modal-container {
    width: 700px !important;
  }
}
@media screen and (max-width: 500px) {
  .modal-container {
    width: 300px !important;
    height: 90vh !important;
  }
}

.modal-mask {
  @apply fixed z-[9998] top-0 left-0 w-full h-full backdrop-blur-[5px] bg-real-black/50 flex transition-[opacity] duration-300 ease-in-out;

  &.error-dialog {
    .modal-container {
      @apply relative px-0 pt-0 pb-standard;
      .close {
        position: absolute !important;
        top: 20px !important;
        right: 20px !important;
        color: white !important;
      }
    }
  }
}

.modal-wrapper {
  @apply flex justify-center items-center flex-1 h-full;
}

.modal-container {
  @apply overflow-hidden block my-0 mx-[40px] p-standard bg-white rounded-normal transition duration-300 ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);

  @media screen and (max-width: 500px) {
    .wrapper {
      width: 100% !important;
      height: 90vh !important;
    }
  }

  .close {
    @apply cursor-pointer absolute right-0;

    @media (hover: hover) {
      &:hover {
        @apply transition duration-300 ease-in-out;
        transform: rotate(90deg);
      }
    }

    svg {
      @apply stroke-white stroke-[1.5px] m-[10px];
    }
  }
}

.cadre {
  @apply flex-1 relative;
}

.modal-enter {
  @apply opacity-0 backdrop-blur-[5px] bg-real-black/50;
}

.modal-leave-active {
  @apply opacity-0;
}

.modal-enter-active {
  @apply opacity-100 backdrop-blur-[5px] bg-real-black/50;
}

.modal-leave {
  @apply opacity-100;
}

#last-roadbooks {
  .modal-container {
    @apply px-0 py-[20px];
  }
}
</style>
