<template lang="pug">
.contact(v-if="!checkOnDevice()[0]" :class="{'noSending': !isSending}")
  perfect-scrollbar
    .contact__container
      .contact__row__container
        perfect-scrollbar.scroll-input(ref="scrollInput")
          .contact--row--wrapper(v-for="(contact, index) in (contactListComputed.value)" :key="index" ref="contactInput")
            .deleteIcon(v-if="contactListComputed.value.length > 1" @click="removeField(index)")
              RcSvg(name="croix")
            .row
              rc-phone-field(
                v-show="smsActive || !isSending"
                @change-prefix="val => prefixes[index] = val.code"
                @input="(value) => editContactPhone(value,index)"
                :value-input="contact && contact.phone || null"
                :empty="contact && contact.phone === null "
                :removing="false"
                :append-to-body="true"
                :debounce="false"
                :prefix="prefixes[index]"
                :placeholder="$t('client_info.placeholder.phone', needTranslate)"
                :invalid="contactsMessagesFailed.find((c) => formatNumber(index, contact.phone) === c.value)"
              )
              rc-email-field(
                :value-input="(contact && contact.email) || null"
                @input="(value) => editContactEmail(value,index)"
                :removing="false"
                :debounce="false"
                :placeholder="$t('client_info.placeholder.email' , needTranslate)"
                :empty="contact && contact.value  === null"
                :invalid="contactsMessagesFailed.find((c) => contact.email === c.value)"
              )
            .row
              .name-infos
                input.firstname(type="text" :placeholder="$t('add_user_modal.user_informations.firstname', needTranslate)" @input="(value) => editContactFirstName(value,index)" v-model="contact.first_name")
                input.lastname(type="text" :placeholder="$t('add_user_modal.user_informations.lastname', needTranslate)" @input="(value) => editContactLastName(value,index)" v-model="contact.last_name")
              .optins(v-if="isOptinParent || isPro()")
                span {{ $t('client_info.newsletter_optin', needTranslate) }} {{ $t('from') }} {{ ':' }}
                .optins-row
                  rc-optin-field(v-if="!isJOParis" :index="index" :index-in-row="0" :value="contact.is_optin" :label="false" @input="(value) => editContactOptin(value,index)")
                  span(v-if="!isJOParis") {{ getDestiName() }}
                  rc-optin-field(v-if="isOptinParent" :index="index" :index-in-row="1"  :value="contact.is_optin_parent" :label="false" @input="(value) => editContactOptinParent(value,index)")
                  span(v-if="isOptinParent") {{ "Agence d'Attractivité de la Drôme" }}
                  rc-optin-field(v-if="isPro()" :index="index" :index-in-row="2"  :value="contact.is_optin_pro" :label="false" @input="(value) => editContactOptinPro(value,index)")
                  span(v-if="isPro()") {{ getProName() }}
              .optin-row(v-else)
                rc-optin-field(:index="index" :value="contact.is_optin" :label="false" @input="(value) => editContactOptin(value,index)")
                span {{ $t('client_info.newsletter_optin', needTranslate) }} {{ $t('from') }} {{ entityTitle }}
          .contact--addContact--wrapper
            RdbkButton.contact--extraFieldBtn(@click="addContact()" :mode="'outline'" :label="$t('new_contact_field')") {{ $t('new_contact_field', needTranslate) }}
.contactMobile(v-else :class="{'noSending': !isSending}")
  span.contact__sendRoadBook {{ $t('send_roadBook') }}
  .contact__container
    .contact__row__container
      span.contact__row__container__title {{ $t('date_picker_title') }}
      .contact__period__buttons
        .contact__period__buttons__row
          .contact__period__button(@click="today()" :class="isActive(currentPreset, {from : moment().startOf('day'), to : moment().endOf('day')}) ? 'selected' : ''") {{ $t('date_presets.today') }}
          .contact__period__button(@click="tommorow()" :class="isActive(currentPreset, {from : moment().add(1, 'days').startOf('day'), to : moment().add(1, 'days').endOf('day')}) ? 'selected' : ''") {{ $t('date_presets.tomorrow') }}
          .contact__period__button(@click="thisWeek()" :class="isActive(currentPreset, {from : moment().startOf('week'), to : moment().endOf('week')}) ? 'selected' : ''") {{ $t('date_presets.this_week') }}
        .contact__period__buttons__row
          .contact__period__button(@click="thisWeekend()" :class="isActive(currentPreset, {from : moment().day(5).startOf('day'), to : moment().day(7).endOf('day')}) ? 'selected' : ''") {{ $t('date_presets.this_weekend') }}
          .contact__period__button__custom
            RcDateRangePicker(:use-default-presets="false" :editable="false" :client-info-field="true" :is-period-mobile="true")
      span.contact__row__container__title {{ $t('recipients') }}
      .contact--row--wrapper(v-for="(contact, index) in (contactListComputed.value)" :key="index" ref="contactInput")
        rc-phone-field(
          v-show="smsActive || !isSending"
          @change-prefix="val => prefixes[index] = val.code"
          @input="(value) => editContactPhone(value,index)"
          :value-input="contact && contact.phone || null"
          :empty="contact && contact.phone === null "
          :removing="false"
          :append-to-body="true"
          :debounce="false"
          :prefix="prefixes[index]"
          :placeholder="$t('client_info.placeholder.phone', needTranslate)"
          :invalid="contactsMessagesFailed.find((c) => formatNumber(index, contact.phone) === c.value)"
          :is-border="true"
        )
        rc-email-field(
          :value-input="(contact && contact.email) || null"
          @input="(value) => editContactEmail(value,index)"
          :removing="false"
          :debounce="false"
          :placeholder="$t('email')"
          :empty="contact && contact.value  === null"
          :invalid="contactsMessagesFailed.find((c) => contact.email === c.value)"
          :is-border="true"
        )
        .row
          .name-infos
            input.firstname(type="text" :placeholder="$t('add_user_modal.user_informations.firstname', needTranslate)" @input="(value) => editContactFirstName(value,index)" v-model="contact.first_name")
            input.lastname(type="text" :placeholder="$t('add_user_modal.user_informations.lastname', needTranslate)" @input="(value) => editContactLastName(value,index)" v-model="contact.last_name")
        .optins(v-if="isOptinParent || isPro()")
          span {{ $t('client_info.newsletter_optin', needTranslate) }} {{ $t('from') }} {{ ':' }}
          .optins-row
            rc-optin-field(v-if="!isJOParis" :index="index" :index-in-row="0" :value="contact.is_optin" :label="false" @input="(value) => editContactOptin(value,index)")
            span(v-if="!isJOParis") {{ getDestiName() }}
            rc-optin-field(v-if="isOptinParent" :index="index" :index-in-row="1" :value="contact.is_optin_parent" :label="false" @input="(value) => editContactOptinParent(value,index)")
            span(v-if="isOptinParent") {{ "Agence d'Attractivité de la Drôme" }}
            rc-optin-field(v-if="isPro()" :index="index" :index-in-row="2" :value="contact.is_optin_pro" :label="false" @input="(value) => editContactOptinPro(value,index)")
            span(v-if="isPro()") {{ getProName() }}
        .optin-row(v-else)
          rc-optin-field(:index="index" :value="contact.is_optin" :label="false" @input="(value) => editContactOptin(value,index)")
          span {{ $t('client_info.newsletter_optin', needTranslate) }} {{ $t('from') }} {{ entityTitle }}
    .footerContactMobile
      .cancel(@click="emit('closeOverlay')") {{ $t('cancel') }}
      RdbkButton.send(@click="!isChecked ? '' : sendRoadBook()" :mode="!isChecked ? 'disabled' : 'important'" :label="$t('send')" :disabled="!isChecked" :icon-name="'send'") {{ $t('send') }}
</template>
<script setup lang="ts">
import { computed, ref, nextTick, onMounted, inject, provide } from 'vue';
import type { Ref, PropType } from 'vue';
import RcEmailField from '@/components/inputs/form//RcEmailField.vue';
import RcPhoneField from '@/components/inputs/form/RcPhoneField.vue';
import RcOptinField from '@/components/inputs/form/RcOptinField.vue';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import RcSvg from '@/components/medias/RcSvg.vue';
import { useRoadbookStore, useConfigStore } from '@/store';
import type { Contact } from '@/models/interfaces';
import { isEmailValid } from '@/utils/functions/utils';
import { psScrollToLeft } from '@/utils/functions/scroll';
import { useMixin } from '@/utils/mixins/useMixin.mixin';
import RdbkButton from '@/components/inputs/RdbkButton.vue';
import RcDateRangePicker from '@/components/inputs/pickers/calendar/RcDateRangePicker.vue';
import moment from 'moment';
import { isPro } from '@/utils/functions/utils';

// General
provide('$moment', moment);
const emit = defineEmits(['updateContactList', 'closeModal', 'closeOverlay', 'sendToContactList']);
const props = defineProps({
  contacts: {
    type: Array as PropType<Array<Contact>>,
    default: () => {
      return [];
    },
  },
  isSending: { type: Boolean, default: false },
  contactsMessagesFailed: {
    type: Array as PropType<Array<Contact>>,
    default: () => {
      return [];
    },
  },
});
const { language, needTranslate, checkOnDevice } = useMixin();
const $t: any = inject('$t');
const contactInput = ref([]);
const scrollInput = ref(null);

// Data
const prefixes: Ref<Array<string | undefined>> = ref([]);
const isChecked: Ref<boolean> = ref(false);
const contactListMutable: Ref<Array<Contact>> = ref([...props.contacts]);

// Is pro from Drôme ?
const isOptinParent: Ref<boolean> = ref(useConfigStore().config.erp_partner_id == 377668);
const isJOParis: Ref<boolean> = ref(useConfigStore().config.erp_partner_id == 943262);

const entityTitle = computed(() => {
  const currentFeed =
    useConfigStore().config.feeds?.find((feed) => {
      return feed.lang == language.value && feed.entity_id == useRoadbookStore().currentEntity.id;
    }) ||
    useConfigStore().config.feeds?.find((feed) => {
      return feed.lang == 'fr' && feed.entity_id == useRoadbookStore().currentEntity.id;
    });
  return currentFeed?.title;
});

const smsActive = computed(() => {
  return useConfigStore().config.sms_active;
});

const contactListComputed = computed(() => contactListMutable);

const currentPreset = computed(() => {
  return useRoadbookStore().metadata.trip.period;
});
// Hooks
onMounted(() => {
  const defaultCountry = useRoadbookStore().metadata.visitor.country;
  if (contactListMutable.value.length > 0) {
    for (let i = 0; i < contactListMutable.value.length; i++) {
      contactListMutable.value[i].phone &&
      parsePhoneNumberFromString(contactListMutable.value[i].phone || '')?.country
        ? prefixes.value.push(
            parsePhoneNumberFromString(contactListMutable.value[i].phone || '')?.country as string,
          )
        : prefixes.value.push(useConfigStore().cca2Codes[defaultCountry]);
    }
  } else {
    prefixes.value.push(
      undefined,
      useConfigStore().cca2Codes[defaultCountry]
        ? useConfigStore().cca2Codes[defaultCountry]
        : defaultCountry,
    );
  }
  nextTick(() => {
    if (props.isSending) {
      let i = 0;
      const cleanContacts: Array<Contact> = [];
      const filterList = contactInput.value.filter((contact: HTMLInputElement) => {
        const is_optin = (contact.querySelector('.optin-cb.checkbox') as HTMLInputElement).checked;
        const first_name = (contact.querySelector('.firstname') as HTMLInputElement).value;
        const last_name = (contact.querySelector('.lastname') as HTMLInputElement).value;
        const date = new Date().toISOString();
        const email = (contact.querySelector('.emailInput') as HTMLInputElement).value;
        const countryPrefix: any = prefixes[i];
        const newValue = new AsYouType(countryPrefix)?.input(
          (contact.querySelector('.phone-number-input') as HTMLInputElement).value,
        );
        const phoneNumber = parsePhoneNumberFromString(newValue, countryPrefix);
        i += 1;
        cleanContacts.push({
          email,
          phone: phoneNumber?.number as string,
          is_optin,
          date,
          first_name,
          last_name,
        });
        return (email && isEmailValid(email)) || (phoneNumber && phoneNumber.isValid());
      });
      if (filterList.length > 0) {
        nextTick(() => {
          emit('updateContactList', {
            list: cleanContacts,
            isGood: true,
          });
        });
      }
    }
    checkContact();
  });
});

// Methods
function isActive(preset: any, value: any): boolean {
  if (preset && preset.from && preset.to) {
    const from =
      typeof preset.from === 'string' && preset.from != '' ? moment(preset.from) : preset.from();
    const to = typeof preset.to === 'string' && preset.to != '' ? moment(preset.to) : preset.to();
    return (
      value &&
      value.from &&
      value.to &&
      value.from.isSame(from, 'day') &&
      value.to.isSame(to, 'day')
    );
  }
  return false;
}
function addContact(): void {
  contactListMutable.value.push({
    email: null,
    phone: null,
    is_optin: false,
    date: new Date().toISOString(),
    first_name: null,
    last_name: null,
  });
  const defaultCountry = useRoadbookStore().metadata.visitor.country;
  prefixes.value.push(
    useConfigStore().cca2Codes[defaultCountry]
      ? useConfigStore().cca2Codes[defaultCountry]
      : defaultCountry,
  );
  nextTick(() => {
    psScrollToLeft(
      scrollInput.value,
      document.querySelector('.contact__row__container')?.clientWidth,
    );
  });
  checkContact();
}

function removeField(index: number): void {
  contactListMutable.value.splice(index, 1);
  prefixes.value.splice(index, 1);
  isChecked.value = false;
  if (contactListMutable.value.length === 0) {
    contactListMutable.value.push({
      email: null,
      phone: null,
      is_optin: false,
      date: new Date().toISOString(),
      first_name: null,
      last_name: null,
    });
    useRoadbookStore().metadata.contact = [];
    const defaultCountry = useRoadbookStore().metadata.visitor.country;
    prefixes.value = [undefined, useConfigStore().cca2Codes[defaultCountry]];
    emit('updateContactList', {
      list: [],
      isGood: isChecked,
    });
  } else {
    nextTick(() => {
      checkContact();
      useRoadbookStore().metadata.contact = [
        {
          email: contactListMutable.value[0].email,
          phone: contactListMutable.value[0].phone,
          is_optin: contactListMutable.value[0].is_optin,
          first_name: contactListMutable.value[0].first_name,
          last_name: contactListMutable.value[0].last_name,
        },
      ];
    });
  }
  nextTick(() => {
    psScrollToLeft(
      scrollInput.value,
      document.querySelector('.contact__row__container')?.clientWidth,
    );
  });
}

function editContactEmail(value, index) {
  contactListMutable.value[index].email = value;
  checkContact();
  if (index == contactListMutable.value.length - 1 && checkOnDevice()[0]) {
    addContact();
  }
}

function editContactPhone(value, index) {
  contactListMutable.value[index].phone = value;
  checkContact();
  if (index == contactListMutable.value.length - 1 && checkOnDevice()[0]) {
    addContact();
  }
}

function editContactOptin(value, index) {
  contactListMutable.value[index].is_optin = value;
  checkContact();
}

function editContactOptinParent(value, index) {
  contactListMutable.value[index].is_optin_parent = value;
  checkContact();
}

function editContactOptinPro(value, index) {
  contactListMutable.value[index].is_optin_pro = value;
  checkContact();
}

function editContactFirstName(value, index) {
  contactListMutable.value[index].first_name = value.target.value;
  checkContact();
}

function editContactLastName(value, index) {
  contactListMutable.value[index].last_name = value.target.value;
  checkContact();
}

function formatNumber(index, phone): string | undefined {
  const countryPrefix: any = prefixes.value[index];
  const newValue = phone ? new AsYouType(countryPrefix).input(phone) : '';
  const phoneNumber = newValue ? parsePhoneNumberFromString(newValue, countryPrefix) : null;
  return phoneNumber?.format('E.164');
}

function checkContact(): void {
  isChecked.value = true;
  let editingInput = false;
  const cleanContacts: Array<Contact> = [];

  const filterList = contactListMutable.value.filter((contact, index) => {
    const { email, phone, is_optin, is_optin_parent, is_optin_pro, first_name, last_name } =
      contact;

    // Email validation.
    const isEmailValidResult = email && isEmailValid(email);
    if (email && !isEmailValidResult) {
      isChecked.value = false;
      editingInput = true;
    }

    // Phone validation / formatting.
    const countryPrefix: any = prefixes.value[index];
    const formattedPhone = phone ? new AsYouType(countryPrefix).input(phone) : '';
    const phoneNumber = formattedPhone
      ? parsePhoneNumberFromString(formattedPhone, countryPrefix)
      : null;
    const isPhoneValid = phoneNumber?.isValid();
    const formattedPhoneNumber = isPhoneValid
      ? phoneNumber
        ? phoneNumber.format('E.164')
        : ''
      : '';

    if ((formattedPhone && !phoneNumber) || (phoneNumber && !isPhoneValid)) {
      isChecked.value = false;
      editingInput = true;
    }

    cleanContacts.push({
      email,
      phone: formattedPhoneNumber,
      is_optin,
      is_optin_parent,
      is_optin_pro,
      first_name,
      last_name,
    });

    return isEmailValidResult || isPhoneValid;
  });

  if (filterList && !props.isSending) {
    for (let i = 0; i < filterList.length; i++) {
      isChecked.value = filterList[i] !== props.contacts[i];
    }
  }

  nextTick(() => {
    if (editingInput || !filterList.length) {
      isChecked.value = false;
    }
    emit('updateContactList', {
      list: cleanContacts,
      isGood: isChecked,
    });
  });
}

function today() {
  useRoadbookStore().updateMetadata({
    category: 'trip',
    field: 'period',
    value: {
      from: moment().startOf('day').toISOString(true),
      to: moment().endOf('day').toISOString(true),
    },
  });
}

function tommorow() {
  useRoadbookStore().updateMetadata({
    category: 'trip',
    field: 'period',
    value: {
      from: moment().add(1, 'days').startOf('day').toISOString(true),
      to: moment().add(1, 'days').endOf('day').toISOString(true),
    },
  });
}

function thisWeek() {
  useRoadbookStore().updateMetadata({
    category: 'trip',
    field: 'period',
    value: {
      from: moment().startOf('week').toISOString(true),
      to: moment().endOf('week').toISOString(true),
    },
  });
}
function thisWeekend() {
  useRoadbookStore().updateMetadata({
    category: 'trip',
    field: 'period',
    value: {
      from: moment().day(5).startOf('day').toISOString(true),
      to: moment().day(7).endOf('day').toISOString(true),
    },
  });
}
function sendRoadBook() {
  emit('closeOverlay');
  emit('sendToContactList');
}

function getProName() {
  return (
    useConfigStore().config.feeds?.find((feed) => {
      return feed.entity_id == useRoadbookStore().currentEntity.id;
    })?.name?.[language.value] ||
    useConfigStore().config.feeds?.find((feed) => {
      return feed.entity_id == useRoadbookStore().currentEntity.id;
    })?.name?.['fr']
  );
}

function getDestiName() {
  return useConfigStore().config.destination_name;
}
</script>

<style lang="postcss">
.failed {
  @apply border border-solid border-red-500 bg-red-100;
}

#modal-container {
  #modal-wrapper {
    @apply justify-between;
  }
}

.contact {
  @apply flex flex-col justify-between px-[2%] py-0 h-full;

  &.noSending {
    height: calc(100% - 240px);
  }

  .recipient {
    @apply flex text-[20px] text-primary mt-2;
  }

  &--register {
    @apply max-w-[200px] ml-auto;
  }

  .contact__container {
    @apply h-full;

    .contact__row__container {
      @apply flex flex-col max-w-[100%] py-4 h-full;

      .ps {
        @apply flex flex-col gap-6 pt-4 h-full items-center;

        .contact--row--wrapper {
          @apply flex flex-col relative border border-solid border-grey rounded-xl p-4 w-[95%] gap-2;

          @media screen and (max-width: 1000px) {
            @apply w-fit;
          }

          .deleteIcon {
            @apply flex absolute top-[-10px] right-[-10px] bg-primary rounded-full h-[25px] w-[25px] justify-center items-center cursor-pointer p-[6px];

            svg {
              @apply fill-white;
            }
          }

          .row {
            @apply flex gap-2 items-center;

            .name-infos {
              @apply flex flex-row gap-2 flex-1 min-w-[350px];

              input {
                @apply h-[34px] rounded-md pl-3 w-full;
                background-color: theme('colors.white-grey');
              }
            }

            @media (max-width: 950px) {
              .row {
                @apply flex flex-col;
              }
            }
          }

          @media (max-width: 950px) {
            .row {
              @apply flex flex-col items-start;

              .name-infos {
                @apply w-full;
              }
            }

            .double-optin {
              @apply !w-full;
            }
          }

          .contactTitle {
            @apply text-[20px] text-primary mt-4 ml-2;
          }

          .emailPhoneCheckBox {
            @apply flex flex-row gap-4 mt-2;

            .emailCheckBox {
              @apply ml-2;
            }

            .phoneCheckBox {
              @apply ml-4;
            }
          }

          .optin-row {
            @apply flex h-full items-center;

            span {
              @apply text-sm;
            }
          }

          .optins {
            @apply flex flex-col h-full items-center justify-center pl-4;

            .optins-row {
              @apply flex flex-row pt-1;

              span {
                @apply text-xs pr-4;
              }
            }

            span {
              @apply text-sm;
            }
          }
        }
      }
    }

    &--wrapper {
      @apply flex flex-col;

      .name-infos {
        @apply flex items-center;

        input {
          @apply mx-3 h-[34px] rounded-md pl-3;
          background-color: theme('colors.white-grey');
        }
      }

      .content-wrapper {
        @apply flex;

        .email-input {
          @apply my-auto;
        }
      }

      .optin-row {
        @apply flex;

        span {
          @apply text-sm;
        }

        .optin-field {
          @apply mt-[-2px];
        }
      }
    }

    &--optinButtons {
      @apply flex items-center ml-4;

      .optin-field {
        @apply mr-[3px];

        .optin-checkbox-wrapper {
          @apply ml-0 mr-standard pl-0;

          .checkbox-indicator {
            @apply relative;
          }
        }
      }

      .delete-field {
        @apply m-0 cursor-pointer;

        &.alone {
          visibility: hidden;
        }

        svg {
          @apply h-4 fill-gray-400 transition-[fill] duration-300 ease-in-out;

          @media (hover: hover) {
            &:hover {
              @apply fill-grey;
            }
          }
        }
      }
    }
  }

  .vs__selected {
    position: absolute !important;
  }

  .separator {
    @apply w-full my-4;
  }
}

.contactMobile {
  @apply flex flex-col h-full w-full items-center;

  .contact__sendRoadBook {
    @apply text-primary;
  }

  .contact__container {
    @apply flex flex-col w-full h-full mt-2 justify-between;

    .contact__row__container {
      @apply flex flex-col w-full gap-2;

      .contact--row--wrapper {
        @apply my-2;

        .deleteIcon {
          @apply flex absolute top-[-25px] right-[0px] bg-primary rounded-full h-[25px] w-[25px] justify-center items-center cursor-pointer p-[6px];

          svg {
            @apply fill-white;
          }
        }

        @apply flex flex-col relative py-2 w-full gap-4;

        .row {
          @apply flex flex-row gap-2 items-center;

          .name-infos {
            @apply flex gap-2 w-full;

            .firstname {
              @apply w-full border border-solid border-light-grey rounded-large;
            }

            .lastname {
              @apply w-full border border-solid border-light-grey rounded-large;
            }

            input {
              @apply h-[38px] rounded-md pl-3;
              background-color: theme('colors.white-grey');
            }
          }
        }

        .emailPhoneCheckBox {
          @apply flex flex-row gap-4 mt-2;

          .emailCheckBox {
            @apply ml-2;
          }

          .phoneCheckBox {
            @apply ml-4;
          }
        }

        .optin-row {
          @apply flex h-full items-center;

          span {
            @apply text-[10px];
          }
        }

        .optins {
          @apply flex flex-col h-full items-center w-full justify-center pt-2;

          .optins-row {
            @apply flex flex-row pt-1 w-full items-center justify-center;

            span {
              @apply text-xs pr-4;
            }
          }

          span {
            @apply text-sm;
          }
        }
      }

      .contact__row__container__title {
        @apply text-primary text-start mt-4;
      }

      .contact__period__buttons {
        @apply flex flex-col w-full gap-2;

        .contact__period__buttons__row {
          @apply w-full flex gap-2;

          .contact__period__button {
            @apply flex flex-row justify-center items-center rounded-md border border-solid border-grey text-sm p-2 h-full text-center;

            &.selected {
              @apply bg-primary text-white border-transparent transition duration-300 ease-in-out;
            }
          }
        }
      }
    }
  }

  .footerContactMobile {
    @apply flex flex-row justify-between w-full mt-4 items-center py-10;

    .cancel {
      @apply w-[49%] text-center font-bold;
    }

    .send {
      @apply w-[49%];
    }
  }
}

.custom-presets {
  @apply flex flex-row;
}

input:focus {
  @apply outline-primary;
}

@media screen and (max-width: 950px) {
  .dates-add {
    @apply border-none;
  }
}

input {
  @apply border-none;
}

input:focus {
  @apply outline-none;
}
</style>
