<template lang="pug">
.dropdown-popup(:class="{ open: visibleCalendars}" @click.self="closeCalendars()")
  transition(name="slide-fade")
    .dates-dropdown(v-if="visibleCalendars" :style="{ top: calendarPosition.top + 'px', left: calendarPosition.left + 'px' }")
      .calendars-wrapper
        RcSvg.selector-prev.click(name="left" @click="previousMonth()")
        .date-pickers-wrapper
          rc-date-picker(ref="firstDatePicker" :value="value" :year="year" :month="month" :range="value" :disabled="disabled" :hovering="hovering" @hover="day => { hovering = day; }")
          rc-date-picker(ref="secondDatePicker" :value="value" :year="secondYear" :month="secondMonth"  :range="value" :disabled="disabled" :hovering="hovering" @hover="day => { hovering = day; }" second)
        RcSvg.selector-next.click(name="right" @click="nextMonth()")
      .handle-date-wrapper
        .cancel-date(@click="cancelAction()") {{ $t('cancel', needTranslate) }}
        .empty-date(v-if="hasCustomPreset && lastActiveIndex && !isClientInfoField && hasCurrentDate" @click="deleteAction()") {{ $t('delete', needTranslate) }}
        RdbkButton(:mode="'important'" :disabled="!value.from" @click="value.from ? validateAction() : null" :label="$t('apply', needTranslate)")
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RcDatePicker from '@/components/inputs/pickers/calendar/RcDatePicker.vue';
import mixin from '@/utils/mixins/RcMixin.vue';
import RcSvg from '@/components/medias/RcSvg.vue';
import { useRoadbookStore, usePlaylistStore, useCalendarStore } from '@/store';
import RcCalendarMixinVue from '@/utils/mixins/RcCalendar.mixin.vue';
import RdbkButton from '../../RdbkButton.vue';

export default defineComponent({
  components: {
    RcDatePicker,
    RcSvg,
    RdbkButton,
  },
  mixins: [mixin, RcCalendarMixinVue],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovering: null as any,
      value: {
        from: null as string | null,
        to: null as string | null,
      },
      year: 1969 as number,
      secondYear: 1969 as number,
      month: 1 as number,
      secondMonth: 2 as number,
    };
  },
  computed: {
    language(): string {
      return useRoadbookStore().metadata.visitor.language;
    },
    hasCustomPreset(): number {
      return usePlaylistStore().datePresets.length;
    },
    visibleCalendars: {
      get(): boolean {
        return useCalendarStore().opened;
      },
      set(value: boolean): void {
        useCalendarStore().toggleOpened(value);
      },
    },
    calendarPosition() {
      return useCalendarStore().position;
    },
    lastActiveIndex() {
      return useCalendarStore().lastActiveIndex;
    },
    currentDate() {
      return useCalendarStore().currentValue;
    },
    customPresets() {
      return usePlaylistStore().datePresets;
    },
    datesEditing() {
      return useCalendarStore().datesEditing;
    },
    lastActiveFixedPreset() {
      return useCalendarStore().lastActiveFixedPreset;
    },
    hasCurrentDate(): boolean {
      return (
        useCalendarStore().currentValue?.from != null && useCalendarStore().currentValue?.to != null
      );
    },
    isClientInfoField(): boolean {
      return (
        this.$route.name === 'preview' ||
        this.$route.name === 'client_info' ||
        this.$route.name === 'roadbook_edit' ||
        this.$route.name === 'languages'
      );
    },
  },

  watch: {
    currentDate: {
      handler() {
        if (this.currentDate) {
          this.value.from = this.currentDate.from;
          this.value.to = this.currentDate.to;
          if (this.value.from) {
            this.year = this.value.from.year();
            this.month = this.value.from.month();
            if (this.month === 11) {
              this.secondYear = this.value.from.year() + 1;
              this.secondMonth = 0;
            } else {
              this.secondYear = this.value.from.year();
              this.secondMonth = this.month + 1;
            }
          }
        }
      },
      deep: true,
    },
    value: {
      handler() {
        let from = this.value.from;
        let to = this.value.to;
        let changes = false;

        if (from && to && from.isAfter(to)) {
          to = from.clone();
          changes = true;
        } else if (from && to && to.isBefore(from)) {
          from = to.clone();
          changes = true;
        }

        if (changes) {
          useCalendarStore().setCurrentValue({ from, to });
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.dropdownCoordinates = this.calendarPosition;
    this.year = this.$moment().year();
    this.month = this.$moment().month();
    if (this.month === 11) {
      this.secondYear = this.$moment().year() + 1;
      this.secondMonth = 0;
    } else {
      this.secondYear = this.$moment().year();
      this.secondMonth = this.month + 1;
    }
  },
  methods: {
    cancelAction() {
      this.closeCalendars();
      this.setLastActiveIndex();
      useCalendarStore().setDatesEditing(false);
    },
    deleteAction() {
      this.removeCustomPreset();
      this.closeCalendars();
      useCalendarStore().setDatesEditing(false);
    },
    validateAction() {
      this.closeCalendars();
      this.handleCustomPreset();
    },
    closeCalendars() {
      this.$nextTick(() => {
        this.visibleCalendars = !this.visibleCalendars;
      });
    },
    previousMonth() {
      if (this.month - 1 < 0) {
        this.month = 11;
        this.secondMonth = 0;
        this.year -= 1;
      } else if (this.month === 11) {
        this.month = 10;
        this.secondYear -= 1;
        this.secondMonth = 11;
      } else {
        this.month -= 1;
        this.secondMonth -= 1;
      }
    },
    nextMonth() {
      if (this.month + 1 > 10 && this.month !== 11) {
        this.month = 11;
        this.secondMonth = 0;
        this.secondYear += 1;
      } else if (this.month === 11) {
        this.month = 0;
        this.year += 1;
        this.secondMonth = 1;
      } else {
        this.month += 1;
        this.secondMonth += 1;
      }
    },
    setLastActiveIndex() {
      if (this.lastActiveFixedPreset !== '') {
        useCalendarStore().setCurrentValue({
          from: this.presets[this.lastActiveFixedPreset].from(),
          to: this.presets[this.lastActiveFixedPreset].to(),
        });
      }
      if (this.lastActiveIndex !== '') {
        const preset = document.querySelector(`[data-index='${this.lastActiveIndex}']`);
        if (preset && !preset.hasAttribute('active')) {
          this.executeCustomPreset(this.filteredPresets(this.customPresets)[this.lastActiveIndex]);
        }
      }
    },
    executeCustomPreset(preset) {
      if (preset && preset.from && preset.to) {
        this.isActive(preset, this.value) && !this.visibleCalendars
          ? this.emptyDate()
          : this.defineCurrentValue(preset);
      }
    },
    removeCustomPreset() {
      usePlaylistStore().removeDatePreset(this.lastActiveIndex);
      useCalendarStore().setCurrentValue({ from: null, to: null });
      useCalendarStore().setDatesAsFilter(true);
    },
    handleCustomPreset() {
      if (this.isClientInfoField) {
        this.handleDatesQualification();
      } else {
        if (this.value.from && !this.value.to) {
          this.value.to = this.value.from;
        }
        const indexBeforeUpdate = this.customPresets.findIndex(
          (preset) => preset === this.filteredPresets(this.customPresets)[this.lastActiveIndex],
        );
        if (this.lastActiveIndex !== '' && this.datesEditing) {
          usePlaylistStore().removeDatePreset(indexBeforeUpdate);
        }
        this.validateDates();
        if (this.datesEditing) {
          this.$nextTick(() => {
            usePlaylistStore().reorderDatePreset({ fromIndex: 0, toIndex: +indexBeforeUpdate });
            this.defineCurrentValueIndex(this.filteredPresets(this.customPresets));
          });
        }
        useCalendarStore().setDatesEditing(false);
      }
    },
    handleDatesQualification() {
      if (!this.value.from) {
        this.closeCalendars();
        return;
      }
      if (this.value.from && !this.value.to) {
        this.value.to = this.value.from;
      }
      const rdbkPeriod = {
        start: this.value.from.startOf('day').toISOString(true),
        end: this.value.to.endOf('day').toISOString(true),
      };
      usePlaylistStore().addDatePreset(rdbkPeriod);
      useCalendarStore().setCurrentValue(this.value);
      this.validateDates();
    },
    validateDates(): void {
      if (this.isClientInfoField) {
        this.$nextTick(() => {
          if (
            this.customPresets.some(
              (date) =>
                date.from == this.value.from.startOf('day').toISOString(true) &&
                date.to == this.value.to.endOf('day').toISOString(true),
            )
          ) {
            const index = this.customPresets.findIndex(
              (date) =>
                date.from == this.value.from.startOf('day').toISOString(true) &&
                date.to == this.value.to.endOf('day').toISOString(true),
            );
            useCalendarStore().setLastActiveIndex(index.toString());
          } else {
            useCalendarStore().setLastActiveIndex('0');
          }
          useRoadbookStore().updateMetadata({
            category: 'trip',
            field: 'period',
            value: this.customPresets[this.lastActiveIndex],
          });
          useCalendarStore().setDatesAsFilter(true);
        });
      } else {
        useCalendarStore().setCurrentValue(this.value);
        useCalendarStore().setDatesAsFilter(true);
        this.$nextTick(() => {
          this.defineCurrentValueIndex(this.customPresets);
        });
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.handle-date-wrapper {
  @apply flex justify-center items-center m-standard gap-[30px];
  @media screen and (max-width: 950px) {
    @apply flex-col-reverse justify-center;
    .empty-date {
      @apply !m-0;
    }
  }

  .cancel-date {
    @apply text-middle-grey cursor-pointer text-[16px];
    @apply transition duration-300 ease-in-out;

    @media (hover: hover) {
      &:hover {
        @apply text-primary;
      }
    }
  }

  .validate-date,
  .empty-date {
    @apply flex justify-center items-center text-[16px] font-bold border-[1px] border-solid border-primary min-h-[46px] bg-primary text-primary box-border text-center px-[24px] py-0 mr-[30px] cursor-pointer rounded-large;
    @apply transition duration-300 ease-in-out;

    @media (hover: hover) {
      &:hover {
        @apply text-white border-[1px] border-solid border-secondary-blue bg-secondary-blue;
        @apply transition duration-300 ease-in-out;
      }
    }
    &[disabled='true'] {
      @apply text-white border-[1px] border-solid border-light-grey bg-light-grey cursor-not-allowed;
      @apply transition duration-300 ease-in-out;
    }
  }

  .empty-date {
    @apply border-[2px] border-solid border-primary bg-white text-primary;
    @media (hover: hover) {
      &:hover {
        @apply text-white border-[2px] border-solid border-primary bg-primary;
        @apply transition duration-300 ease-in-out;
      }
    }
  }
}

.date-pickers-wrapper {
  @apply flex justify-between mb-standard gap-[30px];
}

@media screen and (max-width: theme('screens.xxl')) {
  .date-pickers-wrapper {
    @apply flex-col;
  }
}

.date-picker {
  @media screen and (min-width: 1741px) {
    &:first-child {
      @apply mr-[8px];
    }
    &:last-child {
      @apply ml-[8px];
    }
  }
}

.dropdown-popup {
  @apply fixed z-50 top-0 left-0 w-full h-full bg-real-black/50 hidden opacity-0 transition-[opacity] duration-300 ease-in-out;
  font-family: Poppins, sans-serif;

  &.open {
    @apply flex items-center justify-center opacity-100 backdrop-blur-[5px] overflow-scroll;
  }
}
::-webkit-scrollbar {
  display: none;
}

.dates-dropdown {
  @apply flex flex-col rounded-normal z-[5] m-[5px] bg-white max-w-[630px] p-standard max-h-[95%] overflow-scroll;
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.15);

  .calendars-wrapper {
    @apply relative flex justify-evenly items-center text-grey w-[600];

    .selector-prev {
      @apply h-[20px] mt-[88px];
    }

    .selector-next {
      @apply h-[20px] mt-[88px];
    }
  }
}
</style>
