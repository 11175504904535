import roadbookService from '@/services/api/roadbookService';
import { computed } from 'vue';
import { useLeafletsStore, usePlaylistStore, useRoadbookStore, useTagManagerStore, useConfigStore, useUserStore } from '@/store';

function getRangeSliderUnit(facet, value) {
  if (facet) {
    switch(facet.properties.displayAs) {
      case 'currency':
        return `${value} €`;
      case 'distance':
        return value > 1000 ? `${value / 1000}km` : `${value}m`;
      case 'minutes':
        return Math.floor(value / 60) ? `${Math.floor(value / 60)}h${value % 60}` : `${value % 60}min` ;
      default:
        return `${value}`;
    }
  }
  return `${value}`;
}

function isSameString(string1: string, string2: string): boolean {
  return string1.toUpperCase().includes(string2?.toUpperCase()) && string2 != '';
}

function facetsAreHidden(facetListHTML, facetList) {
  let isToShow = false
  for (let i = 0; i < facetListHTML.length; i++) {
    if ((facetListHTML[i] as HTMLElement).offsetTop > 84 || facetList[i].value === 0) {
      isToShow = true;
    }
    if (i + 1 == facetListHTML.length) {
      return isToShow;
    }
  }
}

function isEmailValid(email: string): boolean {
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  return regexEmail.test(email);
}

// function roadbookUrl() {
//   return useRoadbookStore().url + useRoadbookStore().id;
// }

const roadbookUrl = computed(()=> useRoadbookStore().url + useRoadbookStore().id)

function roadbookPrintUrl() {
  return (
    new URL(useRoadbookStore().woodyUrl.endsWith('/') ? useRoadbookStore().woodyUrl.slice(0, -1) : useRoadbookStore().woodyUrl).origin +
    '/r/print/' +
    useRoadbookStore().id
  );
}

function deleteRoadBook(){
  const roadbookId = useRoadbookStore().id;
  useTagManagerStore().sendEvent({
    event: 'roadbook_abandon',
  });
  useRoadbookStore().resetRoadbook();
  usePlaylistStore().emptyDatePresets();
  useLeafletsStore().setLastDatePicked({ from: '', to: '' });
  roadbookService.delete(roadbookId || "");
}

function isPro(): boolean {
  // ? no way to know if a feed is pro or not whithout erp_partner_id
  if (
    typeof useConfigStore().config.erp_partner_id == 'undefined' ||
    useConfigStore().config.erp_partner_id == null ||
    useConfigStore().config.erp_partner_id?.toString() == ''
  ) {
    return false;
  }
  return useUserStore().entityId != useConfigStore().config.erp_partner_id;
}

export {
  getRangeSliderUnit,
  isSameString,
  facetsAreHidden,
  isEmailValid,
  roadbookUrl,
  roadbookPrintUrl,
  deleteRoadBook,
  isPro,
}
