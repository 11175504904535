import { Metadata } from '@/models/interfaces/Roadbook.interface';
import { defineStore } from 'pinia';
import { useRoadbookStore } from './roadbook';

export const useGtmDataGetterStore = defineStore({
  id: 'gtmDataGetter',
  actions: {
    formatSheet(sheet) {
      return `${sheet.name} (${sheet.sheet_id})`;
    },
    formatSheetlist(sheetlist) {
      return `${sheetlist.title}`;
    },
    formatPlaylist(playlist) {
      return `${playlist.name.fr} (${playlist.id}) `;
    },
    formatPage(page) {
      return page.name ? `${page.name.fr} (${page.id})` : `${page.id}`;
    },
    formatLeaflet(leaflet) {
      if (leaflet.comment !== undefined) {
        return leaflet.title;
      }
      if (leaflet.sheetlist) {
        return this.formatSheetlist(leaflet);
      }
      if (leaflet.type === 'rdbk_playlist') {
        return this.formatPlaylist(leaflet);
      }
      return this.formatPage(leaflet);
    },
    roadbookVisitor() {
       const rawMetadata : Metadata = useRoadbookStore().metadata;
      return {
        accommodation: rawMetadata.trip?.accomodation,
        country: rawMetadata.visitor?.country,
        department: rawMetadata.visitor?.department,
        contacts: rawMetadata.contact,
        trip_duration: rawMetadata.trip?.duration_slug,
      };
    },
  },
});
