<template lang="pug">
#zipCode-field
  input#zipCode-field-input(
    autoComplete="off"
    :value="value"
    @focus="inputHandler($event.target.value, debounce)"
    @blur="close"
    @input="inputHandler($event.target.value, debounce)"
    :placeholder="props?.placeholder"
    :style="{ borderRadius: showOptions ? '12px 12px 0 0' : '' }"
  )
  div#zipCode-options(v-show="showOptions")
    #zipCode-field-scrollbar
      ul
        li(v-for="zipCode in zipCodes" @click="selectZipCode(zipCode)" :key="zipCode")
          span {{ zipCode.zip_code }} ({{ zipCode.name }})
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { Ref } from 'vue';
import { useRoadbookStore } from '@/store';
import apiEsService from '@/services/api/esService';
import { onMounted } from 'vue';

// General
const props = defineProps({
  placeholder: {
    type: String,
    default: null,
  },
  debounce: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['updateVisibility']);

// Data
const value: Ref<string> = ref(useRoadbookStore().metadata.visitor.zip_code);
const isShowOptions: Ref<boolean> = ref(false);
const start: Ref<number> = ref(0);
const size: Ref<number> = ref(10);
const search: Ref<string> = ref('');
const zipCodes: Ref<Array<Record<string, any>>> = ref([]);
const timeout = ref();

// computed
const country = computed(() => useRoadbookStore().metadata.visitor.country || 'FR');
const showOptions = computed(() => isShowOptions.value);

onMounted(async () => {
  if (useRoadbookStore().metadata.visitor.zip_code) {
    const res = await searchValue(useRoadbookStore().metadata.visitor.zip_code);
    isShowOptions.value = false;
    value.value = res[0].zip_code + ` (${res[0].name})`;
  }
});

// watch
watch(country, async (val: string) => {
  if (val !== 'FR') {
    value.value = '';
    useRoadbookStore().updateMetadata({
      category: 'visitor',
      field: 'zip_code',
      value: '',
    });
    useRoadbookStore().updateMetadata({
      category: 'visitor',
      field: 'department',
      value: '',
    });
  }
  const res = await searchValue(useRoadbookStore().metadata.visitor.zip_code);
  isShowOptions.value = false;
  emit('updateVisibility', res.length);
});

// methods
const formatCountryCode = (country_code: string) => {
  return country_code.slice(0, 2).toLowerCase();
};
function close() {
  setTimeout(() => (isShowOptions.value = false), 300);
}
async function searchValue(val: string) {
  search.value = val.charAt(0).toUpperCase() + val.slice(1);
  zipCodes.value = [];
  return await apiEsService
    .searchZipCode({
      start: start.value,
      size: size.value,
      search: search.value,
      country_code: formatCountryCode(country.value),
    })
    .then((res) => {
      zipCodes.value = res;
      isShowOptions.value = true;
      return res;
    });
}
const selectZipCode = (zipCode) => {
  isShowOptions.value = false;
  value.value = zipCode.zip_code + ` (${zipCode.name})`;
  useRoadbookStore().setRoadbookModifiedDate();
  useRoadbookStore().updateMetadata({
    category: 'visitor',
    field: 'zip_code',
    value: zipCode.zip_code ? zipCode.zip_code : '',
  });
  if (useRoadbookStore().metadata.visitor.country == 'FRA') {
    useRoadbookStore().updateMetadata({
      category: 'visitor',
      field: 'department',
      value: zipCode.zip_code ? zipCode.zip_code.slice(0, 2) : '',
    });
  }
};

function inputHandler(val: string, wait: boolean) {
  if (wait) {
    if (timeout.value) clearTimeout(timeout.value);
    timeout.value = setTimeout(() => {
      value.value = val;
      searchValue(val);
    }, 500);
  } else {
    value.value = val;
    searchValue(val);
  }
}
</script>

<style lang="postcss">
#zipCode-field {
  @apply flex flex-col items-center mt-[16px] relative pl-[8px];
  #zipCode-field-input {
    @apply flex-[1] w-[360px];
  }

  #zipCode-options {
    @apply w-[366px] ml-[8px] absolute top-[36px] max-h-[300px] overflow-y-scroll overflow-hidden rounded-b-large;
    z-index: 15;
    #zipCode-field-scrollbar {
      @apply w-[360px];
      animation: fadeIn 500ms;
      ul {
        @apply bg-white-grey flex flex-col items-start;
        li {
          @apply h-[32px] flex items-center px-[8px] cursor-pointer;
          &:hover {
            @apply text-primary;
          }
        }
      }
    }
  }
}
</style>
