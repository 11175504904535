<template lang="pug">
teleport(to="#app")
  transition(name='fade' v-if="!checkOnDevice()[0]")
        #alert-container(v-if="show")
            #alert-wrapper(:class="{'flex': isFlex}")
                perfect-scrollbar(v-if="!loading")
                    slot( name="header")
                        #alert-header
                            #alert-icon(v-if="iconName" :class="[{'large': iconLarge}, {'no-stroke': !iconStroke}]")
                                rc-svg(:name="iconName")
                            #title(v-if="titleText") {{ titleText }}
                    #content
                        slot( name="component")
                          component(:is="usedComponent")
                        slot( name="desc")
                          .description(:style="{'max-width': maxWidthDesc}" v-html="description")
                    slot( name="footer")
                        #footer
                            slot( name="confirm-btn")
                              RdbkButton#confirm-btn(:mode="footerConfirmButtonDisableMutable ? 'disabled' : 'important'" :label="footerConfirmButtonText" :icon-name="'next'" @is-clicked="confirm")
                            slot( name="cancel-btn")
                                #cancel-btn(@click="cancel") {{ footerCancelButtonText }}
                .quit-loading(v-else v-rc-loader="{loading}")
  transition(name='fade' v-else)
    #alert-container-mobile(v-if="show" :class="{'abandon': isAbandon}")
      #alert-wrapper(:class="{'flex': isFlex}")
        slot( name="header")
          #alert-header
            #alert-icon(v-if="iconName" :class="[{'large': iconLarge}, {'no-stroke': !iconStroke}]")
              rc-svg(:name="iconName")
            #title(v-if="titleText") {{ titleText }}
        #content
          slot( name="component")
            component(:is="usedComponent")
          slot( name="desc")
            .description(:style="{'max-width': maxWidthDesc}" v-html="description")
        slot( name="footer")
          #footer
            slot( name="confirm-btn")
              RdbkButton#confirm-btn(:mode="footerConfirmButtonDisableMutable ? 'disabled' : (isAbandon ? 'outline-abandon' : 'important')" :label="footerConfirmButtonText" @is-clicked="confirm")
            slot( name="cancel-btn")
              #cancel-btn(@click="cancel") {{ footerCancelButtonText }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RcSvg from '@/components/medias/RcSvg.vue';
import RdbkButton from '@/components/inputs/RdbkButton.vue';
import mixin from '@/utils/mixins/RcMixin.vue';

export default defineComponent({
  components: {
    RcSvg,
    RdbkButton,
  },
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    iconName: {
      type: String,
      default: '',
    },
    titleText: {
      type: String,
      default: '',
    },
    usedComponent: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    footerConfirmButtonText: {
      type: String,
      default: 'Oui',
    },
    footerCancelButtonText: {
      type: String,
      default: 'Non',
    },
    isFlex: {
      type: Boolean,
      default: false,
    },
    maxWidthDesc: {
      type: String,
      default: '',
    },
    iconStroke: {
      type: Boolean,
      default: false,
    },
    iconLarge: {
      type: Boolean,
      default: true,
    },
    footerConfirmButtonDisable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isAbandon: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [mixin],
  emits: ['cancel', 'confirm'],
  data() {
    return {
      footerConfirmButtonDisableMutable: this.footerConfirmButtonDisable,
    };
  },
  watch: {
    footerConfirmButtonDisable(): void {
      this.footerConfirmButtonDisableMutable = this.footerConfirmButtonDisable;
    },
  },
  methods: {
    confirm(): void {
      this.$emit('confirm');
    },
    cancel(): void {
      this.$emit('cancel');
    },
  },
});
</script>

<style lang="postcss">
#alert-container {
  @apply absolute top-0 w-full h-full z-[15] flex flex-col backdrop-blur-[5px] bg-real-black/50;

  #alert-wrapper {
    @apply relative m-auto p-standard rounded-normal bg-white flex;
    max-height: calc(100% - 80px);
    max-width: calc(100% - 320px);
    &.flex {
      @apply min-w-[70%] flex-1;
      #content {
        @apply min-h-[400px];
      }
    }
    .ps {
      @apply flex-1 flex flex-col mt-standard p-standard;
      height: calc(100% - 20px);
      .ps__rail-x {
        @apply hidden;
      }
      .ps__rail-y {
        @apply my-standard;
      }
    }
    #close {
      @apply absolute right-[20px] top-[20px] z-[5];
      @media (hover: hover) {
        &:hover {
          @apply transition-[transform] duration-300 ease-in-out;
          transform: rotate(90deg);
        }
      }
      svg {
        @apply stroke-white stroke-[1.5px] h-[20px];
      }
    }
    #alert-header {
      @apply flex flex-col;

      #alert-icon {
        @apply mt-0 mx-auto mb-standard;

        &.no-stroke {
          @apply stroke-white;
        }
        svg {
          @apply w-[50px] h-[50px] fill-black opacity-10;
        }
        &.large {
          svg {
            @apply w-[110px] h-[110px];
          }
        }
      }
      #title {
        @apply text-center font-semibold text-[30px] text-primary mx-[20px] my-0 tracking-normal;
      }
    }
    #content {
      @apply flex-1 flex justify-center items-center;

      .description {
        @apply text-center font-light text-[21px] tracking-normal text-grey p-[40px];
      }
    }
    #footer {
      @apply flex flex-col justify-center items-center;

      #cancel-btn {
        @apply text-[15px] mx-auto my-standard cursor-pointer;
        @apply transition duration-[400ms] ease-in-out;

        &:hover {
          @apply text-primary;
        }
      }
    }
  }
  .quit-loading {
    @apply h-[480px] w-[700px];
  }

  @media screen and (max-height: 600px) {
    .quit-loading {
      @apply h-[50vh] w-[50vw];
    }
  }
}
#alert-container-mobile {
  ::-webkit-scrollbar {
    display: none;
  }
  @apply absolute top-0 w-full h-full z-[15] flex flex-col backdrop-blur-[5px] bg-real-black/50;

  #alert-wrapper {
    @apply relative m-auto p-standard rounded-normal bg-white flex flex-col overflow-scroll;
    max-height: calc(100% - 80px);
    max-width: calc(100% - 40px);
    &.flex {
      @apply min-w-[70%] flex-1;
      #content {
        @apply min-h-[400px];
      }
    }
    .ps {
      @apply flex-1 flex flex-col mt-standard p-standard;
      height: calc(100% - 20px);
      .ps__rail-x {
        @apply hidden;
      }
      .ps__rail-y {
        @apply my-standard;
      }
    }
    #close {
      @apply absolute right-[20px] top-[20px] z-[5];
      @media (hover: hover) {
        &:hover {
          @apply transition-[transform] duration-300 ease-in-out;
          transform: rotate(90deg);
        }
      }
      svg {
        @apply stroke-white stroke-[1.5px] h-[20px];
      }
    }
    #alert-header {
      @apply flex flex-col;

      #alert-icon {
        @apply mt-0 mx-auto mb-standard;

        &.no-stroke {
          @apply stroke-white;
        }
        svg {
          @apply w-[50px] h-[50px] fill-black opacity-10;
        }
        &.large {
          svg {
            @apply w-[110px] h-[110px];
          }
        }
      }
      #title {
        @apply text-center font-semibold text-[30px] text-primary mx-[20px] my-0 tracking-normal;
      }
    }
    #content {
      @apply flex-1 flex justify-center items-center;

      .description {
        @apply text-center font-light text-[21px] tracking-normal text-grey p-[40px];
      }
    }
    #footer {
      @apply flex flex-col justify-center items-center;

      #cancel-btn {
        @apply text-[15px] mx-auto my-standard cursor-pointer;
        @apply transition duration-[400ms] ease-in-out;

        &:hover {
          @apply text-primary;
        }
      }
    }
  }
}
.abandon {
  #alert-wrapper {
    #alert-header {
      #alert-icon {
        .svg-icon {
          @apply !fill-red-500 !opacity-100 border-solid border-[2.5px] border-red-500 rounded-full p-2;

          path {
            @apply !fill-red-500;
          }
          #Ellipse_360 {
            @apply !stroke-red-500;
          }
        }
      }
      #title {
        @apply !text-red-500;
      }
    }
    #content {
      .description {
        @apply text-red-500;
      }
    }
  }
}
</style>
