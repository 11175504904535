import { defineStore } from 'pinia';
import api from '@/services/api/playlistService';
import { Facet } from '@/models/interfaces/Facet.interface';
import { Commune } from '@/models/interfaces/Commune.interface';
import { Leaflet } from '@/models/interfaces/Leaflet.interface';

export const usePlaylistStore = defineStore({
  id: 'playlist',
  state: () => ({
    selected: {} as Leaflet,
    datePresets: [] as Array<{ from?: number; to?: number }>,
    communesPresets: [] as Array<string>,
    facets: {} as Array<Facet>,
  }),
  persist: {
    storage: window.sessionStorage,
  },
  actions: {
    setSelected(playlist: Leaflet) {
      this.selected = playlist;
    },
    removeDatePreset(index: number) {
      this.datePresets.splice(index, 1);
    },
    reorderDatePreset({ fromIndex, toIndex }) {
      const preset = { ...this.datePresets[0] };
      this.datePresets.splice(fromIndex, 1);
      this.datePresets.splice(toIndex, 0, preset);
    },
    emptyDatePresets() {
      this.datePresets = [];
    },
    addCommunesPreset(preset: Array<Commune>) {
      this.communesPresets = preset;
    },
    removeCommunesPreset(index: number) {
      this.communesPresets.splice(index, 1);
    },
    emptyCommunesPresets() {
      this.communesPresets = [];
    },
    setFacet({ facetId, facet }) {
      this.facets[facetId] = facet;
    },
    addDatePreset(date: Facet) {
      if (
        !this.datePresets.find((it: { from?: number; to?: number }) => {
          return it.from === date.start && it.to === date.end;
        })
      ) {
        const preset = { from: date.start, to: date.end };
        this.datePresets = [preset, ...this.datePresets];
      }
    },
    async getFacet(facetId: number) {
      const facet = await api.getFacet(facetId);
      this.setFacet({ facetId, facet });
    },
  },
});
