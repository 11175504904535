import client from './apiConnector';
import pro from './proConnector';
import { useErrorHandlerStore } from '@/store/errorHandler';

export default {
  async updateMail(sheet_id: number, email: string) {
    try {
      const mail = await pro().post(`/account`,{sheet_id,email});
      return mail.data;
    } catch (e: any) {
      useErrorHandlerStore().throwError({
        title: 'get_licences_failure',
        detail: 'error.get_licences',
        originalError: e.response,
      });
      console.log(`failed to set mail for ${sheet_id}`);
      return {};
    }
  },
  async invitationTipy(sheet_id: string, email: string, discriminant: number, capacity: number, rc_offer_month: number, destination_offer_month: number, discount_fix: number, discount_variable: number, email_copy: string | null, invite_pro: boolean, no_iban: boolean) {
    try {
      const mail = await client().post(`/tipy/licenses/invitation`,{sheet_id, email,discriminant, capacity, rc_offer_month, destination_offer_month, discount_fix, discount_variable, email_copy, invite_pro, no_iban});
      return mail.data;
    } catch (e: any) {
      useErrorHandlerStore().throwError({
        title: 'get_licences_failure',
        detail: 'error.get_licences',
        originalError: e.response,
      });
      return false;
    }
  },
  async getContext(sheet_id: number): Promise<any> {
    try {
      const res = await client().get(`/licenses/context/sheet/${sheet_id}`);
      return res;
    } catch (e: any) {
      console.error(`API Error Get Context: ${e}`);
      return e.response;
    }
  },
  async getDiscriminant(): Promise<Array<Record<string,any>>> {
    try {
      const response = await client().get(`/tipy/discriminant-prices`);
      return response.data;
    } catch (e: any) {
      console.error(`API Error Get discrimminant prices tipy: ${e}`);
      return [];
    }
  },
};
