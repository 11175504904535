<template lang="pug">
#region-field(:class="label")
  .main-regions
    p.main-region.select-box.click(
      v-for="(region, index) in Object.entries(mainRegions)"
      :key="index"
      :class="{'selected': selectedRegion == region[0]}"
      @click="selectRegion({key: region[0], value: mainRegions[region[0]] })"
    ) {{ mainRegions[region[0]] }}
  v-select(
    :class="label"
    :options="regions"
    :model-value="regions.find(it => it.key === selectedRegion) || null"
    :placeholder="placeholder"
    @update:model-value="val => selectRegion(val)"
  )
    template(#selected-option="option")
      span {{ option.label }}
    template(#option="option")
      span {{ option.label }} {{ label == "canton" ? '(' + option.key + ')' : '' }}
    template(#no-options)
      span {{ $t('no_results') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useConfigStore, useRoadbookStore } from '@/store';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: 'canton',
    },
    placeholder: {
      type: String,
      default: 'Your canton',
    },
  },
  computed: {
    regions(): any {
      // : {AG: "Aargau", AI: "Appenzell Innerrhoden", AR: "Appenzell Ausserrhoden"...}
      if (this.label == 'canton') {
        return (
          Object.entries(useConfigStore().swissCantons).map((it) => {
            return { key: it[0], label: it[1] };
          }) || []
        );
      } else if (this.label == 'provinceCan') {
        return (
          useConfigStore().canRegions.values.map((it) => {
            return { key: it.value, label: it.label.default };
          }) || []
        );
      } else if (this.label == 'espRegions') {
        return (
          useConfigStore().espRegions.values.map((it) => {
            return { key: it.value, label: it.label.default };
          }) || []
        );
      } else {
        return (
          useConfigStore().belgRegions.values.map((it) => {
            return { key: it.value, label: it.label.default };
          }) || []
        );
      }
    },
    mainRegions(): { GE?: string; VD?: string; VS?: string } {
      return useConfigStore().mainCantons && this.label == 'canton'
        ? useConfigStore().mainCantons
        : {};
    },
    selectedRegion(): string {
      return useRoadbookStore().metadata.visitor.region || '';
    },
  },
  methods: {
    selectRegion(value: { key: string }): void {
      useRoadbookStore().setRoadbookModifiedDate();
      useRoadbookStore().updateMetadata({
        category: 'visitor',
        field: 'region',
        value: value ? value.key : '',
      });
    },
  },
});
</script>

<style lang="postcss">
#region-field {
  @apply flex-[0] flex flex-row flex-wrap;

  .main-regions {
    @apply flex flex-row;
  }

  .canton {
    @apply ml-standard;
  }

  .remove-icon {
    height: 14px;
    path {
      stroke: none !important;
      fill: white;
    }
  }
}
.v-select {
  @apply v-select-layer;

  &.vs--open {
    .vs__selected {
      @apply top-[6px] z-10 flex absolute;
    }
  }
}
@media screen and (max-width: 890px) {
  #region-field {
    .canton {
      @apply ml-[8px];
    }
  }
}
</style>
