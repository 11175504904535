<template lang="pug">
#logout
  #logout-btn.click(@click="open")
    span {{ $t('logout', needTranslate) }}
  rc-alert(
    :show="isOpen" :title-text="$t('logout-title', needTranslate)" :icon-name="'off'"
    :description="$t('logout_prompt', needTranslate) + ' </br><b>' + $t('logout_prompt_span', needTranslate) + '</b>'"
    :footer-confirm-button-text="$t('validate', needTranslate)" @confirm="logout" @cancel="close"
    :footer-cancel-button-text="$t('cancel', needTranslate)" :icon-large="false" :icon-stroke="true")
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserStore } from '@/store/user';
import RcAlert from '@/components/feedbacks/RcAlert.vue';

export default defineComponent({
  components: {
    RcAlert,
  },
  data() {
    return {
      isOpen: false as boolean,
    };
  },
  methods: {
    open(): void {
      this.isOpen = true;
    },
    close(): void {
      this.isOpen = false;
    },
    async logout(): Promise<void> {
      useUserStore().logout();
    },
  },
});
</script>

<style lang="postcss">
.warning-wrapper {
  @apply flex flex-col;
}

#logout-btn {
  @apply text-[11px] flex items-center flex-col justify-center w-full font-medium text-error-color;

  span {
    @apply w-[80%] whitespace-nowrap overflow-hidden text-ellipsis font-light;
  }

  i {
    @apply text-[25px] border-solid border-[1px] border-error-color rounded-[99px] h-[26px] w-[26px] flex items-center justify-center mb-[5px];
  }
}

.logout-wrapper {
  @apply flex flex-col justify-center items-center;

  .warning {
    @apply text-center text-[35px] text-primary mx-[24px] my-[16px] font-bold;
  }

  .prompt {
    @apply text-center text-[21px];

    span {
      @apply clear-both inline-block text-center w-full font-medium;
    }
  }

  .off {
    @apply mt-[50px] h-[50px] w-auto text-center fill-light-grey;
  }

  #cancel-logout-btn {
    @apply text-[15px] cursor-pointer mt-standard mx-auto mb-[50px];
    @apply transition duration-[400ms] ease-in-out;

    &:hover {
      @apply text-primary;
    }
  }
}
</style>
