<template lang="pug">
div.date-picker(:disabled="disabled")
  div.days
    div.date-picker-header
      span {{ monthName }} {{ year }}
    table(cellpadding="0" cellspacing="0")
      thead
        tr
          th(v-for="(day, index) in weekdays" :key="index") {{day[0].toUpperCase() + day.substr(1,2)}}
      tbody
        tr(v-for="(week, index2) in weeks" :key="index2")
          td(v-for="(day, index3) in week.days" :key="index3")
            div.day(:class="{out: isOut(day), hovering: isHovering(day), selected: isSelected(day), inrange: inRange(day), today: isToday(day), from: isFrom(day), to: isTo(day)}" @click="select(day)" @mouseenter="setHovering(day)")
              .marker {{ day.format('DD') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import moment, { Moment } from 'moment';
import mixin from '@/utils/mixins/RcMixin.vue';
import RcCalendarMixin from '@/utils/mixins/RcCalendar.mixin.vue';
import { useCalendarStore } from '@/store/calendar';

export default defineComponent({
  mixins: [mixin, RcCalendarMixin],
  props: {
    value: {
      type: Object,
      default: () => moment(),
    },
    minYear: {
      type: Number,
      default: 1969,
    },
    maxYear: {
      type: Number,
      default: moment().year() + 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    second: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Object,
      default: () => {
        return;
      },
    },
    hovering: {
      type: Object,
      default: () => {
        return;
      },
    },
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
  },
  emits: ['input', 'hover'],
  computed: {
    selecting(): boolean {
      return this.value.from && !this.value.to;
    },
    _moment(): Moment {
      return this.$moment(`01/${this.month + 1}/${this.year}`, 'DD/MM/YYYY');
    },
    monthName(): string {
      return this.capitalize(this.$moment.localeData().months()[this.month]);
    },
    weekdays(): Moment {
      return this.$moment.localeData().weekdays(true);
    },
    months(): Moment {
      return this.$moment
        .localeData()
        .months()
        .map((m: number, i: number) => ({ value: i, text: m }));
    },
    years(): Array<{ value: number; text: number }> {
      const years: Array<{ value: number; text: number }> = [];
      for (let i = this.minYear; i <= this.maxYear; i++) {
        years.push({ value: i, text: i });
      }
      return years;
    },
    weeks(): Array<{ weekNumber?: number; days?: Array<Moment> }> {
      const weeks: Array<{ weekNumber?: number; days?: Array<Moment> }> = [];
      const day = this._moment.clone().startOf('week');
      const end = this._moment.clone().endOf('month').endOf('week');
      while (end.diff(day, 'days') >= 0 || weeks.length < 7) {
        let week = weeks.find((x) => (x as any).weekNumber == day.week());
        if (!week) {
          week = { weekNumber: day.week(), days: [] };
          weeks.push(week);
        }
        week.days?.push(day.clone());
        day.add(1, 'day');
      }
      return weeks;
    },
  },
  methods: {
    setHovering(day: Moment): void {
      this.$emit('hover', day);
    },
    select(day: Moment): void {
      const newValue = this.value;
      if (!this.value.from && !this.value.to) {
        newValue.from = day;
      } else if (this.value.from && this.value.to) {
        newValue.from = day;
        newValue.to = null;
      } else {
        if (day < newValue.from) {
          newValue.to = newValue.from;
        } else {
          newValue.to = day;
        }
      }
      this.$emit('input', newValue);
    },
    isHovering(day: Moment): boolean {
      return this.selecting && this.hovering && this.value.from < day && day <= this.hovering;
    },
    isOut(day: Moment): boolean {
      return day.month() != this.month;
    },
    isSelected(day: Moment): Moment {
      return (
        this.$moment(this.value.to).isSame(day, 'day') ||
        (this.$moment(this.value.from).isSame(day, 'day') &&
          !(this.selecting && this.hovering > day))
      );
    },
    inRange(day: Moment): Moment {
      return (
        this.range &&
        this.range.from &&
        this.range.to &&
        day.isAfter(this.range.from) &&
        day.isBefore(this.range.to)
      );
    },
    isFrom(day: Moment): Moment {
      return (
        (this.range && this.range.from && day.isSame(this.range.from, 'day')) ||
        (day.isSame(this.range.to, 'day') && !this.range.from) ||
        (day.isSame(this.range.from, 'day') && this.selecting && this.hovering > day)
      );
    },
    isTo(day: Moment): Moment {
      return (
        ((this.range && this.range.to && day.isSame(this.range.to, 'day')) ||
          (day.isSame(this.range.from, 'day') && !this.range.to) ||
          (this.selecting && this.hovering.isSame(day) && day.isAfter(this.range.from))) &&
        !(day.isSame(this.range.from, 'day') && this.selecting && this.hovering > day)
      );
    },
    isToday(day: Moment): Moment {
      return this.$moment().isSame(day, 'day');
    },
    updateMonth(): void {
      if (this.second) {
        useCalendarStore().setToMonth(this.month);
      } else {
        useCalendarStore().setFromMonth(this.month);
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.date-picker {
  @apply overflow-hidden;
  user-select: none;

  .days {
    @apply flex flex-col max-w-[256px];
  }

  &[disabled='true'] {
    cursor: not-allowed !important;

    * {
      @apply pointer-events-none;
    }
  }

  .date-picker-header {
    @apply text-primary pt-standard pr-standard pb-0 pl-standard text-center font-bold text-[19px];
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    @apply m-0 p-0 border-none text-[100%] align-middle;
    font: inherit;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  table {
    thead tr th {
      @apply px-[5px] py-[11px] pb-standard text-[13px] text-primary;
    }

    tbody tr td .day {
      @apply text-[13px] flex justify-center items-center cursor-pointer text-grey;
      user-select: none;

      .marker {
        @apply p-[10px] h-[35px] w-[35px] flex justify-center items-center;
      }

      &:hover {
        @apply bg-white;
      }

      &.out {
        @apply hidden;
      }

      &.inrange {
        @apply bg-white-grey text-primary font-medium;
        box-shadow: inset 0px 0px 0px 1px transparent;
      }

      &.from {
        &.to {
          @apply bg-transparent border-none;

          .marker {
            @apply border-none;
          }
        }
      }

      &.to:not(.out) {
        @apply flex justify-center items-center;

        .marker:not(.out) {
          @apply text-center p-[10px] bg-white text-primary rounded-[100%] flex justify-center items-center font-medium;
          box-shadow: inset 0px 0px 0px 2px theme('colors.primary');
        }
      }

      &.from:not(.out) {
        @apply flex justify-center items-center;

        .marker:not(.out) {
          @apply text-center p-[10px] bg-primary text-white rounded-[100%] flex justify-center items-center font-medium;
          box-shadow: inset 0px 0px 0px theme('colors.primary');
        }
      }

      &.to {
        background: linear-gradient(90deg, theme('colors.white-grey') 50%, transparent 50%);
      }

      &.from {
        background: linear-gradient(90deg, transparent 50%, theme('colors.white-grey') 50%);
      }

      &.today {
        &:not(.from):not(.to) {
          @apply text-real-black bg-white-grey;
        }
      }

      &.today {
        @apply text-real-black;
        &:not(.inrange):not(.hovering):not(.to):not(.from) {
          @apply bg-white-grey rounded-[100%];
        }
      }

      &.hovering {
        &:not(.selected):not(.to):not(.from):not(.today) {
          @apply bg-white-grey text-primary font-medium;
          box-shadow: inset 0px 0px 0px 1px rgba(66, 135, 245, 0);
        }
      }
    }
  }
}
</style>
