<template lang="pug">
#hour-field
  input.hour-category(type="datetime-local" :value="selectedCategory" @input="event => selectCategory(event.target.value)")
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import mixin from '@/utils/mixins/RcMixin.vue';
import { useRoadbookStore } from '@/store';

export default defineComponent({
  mixins: [mixin],
  computed: {
    selectedCategory(): string {
      if (!useRoadbookStore().metadata.visitor.hour) {
        const now = new Date();
        this.selectCategory(this.toIsoString(now));
      }
      return useRoadbookStore().metadata.visitor.hour;
    },
  },
  methods: {
    selectCategory(category: string): void {
      useRoadbookStore().setRoadbookModifiedDate();
      useRoadbookStore().updateMetadata({
        category: 'visitor',
        field: 'hour',
        value: category,
      });
    },
    toIsoString(date: Date): string {
      const pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
      };

      return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes())
      );
    },
  },
});
</script>

<style lang="postcss" scoped>
#hour-field {
  @apply flex relative overflow-hidden mb-[15px] max-w-full;

  &:after {
    @apply top-0 right-[-5px] absolute w-[9px] bg-white h-full content-[''] blur-[6px];
  }
  &:before {
    @apply top-0 left-[-5px] absolute w-[9px] bg-white h-full content-[''] blur-[6px] z-[1];
  }
  .left-arrow,
  .right-arrow {
    @apply fill-grey h-[25px] w-[10px] min-w-[10px] cursor-pointer;
  }
  .left-arrow {
    @apply mt-[9px] mr-[6px] mb-0 ml-[12px];
  }
  .right-arrow {
    @apply mt-[9px] mr-[12px] mb-0 ml-[6px];
  }
  .category-select {
    @apply flex items-center overflow-x-scroll scroll-smooth flex-wrap;
    .select-box {
      margin-top: 15px;
    }

    &::-webkit-scrollbar {
      @apply hidden;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .category {
      @apply font-light whitespace-nowrap text-[14px] w-auto cursor-pointer bg-transparent transition duration-[400ms] ease-in-out text-grey opacity-50 my-0 mx-[8px] px-[16px] py-[9px];
      @apply border-[2px] border-solid border-grey;

      &.selected {
        @apply opacity-100 text-primary border-[2px] border-solid border-primary;
      }

      @media (hover: hover) {
        &:hover {
          @apply opacity-100 transition duration-[400ms] ease-in-out text-primary border-[2px] border-solid border-primary;
        }
      }
    }
    input {
      @apply h-[40px] mt-[15px] ml-[8px];
    }
  }
  @media screen and (max-width: 840px) {
    .category-select {
      @apply w-[90%];
    }
  }
}
</style>
