import axios from 'axios';

export default (headers = { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' }) =>
  axios.create({
    timeout: 60000,
    headers,
    responseType: 'json',
    baseURL: '/v2/roadbook/pro',
  });

