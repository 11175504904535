<template lang="pug">
div
  #tooltip(role="tooltip")
    div(v-if="type == 'text'") {{content}}
    div(v-if="type == 'textArray'")
      div.row(v-for="row, index in content" :key="index") {{row}}
    div.container(v-if="type == 'img'")
      img(v-for="author, index in content" :src="author" :key="index")
    div(v-if="type == 'HTML'" v-html="content.outerHTML")
    #arrow(data-popper-arrow="")
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { createPopper } from '@popperjs/core';

export default defineComponent({
  components: {},
  props: {
    conditionalDisplay: {
      type: Boolean,
      default: true,
    },
    objectRef: {
      type: HTMLElement,
      default: undefined,
    },
    type: {
      type: String,
      default: '',
    },
    content: {
      type: null as any,
      default: undefined,
    },
    position: {
      type: String,
      default: '',
    },
    altAxis: Boolean,
    offsetX: {
      type: Number,
      default: 5,
    },
    offsetY: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      timer: undefined as number | undefined,
      ref: undefined as HTMLElement | undefined,
    };
  },
  watch: {
    objectRef: {
      handler() {
        this.ref = this.objectRef;
        this.setListeners();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.ref) {
      this.ref = this.$el.parentElement;
      this.setListeners();
    }
  },
  methods: {
    setListeners() {
      if (this.ref) {
        const tooltip = this.$el?.querySelector('#tooltip');
        const popper = createPopper(this.ref, tooltip, {
          placement: this.position ? this.position : 'top',
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'document',
                mainAxis: true,
                altAxis: this.altAxis || false,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [this.offsetX, this.offsetY],
              },
            },
            {
              name: 'arrow',
              options: {
                padding: 8,
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top', 'bottom', 'right', 'left'],
              },
            },
          ],
        });

        this.ref.addEventListener('mouseenter', () => {
          const itemsForTooltip: Array<HTMLElement> = (
            Array.from(this.ref?.children) as Array<HTMLElement>
          ).filter((element: HTMLElement) => element?.className === 'content');
          let hasTooltip: boolean;

          if (itemsForTooltip.length) {
            itemsForTooltip[0].scrollWidth > itemsForTooltip[0].clientWidth
              ? (hasTooltip = true)
              : (hasTooltip = false);
          } else {
            this.ref.scrollWidth >= this.ref.clientWidth
              ? (hasTooltip = true)
              : (hasTooltip = false);
          }
          if (hasTooltip || !this.conditionalDisplay) {
            this.timer = setTimeout(() => {
              tooltip.setAttribute('data-show', '');
              popper.update();
            }, 500);
          }
        });
        this.ref.addEventListener('mouseleave', () => {
          clearTimeout(this.timer);
          setTimeout(() => {
            tooltip.removeAttribute('data-show');
            popper.update();
          }, 500);
        });
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
#tooltip {
  @apply text-[14px] text-white px-[8px] py-[3px] rounded-normal hidden overflow-visible z-[15] font-normal;
  font-family: Poppins;
  background: rgba(95, 95, 95, 0.95);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);

  &[data-show] {
    @apply block;
  }

  #arrow,
  #arrow::before {
    @apply absolute w-[8px] h-[8px] bg-inherit opacity-[95];
    background: rgba(95, 95, 95, 0.95);
  }

  #arrow {
    @apply invisible;
  }

  #arrow::before {
    @apply visible content-[""];
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > #arrow {
    @apply bottom-[-4px];
  }

  &[data-popper-placement^='bottom'] > #arrow {
    @apply top-[-4px];
  }

  &[data-popper-placement^='left'] > #arrow {
    @apply right-[0px] !top-[2px];
  }

  &[data-popper-placement^='right'] > #arrow {
    @apply left-[0px];
  }
  .container {
    @apply flex items-center justify-center;
  }
  .row {
    @apply text-center;
  }
}
</style>
