import client from './apiConnector';
import pro from './proConnector';
import { useLeafletsStore } from '@/store/leaflets';
import { useErrorHandlerStore } from '@/store/errorHandler';
import { Leaflet } from '@/models/interfaces/Leaflet.interface';

export default {
  async getLeaflet(leafletId: string): Promise<Leaflet | null> {
    try {
      const lealfetResponse = await client().get(`/post/${leafletId}`);
      return lealfetResponse.data;
    } catch (e: any) {
      console.error(`API Error Get Leaflet with Id ${leafletId}: ${e}`);
      return null;
    }
  },
  async getLeafletTags(roadbookId: string): Promise<Array<string> | null> {
    try {
      const leafletTags = await client().get(`/roadbook/${roadbookId}/tags`);
      return leafletTags.data;
    } catch (e: any) {
      console.error(`API Error Get roadbook tags with Id ${roadbookId}: ${e}`);
      return null;
    }
  },

  async updateUsage(leafletId: string): Promise<void> {
    try {
      await client().post(`/roadbook/usage/${leafletId}`);
    } catch (e: any) {
      console.log('usage update failure');
    }
  },

  async searchLeaflets(params: {start?: number, size?: number, search?: string | null, filters?: Array<string>, lang?: string, type?: string}): Promise<Array<Leaflet>> {
    try {
      const configResponse = await client().post('/post/search', params);
      return configResponse.data;
    } catch (e: any) {
      console.error(`API Error Search Leaflets: ${e}`);
      useLeafletsStore().setLoading(false);
      useErrorHandlerStore().throwError({
        title: 'get_datas_failure',
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return [];
    }
  },

  async searchSheets(params: {start?: number, size?: number, search?: string | null, lang?: string}): Promise<any> {
    try {
      const configResponse = await client().post('/sheets/search', params);
      return configResponse.data;
    } catch (e: any) {
      console.error(`API Error Search Sheets: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'get_datas_failure',
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return {};
    }
  },

  async searchSheetsPro(params: {start?: number, size?: number, search?: string | null, filters?: Record<string,any>, lang?: string, onboarding?: boolean}): Promise<any> {
    try {
      const configResponse = await pro().post('/sheets/search', params);
      return configResponse.data;
    } catch (e: any) {
      console.error(`API Error Search Sheets: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'get_datas_failure',
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return {};
    }
  },

  async searchZipCode(params: {start?: number, size?: number, search?: string | null, country_code?: string}): Promise<Array<Record<string, any>>> {
    try {
      const configResponse = await client().post('/zip_code/search', params);
      return configResponse.data;
    } catch (e) {
      console.error(`API Error Search ZipCode: ${e}`);
      return [];
    }
  },
};
