<template lang="pug">
#client-info(v-rc-loader="{loading : loading}" :class="{'isInModal': props.isInModal, 'isInOverlay': props.isInOverlay, 'isQualif': qualificationOnly}")
  #client-info-form
      .qualifInOverlay(v-if="props.isInOverlay")
        span.qualifInOverlayText {{ $t('roadbook_edit.qualif') }}
      .client-info-row
        .client-info-field
          .client-info-field-title
            span.main-language {{ $t('client_info.country') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.country',native)"
              )
          rc-country-field(
            :placeholder="$t('client_info.placeholder.country',needTranslate)"
          )
        .client-info-field(
          v-if="customInfosContainsKeys(['region', 'canton'])"
        )
          .client-info-field
            .client-info-field-title
              span.main-language(v-if="customInfosContainsKeys(['region'])") {{ $t('client_info.region') }}
              //- span.main-language(v-else) {{ $t('client_info.canton') }}
            rc-custom-client-info-field(
              v-if="customInfosContainsKeys(['region'])"
              label="region"
              :values="customInfos['region']"
            )
            rc-custom-client-info-field(v-else label="region" :values="customInfos['canton']")
        .client-info-field(
          v-if="roadbookStore.metadata.visitor.country === 'CHE'"
        )
          .client-info-field-title.region
            span.main-language {{ $t('client_info.canton') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.canton', native)"
              )
          rc-region-field(
            label="canton"
            :placeholder="$t('client_info.placeholder.canton', needTranslate)"
          )
        .client-info-field(
          v-else-if="roadbookStore.metadata.visitor.country === 'BEL'"
        )
          .client-info-field-title.region
            span.main-language {{ $t('client_info.province') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.province', native)"
              )
          rc-region-field(
            label="provinceBel"
            :placeholder="$t('client_info.placeholder.province', needTranslate)"
          )
        .client-info-field(
          v-else-if="roadbookStore.metadata.visitor.country === 'CAN'"
        )
          .client-info-field-title.region
            span.main-language {{ $t('client_info.province') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.province', native)"
              )
          rc-region-field(
            label="provinceCan"
            :placeholder="$t('client_info.placeholder.province', needTranslate)"
          )
        .client-info-field(
          v-else-if="roadbookStore.metadata.visitor.country === 'ESP'"
        )
          .client-info-field-title.region
            span.main-language {{ $t('client_info.province') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.province', native)"
              )
          rc-region-field(
            label="espRegions"
            :placeholder="$t('client_info.placeholder.province', needTranslate)"
          )
        .client-info-field(
          v-else-if="roadbookStore.metadata.visitor.country === 'FRA' && configStore.config.main_departments"
        )
          .client-info-field-title
            span.main-language {{ $t('client_info.department') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.department', native)"
              )
          rc-department-field(
            :placeholder="$t('client_info.placeholder.department', needTranslate)"
          )
      .client-info-row(v-show="displayPostCode")
        .client-info-field
          .client-info-field-title
              span.main-language {{ $t('client_info.zip_code') }}
              i.default-language
                rc-translate.default-language(
                  :value="$t('client_info.zip_code', native)"
                )
              RcZipCodeField(
              :placeholder="$t('client_info.placeholder.zip_code', needTranslate)"
              @update-visibility="(v) => displayPostCode = v"
              )
      .client-info-row(v-if="hasRequestType")
        .client-info-field
          .client-info-field-title
            span.main-language {{ $t('client_info.request_type') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.request_type', native)"
              )
          rc-request-type-field
      .client-info-row
        .client-info-field
          .client-info-field-title
            span.main-language {{ $t('client_info.trip_dates') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.trip_dates', native)"
              )
          rc-date-range-picker.date-picker(:use-default-presets="false" :editable="true" :client-info-field="true")
      .client-info-row(v-if="configStore.config.visitor_categories.length > 0")
        .client-info-field
          .client-info-field-title
            .main-language {{$t('client_info.category', needTranslate)}}
          rc-client-category
      .client-info-row(v-if="configStore.config.mobility_categories?.length > 0")
        .client-info-field
            .client-info-field-title
              span.main-language  {{ $t('client_info.mobility', native) }}
            rc-mobility-field
      .client-info-row
        .client-info-field
          .client-info-field-title
            span.main-language {{ $t('client_info.number', native) }}
          rc-number-field
      .client-info-row
        .client-info-field(v-if="configStore.config.accommodation_categories.length > 0")
          .client-info-field-title
            span.main-language {{ $t('client_info.accommodation') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.accommodation', native)"
              )
          rc-accommodation-field
      .client-info-row
        .client-info-field(v-if="configStore.config.trip_durations.length > 0")
          .client-info-field-title
            span.main-language {{ $t('client_info.trip_duration') }}
            i.default-language
              rc-translate.default-language(
                :value="$t('client_info.trip_duration', native)"
              )
          rc-trip-duration-field
      .client-info-row.custom(v-if="isCustomInfos")
        .client-info-field( v-for="info in customInfos" :key="info")
          .client-info-field-title
            span.main-language {{ info.name[language] ? info.name[language] : info.name.default }}
          rc-custom-client-info-field(
            :label="info.slug"
            :entries="info.values"
            :name="info.name[language] ? info.name[language] : info.name.default"
          )
      //- .client-info-row
      //-   .client-info-field
      //-     .client-info-field-title
      //-       span.main-language {{ $t('client_info.weather.title', native) }}
      //-     rc-weather-field
      .client-info-row
        .client-info-field
          .client-info-field-title
            span.main-language {{ $t('client_info.hour', native) }}
            .client-info-row
          rc-hours-field
      .client-info-row
        .client-info-field
          .client-info-field-title
            span.main-language {{ $t('client_info.notes', native) }}
          textarea.client-info-field-notes(v-model="useRoadbookStore().metadata.visitor.notes" :placeholder="$t('client_info.placeholder.notes', needTranslate)")
  #right-bar(v-if="!props.isInOverlay")
    RdbkButton#finish-roadbook-btn(v-if="(hasLeaflets || !qualificationOnly) && !props.isInModal" :mode="'important'" :icon-name="'next'" :label="$t('next',needTranslate)" @is-clicked="finishRoadbook")
    RdbkButton#finish-roadbook-btn(v-if="props.isInModal" :mode="'important'" :label="$t('save',needTranslate)" @is-clicked="closeModal")
    rc-restart-button(v-if="(!hasLeaflets && qualificationOnly) && !props.isInModal", :text="abandonText", :delete="false" :no-confirm="qualificationOnly" @loading="isLoading")
      template(#openButton)
        RdbkButton(:mode="'important'"  :label="$t('save', needTranslate)" :icon-name="'next'")
  #inOverlaySaveButton(v-if="props.isInOverlay")
    RdbkButton.saveButton(:mode="'important'"  :label="$t('save', needTranslate)" @is-clicked="finishRoadbook")
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import type { Ref } from 'vue';
import {
  RcAccommodationField,
  RcMobilityField,
  RcHoursField,
  // RcWeatherField,
  RcNumberField,
  RcCountryField,
  RcCustomClientInfoField,
  RcDepartmentField,
  RcRegionField,
  RcRequestTypeField,
  RcTripDurationField,
  RcZipCodeField,
} from './components';
import RcRestartButton from '@/components/inputs/RcRestartButton.vue';
import RcDateRangePicker from '@/components/inputs/pickers/calendar/RcDateRangePicker.vue';
import { RcClientCategory } from '@/views/RoadBOOK/RoadbookEdit/components';
import RcTranslate from '@/components/data_displays/RcTranslate.vue';
import RdbkButton from '@/components/inputs/RdbkButton.vue';
import { useConfigStore, useRoadbookStore, usePlaylistStore, useTagManagerStore } from '@/store/';
import type { Entity, Feed, Category, Leaflet } from '@/models/interfaces';
import { useRouter } from 'vue-router';
import { useMixin } from '@/utils/mixins/useMixin.mixin';
// import menton_custom_infos from '@/assets/mocks/menton_custom_infos_mock.json';

// General
const roadbookStore = useRoadbookStore();
const configStore = useConfigStore();

// Mock for menton custom infos :
// const mentonCustomInfos = menton_custom_infos;
// configStore.updateCustomClientInfos(mentonCustomInfos);
const router = useRouter();
const emit = defineEmits(['closeModal', 'closeQualif']);
const props = defineProps({
  isInModal: {
    type: Boolean,
    default: false,
  },
  isInOverlay: {
    type: Boolean,
    default: false,
  },
});
const { native, needTranslate, language } = useMixin();
const $t: any = inject('$t');
const loading: Ref<boolean> = ref(false);

// Data
const abandonText: Ref<{ cancel_btn?: string; confirm_btn?: string }> = ref({});
const displayPostCode = ref(true);
// Computed
const qualificationOnly = computed(() => {
  const all: Array<Leaflet | Feed> = [];
  const entities = useRoadbookStore().entities;
  if (entities) {
    for (let i = 0; i < entities.length; i += 1) {
      const currentEntity: Entity = entities[i];
      if (currentEntity.feeds) {
        for (let y = 0; y < currentEntity.feeds.length; y += 1) {
          const currentFeed: Feed = currentEntity.feeds[y];
          if (currentEntity.feeds && currentFeed) {
            all.push(currentFeed);
            if (currentFeed.objects) {
              for (let z = 0; z < currentFeed.objects.length; z += 1) {
                all.push(currentFeed.objects[z]);
              }
            }
          }
        }
      }
    }
  }
  return all.length === 1;
});

const customInfos = computed(() => {
  return configStore.config.custom_client_infos ? configStore.config.custom_client_infos : null;
});

const isCustomInfos = computed(() => configStore.config.custom_client_infos !== undefined);

const requestTypes = new Set([
  'demande',
  'demandes',
  'type-de-demande',
  'types-de-demandes',
  'types-de-demande',
  'type-de-demandes',
  'type_de_demande',
  'types_de_demandes',
  'types_de_demande',
  'type_de_demandes',
]);

const hasRequestType = computed(() => {
  if (configStore.config.leaflet_categories) {
    const filter: Category =
      configStore.config.leaflet_categories.find(
        (it: Category) => it.slug && requestTypes.has(it.slug),
      ) || {};
    return filter && filter.children ? true : false;
  }
  return false;
});

// Hooks
onMounted(() => {
  abandonText.value = {
    confirm_btn: $t('create_new_roadbook', needTranslate),
    cancel_btn: $t('back_to_qualification', needTranslate),
  };

  const presetsFromEntities = roadbookStore.entities;
  if (presetsFromEntities) {
    presetsFromEntities.forEach((entity: Entity) =>
      entity.feeds?.forEach((feed: Feed) => {
        feed.objects?.forEach((item: Leaflet) => {
          if (item?.facets) {
            Object.entries(item?.facets).forEach((facet) => {
              if (facet[1].start && facet[1].end) {
                usePlaylistStore().addDatePreset(facet[1]);
              }
            });
          }
        });
      }),
    );
  }

  const roadbookPeriod = roadbookStore.metadata?.trip?.period;
  if (roadbookPeriod && Object.keys(roadbookPeriod).length) {
    usePlaylistStore().addDatePreset({ start: roadbookPeriod.from, end: roadbookPeriod.to } as any);
  }
});

// Methods
function isLoading(isLoading: boolean): void {
  loading.value = isLoading;
}

function closeModal(): void {
  emit('closeModal');
}

function customInfosContainsKeys(searchKeys: Array<string>): boolean {
  // check if the custom infos contain the keys
  let containsKey = false;

  searchKeys.forEach((key: string) => {
    if (Object.prototype.hasOwnProperty.call(customInfos, key)) {
      containsKey = true;
    }
  });

  return containsKey;
}

async function finishRoadbook(): Promise<void> {
  roadbookStore.updateMetadata({
    category: 'finished',
    field: null,
    value: true,
  });
  let contact = '';
  let optin: boolean | null = null;
  switch (roadbookStore.metadata.contact.length) {
    case 2:
      contact = 'Mail & Phone';
      optin = roadbookStore.metadata.contact[0].is_optin || false;
      break;
    // case 1:
    //   optin = roadbookStore.metadata.contact[0].is_optin || false;
    //   switch (roadbookStore.metadata.contact[0].type) {
    //     case 'email':
    //       contact = 'Phone';
    //       break;
    //     case 'phone':
    //       contact = 'Mail';
    //       break;
    //     default:
    //   }
    //  break;
    default:
  }
  useTagManagerStore().sendEvent({
    event: 'roadbook_final_validation',
    tripDuration: roadbookStore.metadata.trip.duration_slug,
    accommodation: roadbookStore.metadata.trip.accommodation,
    region: roadbookStore.metadata.visitor.region,
    department: roadbookStore.metadata.visitor.department,
    contact,
    optin,
  });
  props.isInOverlay ? router.push('/languages') : router.push('/preview');
  emit('closeQualif');
}
</script>

<style lang="postcss">
#inOverlaySaveButton {
  @apply w-full pt-3 border-t-[1px] border-solid border-light-grey;
  .saveButton {
    @apply w-full h-[50px] text-[18px];
  }
}
#client-info {
  @apply flex justify-between flex-col border-t-[5px] border-solid border-primary;
  &.isQualif {
    @apply border-t-[5px] border-solid border-purple;
  }
  &.isInModal {
    @apply h-[calc(100%-190px)];
  }
  &.isInOverlay {
    @apply w-full h-full border-none;
    #client-info-form {
      @apply p-0 m-0 w-full;
      .client-info-row {
        @apply max-w-full mt-0;
        .client-info-field {
          @apply mt-0 mr-0 mb-0 ml-0 w-full;
          .client-info-field-title {
            @apply pt-4;
            .main-language {
              @apply text-[16px];
            }
          }
          #country-field {
            @apply w-full m-0;
            #countrySelect {
              @apply w-full;
            }
          }
          #accommodation-field {
            @apply w-full m-0 pb-4 justify-center;
          }
          #department-field {
            @apply w-full;
            #client-department-select {
              @apply w-full ml-0 flex-none;
              .vs__dropdown-toggle {
                @apply w-full max-w-full;
              }
            }
          }
          #region-field {
            @apply w-full;
          }
        }
        .vs__dropdown-toggle {
          @apply w-full max-w-full;
        }
      }
    }
    .v-select {
      @apply w-full;
    }
    .vs__dropdown-menu {
      @apply w-full max-w-full;
    }
    #zipCode-field {
      @apply p-0;
      #zipCode-field-input {
        @apply w-full max-w-full;
      }
      #zipCode-options {
        @apply w-full max-w-full m-0;
      }
    }
    .custom-presets {
      @apply m-0;
    }
  }
  #client-info-form {
    @apply my-0 mx-[24px] bg-white overflow-y-auto py-0 pr-0 pl-[16px];

    .qualifInOverlay {
      @apply w-full flex justify-center items-center border-b-[1px] border-solid border-light-grey pb-6;
      .qualifInOverlayText {
        @apply text-[18px] text-primary;
      }
    }
    .ps {
      @apply pt-0 px-0 pb-[81px];
    }

    .client-info-row {
      @apply flex flex-wrap mt-[24px];

      .vs__dropdown-toggle {
        @apply w-[360px];
      }

      &:first-of-type {
        @apply mt-standard;
      }

      .client-info-field {
        @apply mt-0 mr-[48px] mb-[16px] ml-[16px];

        .date-picker {
          @apply ml-[8px];
        }

        .client-info-field-title {
          @apply mb-[16px];

          .main-language {
            @apply text-primary text-[26px];
          }
          .default-language {
            @apply font-light text-middle-grey text-[18px];
          }
        }

        .client-info-field-notes {
          @apply min-h-[200px] rounded-lg w-[500px] resize-none mt-[15px] p-[15px] text-[16px] text-black bg-white-grey;
          font-family: 'Poppins', sans-serif;
        }
      }
      &.custom {
        @apply mb-[126px] mt-[45px];
      }
    }
  }
  #right-bar {
    @apply flex flex-col box-border pt-0 px-standard pb-standard absolute z-[1] h-fit bottom-0 right-[40px] justify-end;

    #finish-roadbook-btn {
      @apply mt-0 mx-auto mb-[8px] py-[10px] px-[50px];
    }
  }

  input,
  select {
    @apply text-input text-[14px] text-real-black;
  }
  .select-box {
    @apply px-[8px] py-[4px] mx-[7px] rounded-small text-grey transition duration-300 cursor-pointer border-[1px] border-solid border-grey flex items-center;
    @media screen and (max-width: 950px) {
      @apply text-[12px];
    }
    &.selected {
      @apply text-white bg-primary border-grey;
    }
    @media (hover: hover) {
      &:hover:not(.selected) {
        @apply text-white bg-primary;
      }
    }
  }

  @keyframes rotateIcon {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }

  @keyframes arrowslide {
    to {
      transform: translateX(10px);
    }
  }
  @-moz-keyframes finisharrowslide {
    from {
      transform: translateX(10px);
    }
    to {
      transform: translateX(0px);
    }
  }
  @-webkit-keyframes finisharrowslide {
    from {
      transform: translateX(10px);
    }
    to {
      transform: translateX(0px);
    }
  }

  @keyframes finisharrowslide {
    from {
      transform: translateX(10px);
    }
    to {
      transform: translateX(0px);
    }
  }
}
</style>
