import elloha from '@/assets/apis/elloha.png';
import booking from '@/assets/apis/Booking.png';
import cmt from '@/assets/apis/cmt.png';
import ctoutvert from '@/assets/apis/ctoutvert.png';
import ingenie from '@/assets/apis/ingenie.png';
import msem from '@/assets/apis/MSEM.svg';
import opensystem from '@/assets/apis/opensystem.png';
import regiondo from '@/assets/apis/regiondo.png';
import ressourcessi from '@/assets/apis/ressourcesSi.png';
import stardekk from '@/assets/apis/stardekk.png';
import tourismSystem from '@/assets/apis/tourismSystem.png';

export function createLoader(this: any, el, binding) {
    //Instanciate "waiting for loader"
    const longLoad = el.querySelector(':scope > .isPending > .long-load-content');
  
    if (longLoad) {
      if (binding.value.loading) {
        if (
          (el.getAttribute('id') == 'results-preview-wrapper' ||
            el.classList.contains('sheet-scroll-loading')) &&
          !!window.engines &&
          window.engines?.length
        ) {
          longLoad.classList.remove('disabled');
          el.querySelectorAll(':scope > .isPending > .long-load-content img').forEach(
            (element, index) => {
              if (window.engines && window.window.engines[index]) {
                if (window.engines[index].indexOf('elloha') > -1) {
                  element.src = elloha;
                } else if (window.engines[index].indexOf('booking') > -1) {
                  element.src = booking;
                } else if (window.engines[index].indexOf('cmt') > -1) {
                  element.src = cmt;
                } else if (window.engines[index].indexOf('ctoutvert') > -1) {
                  element.src = ctoutvert;
                } else if (
                  window.engines[index].indexOf('ingenie') > -1 ||
                  window.engines[index].indexOf('ingénie') > -1
                ) {
                  element.src = ingenie;
                } else if (window.engines[index].indexOf('msem') > -1) {
                  element.src = msem;
                } else if (window.engines[index].indexOf('opensystem') > -1) {
                  element.src = opensystem;
                } else if (window.engines[index].indexOf('regiondo') > -1) {
                  element.src = regiondo;
                } else if (window.engines[index].indexOf('ressourcessi') > -1) {
                  element.src = ressourcessi;
                } else if (window.engines[index].indexOf('stardekk') > -1) {
                  element.src = stardekk;
                } else if (
                  window.engines[index].indexOf('tourismsystem') > -1 ||
                  window.engines[index].indexOf('toursimsystem') > -1
                ) {
                  element.src = tourismSystem;
                } else {
                  longLoad.classList.add('disabled');
                }
              } else {
                longLoad.classList.add('disabled');
              }
            },
          );
        } else {
          longLoad.classList.add('disabled');
        }
      } else {
        longLoad.classList.remove('active');
        clearTimeout(this.$timeoutLongLoading);
      }
    }
  }