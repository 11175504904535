import { Moment } from 'moment';
import { defineStore } from 'pinia';

export const useCalendarStore = defineStore({
  id: 'calendar',
  state: () => ({
    opened: false as boolean,
    position: { top: 0, left: 0 } as {top: number, left: number},
    fromMonth: 0 as number,
    toMonth: 0 as number,
    currentValue: null as {from?: Moment | null, to?: Moment | null} | null,
    datesAsFilter: false as boolean,
    datesEditing: false as boolean,
    isInQualifModal: false as boolean,
    lastActiveIndex: "" as string,
    lastActiveFixedPreset: "" as string,
  }),
  actions: {
    setFromMonth(value: number) {
      this.fromMonth = value;
    },
    setToMonth(value: number) {
      this.toMonth = value;
    },
    setCurrentValue(value: {from?: Moment | null, to?: Moment | null}) {
      this.currentValue = value;
    },
    setIsInQualifModal(value: boolean) {
      this.isInQualifModal = value;
    },
    toggleOpened(value: boolean) {
      this.opened = value;
    },
    setCalendarPosition(value: {top: number, left: number}) {
      this.position = value;
    },
    setDatesAsFilter(value: boolean) {
      this.datesAsFilter = value;
    },
    setLastActiveIndex(value: string) {
      this.lastActiveIndex = value;
    },
    setLastActiveFixedPreset(value: string) {
      this.lastActiveFixedPreset = value;
    },
    setDatesEditing(value: boolean) {
      this.datesEditing = value;
    },
    setPosition(value: {top: number, left: number}) {
      this.setCalendarPosition(value);
    },
  },
});
