<template lang="pug">
rc-modal(
    :centered-title="true" :icon-name="'tel'"
    :title-text="$t('roadbook_edit.contact_title', needTranslate)"
    :width="'1400px'" :show="isOpen"
    :footer-confirm-button-text="$t('save', native)"
    @confirm="saveContact" @cancel="closeModal"
    :footer-confirm-button-disable="!contactsAreValid"
    :footer-cancel-button-text="$t('cancel' , needTranslate)"
    @close="closeModal")
        template(#content)
            rc-contact(@close-modal="closeModal" :contacts="contactList" @update-contact-list="newList => updateContactList(newList)")
</template>

<script lang="ts">
import { useRoadbookStore } from '@/store';
import mixin from '@/utils/mixins/RcMixin.vue';
import { defineComponent } from 'vue';
import { Contact } from '@/models/interfaces';
import RcContact from '@/components/RcContact.vue';
import RcModal from '@/components/navigations/RcModal.vue';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { isEmailValid } from '@/utils/functions/utils';

export default defineComponent({
  components: {
    RcContact,
    RcModal,
  },
  mixins: [mixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      contactList:
        useRoadbookStore().metadata.contact.length > 0
          ? useRoadbookStore().metadata.contact
          : [{ email: null, phone: null, is_optin: false }],
      contactsAreValid: false as boolean,
    };
  },
  computed: {
    idRoadbook(): string | null {
      return useRoadbookStore().id;
    },
    defaultContact(): object {
      return { email: null, phone: null, is_optin: false };
    },
  },
  watch: {
    idRoadbook() {
      if (this.idRoadbook === null || useRoadbookStore().metadata.contact.length === 0) {
        this.contactList = [this.defaultContact];
      } else {
        this.contactList = useRoadbookStore().metadata.contact;
      }
    },
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
      this.contactList =
        useRoadbookStore().metadata.contact.length > 0
          ? useRoadbookStore().metadata.contact
          : [this.defaultContact];
    },
    updateContactList(newList: {
      list: Array<Contact>;
      prefixes: Array<string>;
      isGood: boolean;
    }): void {
      this.contactsAreValid = newList.isGood;
      this.contactList = [...newList.list];
    },
    async saveContact(): Promise<void> {
      const contactListReturn = [...this.contactList];
      const contactList = [] as Array<Contact>;
      if (contactListReturn.length === 0) {
        await useRoadbookStore().updateContactList([]);
      } else {
        this.contactList.forEach((contact) => {
          const isExistingContact = contact.phone
            ? contactList.find((c) => c.phone === contact.phone)
            : contactList.find((c) => c.email === contact.email);

          if (contact.email) {
            const value = contact.email;
            value && isEmailValid(value) && !isExistingContact ? contactList.push(contact) : null;
          } else {
            if (contact.phone) {
              const phoneNumber = parsePhoneNumberFromString(contact.phone);
              phoneNumber && phoneNumber.isValid() && !isExistingContact
                ? contactList.push(contact)
                : null;
            }
          }
        });
      }
      this.$nextTick(async () => {
        await useRoadbookStore().updateContactList(contactList);
        this.closeModal();
        if (contactList.length === 0) {
          this.contactList = [this.defaultContact];
        } else {
          this.contactList = [...contactList];
        }
      });
    },
  },
});
</script>
