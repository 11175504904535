import client from './apiConnector';
import { useErrorHandlerStore } from '@/store/errorHandler';
import { useUserStore } from '@/store/user';
import { User } from '@/models/interfaces/User.interface';
import defaultImage from '@/assets/avatar-default-man.svg';
import connectService from './connectService';

export default {
  async connect(user: User | string, isPro?: boolean): Promise<boolean> {
    const proUrls = [
      'http://localhost:3006',
      'https://pro.roadbook.rc-preprod.com',
      'https://pro.roadbook.travel',
    ];
    if (!isPro) isPro = proUrls.includes(window.location.origin);

    try {
      const response = await client().get('/autoconnect', {
        params: {
          login: typeof user === 'string' ? user : user.login,
          version: useUserStore().version,
          isPro,
        },
      });
      window.sessionStorage.clear();
      window.location.href = response.data.autologin_url;
      return true;
    } catch (e: any) {
      console.error(`API Error Connect User: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'connect_failure',
        detail: `log_error.${e.response.data.error}`,
      });
      return false;
    }
  },
  async connectRbPro(email: string): Promise<User | boolean> {
    try {
      const proToken = await client().get(`/pro/token?&email=${email}`);
      return proToken.data;
    } catch (e: any) {
      console.error(`API Error Connect User: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'connect_failure',
        detail: `log_error.${e.response.data.error}`,
      });
      return false;
    }
  },

  async getCurrent(): Promise<User> {
    try {
      const userResponse = await client().get('/user');
      const user = userResponse.data;
      if (!user.has_image) {
        user.image_url = defaultImage;
      } else if (!user.image_url) {
        user.image_url = `/v2/roadbook/user/${user.contact_id}/image`;
      }
      return user;
    } catch (e: any) {
      console.error(`API Error Get Current User: ${e}`);
      return e.response;
    }
  },
  async getUsers(): Promise<Array<User>> {
    try {
      const usersResponse = await client().get('/user/list');
      return usersResponse.data.map((it: User) => {
        if (!it.has_image) {
          it.image_url = defaultImage.toString();
        } else if (!it.image_url) {
          it.image_url = `/v2/roadbook/user/${it.contact_id}/image`;
        }
        return it;
      });
    } catch (e: any) {
      console.error(`API Error Get Users: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'get_datas_failure',
        detail: `log_error.${e.response.data.error}`,
      });
      return [];
    }
  },
  async createUser(
    firstname: string,
    lastname: string,
    image: string,
    otherLanguages: Array<string>,
    login: string,
    loginLanguage: string,
    civility: string,
  ): Promise<boolean> {
    try {
      await client().post('/user', {
        firstname: firstname,
        lastname: lastname,
        image: image,
        partner_another_language_codes: otherLanguages,
        user_login: login,
        original_language_code: loginLanguage,
        civility: civility,
      });
      return true;
    } catch (e: any) {
      const detail =
        e.response.status === 409
          ? 'log_error.LoginNotFreeException'
          : `log_error.${e.response.data.error}`;
      console.error(`API Error Create User: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'error.create_user',
        detail: detail,
        originalError: e.response,
      });
      return false;
    }
  },
  async updateUser(
    firstname: string,
    lastname: string,
    image: string,
    otherLanguages: Array<string>,
    login: string,
    loginLanguage: string,
    contactId: number,
    civility: string,
  ): Promise<boolean> {
    try {
      await client().put('/user', {
        firstname: firstname,
        lastname: lastname,
        image: image,
        partner_another_language_codes: otherLanguages,
        user_login: login,
        original_language_code: loginLanguage,
        contact_id: contactId,
        civility: civility,
      });
      return true;
    } catch (e: any) {
      console.error(`API Error Update User ${contactId}: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'error.create_user',
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return false;
    }
  },
  async deleteRoadBookRole(contactId: number): Promise<boolean> {
    try {
      await client()
        .delete(`/user/role/roadbook?contactId=${contactId}`)
        .then(() => {
          useUserStore().getUsers();
          useUserStore().getUsersWithoutPassword();
          useUserStore().getUsersWithoutRoadBookRole();
        });
      return true;
    } catch (e: any) {
      console.error(`API Error Delete Roadbook Role of ${contactId}: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'get_datas_failure',
        detail: `log_error.${e.response.data.error}`,
      });
      return false;
    }
  },
  async getUsersWithoutRoadBookRole(): Promise<Array<User>> {
    try {
      const usersResponse = await client().get('/user/list/without_rb_role');
      return usersResponse.data.map((it: User) => {
        if (!it.has_image) {
          it.image_url = defaultImage.toString();
        } else if (!it.image_url) {
          it.image_url = `/v2/roadbook/user/${it.contact_id}/image`;
        }
        return it;
      });
    } catch (e: any) {
      console.error(`API Error Get Users Without RoadBook Role: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'get_datas_failure',
        detail: `log_error.${e.response.data.error}`,
      });
      return [];
    }
  },
  async getUsersWithoutPassword(): Promise<Array<User>> {
    try {
      const usersResponse = await client().get('/user/list/without_password');
      return usersResponse.data.map((it: User) => {
        if (!it.has_image) {
          it.image_url = defaultImage.toString();
        } else if (!it.image_url) {
          it.image_url = `/v2/roadbook/user/${it.contact_id}/image`;
        }
        return it;
      });
    } catch (e: any) {
      console.error(`API Error Get Users Without Password : ${e}`);
      useErrorHandlerStore().throwError({
        title: 'get_datas_failure',
        detail: `log_error.${e.response.data.error}`,
      });
      return [];
    }
  },
  async logout(): Promise<void> {
    try {
      navigator?.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((v) => {
          v.unregister();
        });
      });
      // Clears service worker caches.
      // caches.keys().then((cs) => cs.forEach((c) => caches.delete(c)));
      await client().get('/logout');
      console.warn('Disconnected from Roadbook');
      document.cookie = 'SESSIONID=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
      window.sessionStorage.clear();
      const logoutUrl = connectService.getLogoutUrl();
      window.location.assign(logoutUrl);
    } catch (e: any) {
      console.error(`API Error Logout: ${e}`);
      useErrorHandlerStore().throwError({
        title: 'deconnect_failure',
        detail: `log_error.${e.response.data.error}`,
      });
    }
  },
  async updateSession(): Promise<any> {
    try {
      const res = await client().get(`/update_session?version=${useUserStore().version}`);
      return res;
    } catch (e: any) {
      console.error(`API Error Update Session: ${e}`);
      return e.response;
    }
  },
};
