<script lang="ts">
import { defineComponent } from 'vue';
import { useCalendarStore } from '@/store/calendar';
import { Moment } from 'moment';

export default defineComponent({
  data() {
    return {
      presets: [
        {
          name: 'today' as string,
          from: () => this.$moment(),
          to: () => this.$moment(),
        },
        {
          name: 'tomorrow' as string,
          to: () => this.$moment().add(1, 'days'),
          from: () => this.$moment().add(1, 'days'),
        },
        {
          name: 'this_week' as string,
          from: () => this.$moment(),
          to: () => this.$moment().add(7, 'days'),
        },
        {
          name: 'this_weekend' as string,
          from: () => this.$moment().day(5),
          to: () => this.$moment().day(7),
        },
      ],
    };
  },
  methods: {
    capitalize(value: Moment): string {
      if (!value) return '';
      const newValue = value.toString();
      return newValue.charAt(0).toUpperCase() + newValue.slice(1);
    },
    // Dates Management (used in RcCalendar - RcDateRangePicker)
    defineCurrentValue(preset: any) {
      if (typeof preset.from === 'string') {
        useCalendarStore().setCurrentValue({
          from: this.$moment(preset.from),
          to: this.$moment(preset.to),
        });
      } else {
        useCalendarStore().setCurrentValue({
          from: preset.from(),
          to: preset.to(),
        });
      }
    },
    defineCurrentValueIndex(array: Array<any>): void {
      const fixedIndex = this.presets.findIndex(
        (preset) =>
          preset.from().date() === this.value.from.date() &&
          preset.to().date() === this.value.to.date() &&
          preset.from().month() === this.value.from.month(),
      );
      if (fixedIndex !== -1) {
        useCalendarStore().setLastActiveFixedPreset(fixedIndex.toString());
        useCalendarStore().setLastActiveIndex('');
      } else {
        const index = array.findIndex(
          (date) =>
            date.from == this.value.from.startOf('day').toISOString(true) &&
            date.to == this.value.to.endOf('day').toISOString(true),
        );
        useCalendarStore().setLastActiveFixedPreset('');
        useCalendarStore().setLastActiveIndex(index.toString());
      }
    },
    isActive(preset: any, value: any): boolean {
      if (preset && preset.from && preset.to) {
        const from = typeof preset.from === 'string' ? this.$moment(preset.from) : preset.from();
        const to = typeof preset.to === 'string' ? this.$moment(preset.to) : preset.to();
        return (
          value &&
          value.from &&
          value.to &&
          value.from.isSame(from, 'day') &&
          value.to.isSame(to, 'day')
        );
      }
      return false;
    },
    filteredPresets(datePresets: Array<any>): any {
      return datePresets.filter((item) => {
        return !this.isFixedPreset(item);
      });
    },
    isFixedPreset(preset: any): boolean | undefined {
      const fixedPresets = this.presets;
      if (preset.from && preset.to) {
        let isFoundedInPreset = false;
        fixedPresets.forEach((fixedPreset) => {
          if (
            this.$moment(preset.from).date() == fixedPreset.from().date() &&
            this.$moment(preset.to).date() == fixedPreset.to().date() &&
            this.$moment(preset.from).month() == fixedPreset.from().month()
          ) {
            isFoundedInPreset = true;
          } else if (
            this.$moment(preset.from).isSame(fixedPreset.from()) &&
            this.$moment(preset.to).isSame(fixedPreset.to())
          ) {
            isFoundedInPreset = true;
          }
        });
        return isFoundedInPreset;
      }
    },
  },
});
</script>
