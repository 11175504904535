import client from './apiConnector';
import { useErrorHandlerStore } from '@/store/errorHandler';

import axios from 'axios';
import { Facet } from '@/models/interfaces/Facet.interface';

export default {
  async getHawwwaiConf(confId: number, filters: Array<any>, page: number, site_key, onlylang = false, lang = 'fr', cancelToken: any = null): Promise<any> {
    if(confId === undefined) {
      console.error("Get hawwwai conf canceled because confId got undefined");
      return false;
    }
    try {
      const confResponse = await client().post(
        `/facets/conf/${confId}`,
        {
          start: page * 20,
          size: 20,
          facets: filters,
          site_key,
          onlylang,
          lang
        },
        {
          cancelToken,
        },
      );
      return confResponse.data;
    } catch (e: any) {
      if (!axios.isCancel(e)) {
        console.error(`API Error Get Hawwwai Conf: ${e}`);
        useErrorHandlerStore().throwError({
          title: 'get_datas_failure',
          detail: `log_error.${e.response.data.error}`,
          originalError: e.response,
        });

        return {};
      } else {
        console.warn(`API Error Get Hawwwai Conf: ${e} (canceled)`);
        return 'axios_cancelled';
      }
    }
  },

  async getFacet(facetId: number): Promise<Facet | undefined> {
    try {
      const facetResponse = await client().get(`/facets/definition/${facetId}`);
      return facetResponse.data;
    } catch (e: any) {
      console.error(`API Error Get Facet with Id ${facetId}: ${e}`);
      return e.response;
    }
  },
};
