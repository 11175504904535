<template lang="pug">
#current-user(v-if="user")
  transition(name="fade")
    #current-user-top-block(v-if="!channelListIsOpen")
      transition-group(name="fade")
        .navigation-btns(
            :class="route.name"
            :key="'navigation-btns'"
          )
          rc-restart-button(
            v-if="route.name && ['roadbook_edit', 'client_info', 'preview'].includes(route.name) && !isFinished"
            :text="$t('abandon', native)"
          )
          rc-restart-button(
            v-else-if="route.name && ['roadbook_edit', 'client_info', 'preview'].includes(route.name)"
            :text="$t('abandon_confirm', native)"
          )
          rc-logout-button(
            v-else-if="route.name === 'init'"
          )
          #previous-route-btn.click(
            v-if="['preview', 'advisors', 'licences', 'languages', 'webhook','channel','report', 'licences-pro', 'qr-code', 'roadbook_select'].includes(route.name) || (['client_info'].includes(route.name) && !isQualif)",
            @click="changeRoute"
          )
            .arrow-reverse
              rc-svg(name="left").arrow-left
            .back {{ $t('back', native)}}
        .separator(v-if="['client_info', 'preview'].includes(route.name) || (['roadbook_edit', 'client_info', 'preview'].includes(route.name))")
        #current-user-language(v-if="language && !['advisors', 'licences', 'webhook', 'offices', 'languages', 'report', 'licences-pro', 'qr-code'].includes(route.name)")
          transition(name="slide-fade-reverse")
            .language-logo-list(v-if="languageListIsOpen")
              .language-logo.click(:key="lang" v-for="lang in languages.filter(e => e != language)" @click="selectLanguage(lang)")
                img.language-flag.round.click(
                  :src="getLangFlagUrl(lang)",
                  :lang="language"
                )
          .language-logo.click(@click="languageListIsOpen = !languageListIsOpen")
            img.language-flag.round.click(
              :src="getLangFlagUrl(language)",
              :lang="language"
            )
        .separator(v-if="language && !['advisors', 'licences', 'webhook', 'offices', 'languages', 'report', 'licences-pro', 'qr-code'].includes(route.name)" :key="'separator-language'")
        #current-user-qualif.channel-item.click(v-if="['roadbook_edit', 'preview'].includes(route.name) && !isPro()" @click="toggleShowQualif" :key="'current-user-qualif'")
          rc-svg(
            name="profil"
          )
          p {{$t('roadbook_edit.qualif', native)}}
        .separator(v-if="['roadbook_edit', 'preview'].includes(route.name)")
        #current-user-contact.channel-item.click(v-if="['roadbook_edit', 'client_info', 'preview'].includes(route.name) && !isQualif" @click="openContact" :key="'current-user-contact'")
          rc-svg(
            name="tel"
          )
          p {{$t('roadbook_edit.contact', needTranslate)}}
        .separator(v-if="['roadbook_edit', 'client_info', 'preview'].includes(route.name) && !isQualif" :key="'separator-contact'")
  transition(name="fade")
    #current-user-channel(v-if="channel_categories && selectedChannel && !['channel', 'offices'].includes(route.name)" class="flex flex-col overflow-y-auto")
      transition(name="slide-fade")
        .channel-logo-list(v-if="channelListIsOpen")
          .channel-logo.click(:key="channel_category" v-for="channel_category in channel_categories.filter(channel => channel.slug != selectedChannel)" @click="selectChannel(channel_category.slug)")
            rc-svg(
              :name="getLogoUrl(channel_category)"
              :class="channel_category.slug"
              )
            p {{ channel_category.name[language] || channel_category.name[native] || channel_category.name['fr']}}
      .channel-logo.click(@click="channelListIsOpen = !channelListIsOpen")
        rc-svg(
          :name="getLogoUrl(channel_categories.find(channel => channel.slug == selectedChannel))"
          )
        p {{ channel_categories.find(channel => channel.slug == selectedChannel).name[language] || channel_categories.find(channel => channel.slug == selectedChannel).name[native] || channel_categories.find(channel => channel.slug == selectedChannel).name['fr'] }}
  transition(name="fade")
    .separator(v-if="office && (Object.keys(configStore.office).length != 0) && !['offices', 'init'].includes(route.name)")
  transition(name="fade")
    #current-user-office.channel-item.click(v-if="office && (Object.keys(configStore.office).length != 0) && !['offices', 'init'].includes(route.name)" @click="goToOffice")
      rc-svg(name="siege")
      p {{office.name}}
      rc-poppers.poppers(:type="'text'" :content="office.name")
  .separator(v-if="!['offices', 'init'].includes(route.name)")
  #current-user-preferences.channel-item(@click="toggleNav")
    rc-svg(
      name="parametres"
      :class="{ 'turn': navIsOpen === true }"
    )
  .separator
  .advisor(@click="goToAdvisors")
    img#current-user-avatar.round(:src="user.image_url")
    rc-poppers.poppers(:type="'text'" :content="`${user.first_name} ${user.last_name}`")
  #current-user-nav(:class="{'isOpen' : navIsOpen}" v-outside="() => closeNav()")
    .pic
    .current-user-nav__item(@click="goToAdvisors" :class="{'disabled': route.name === 'advisors'}") {{ $t('toogle.user','fr') }}
      rc-svg(name="right").svg-item
    .current-user-nav__item(v-if="availableLicences && !userIsAPro" @click="goToLicences" :class="{'disabled': route.name === 'licences'}") {{ $t('toogle.licenses', native) }}
      rc-svg(name="right").svg-item
    .current-user-nav__item(v-if="availableWebhooks" @click="goToWebHooks" :class="{'disabled': route.name === 'webhook'}") {{ $t('toogle.webhook', native) }}
      rc-svg(name="right").svg-item
    .current-user-nav__item(@click="goToQRCode" :class="{'disabled': route.name === 'qr-code'}") {{ $t('qr-code.title', native) }}
      rc-svg(name="right").svg-item
    .current-user-nav__item(@click="goToReport" :class="{'disabled': route.name === 'report'}") {{ $t('report.title', native) }}
      rc-svg(name="right").svg-item
    .current-user-nav__item(v-if="availableStats && !userIsAPro" @click="goToStatistics") {{ $t('toogle.statistics', native) }}
      rc-svg(name="right").svg-item
    // .current-user-nav__item(v-if="isRaccourci && reportUrl == null" @click="goToLookerCreator") {{ $t('toogle.looker', native) }}
    //   rc-svg(name="right").svg-item
    #roadbookPro.current-user-nav__item(v-if="(isRaccourci || isIleDeRe) && !isPro()" @click="goToLicencesPro" :class="{'disabled': route.name === 'licences-pro'}") {{ $t('toogle.licensesPro', native) }}
      rc-svg(name="right").svg-item
    .current-user-nav__item(@click="disconnectIsOpen") {{ $t('logout', native) }}
      rc-svg(name="right").svg-item
  rc-alert(
    :show="isOpen" :title-text="$t('logout-title', native)" :icon-name="'off'"
    :description="$t('logout_prompt', native) + ' </br><b>' + $t('logout_prompt_span', native) + '</b>'"
    :footer-confirm-button-text="$t('validate', native)" @confirm="logout" @cancel="close"
    :footer-cancel-button-text="$t('cancel', native)"  :icon-large="false" :icon-stroke="true")
  rc-alert(
    :show="reconnectOpened" :title-text="$t('logout-title', native)" :icon-name="'unlock'"
    :description="$t('user_reconnect.desc', native) + ' </br>' + $t('user_reconnect.desc_span', native)"
    :footer-confirm-button-text="$t('user_reconnect.connect', native)" @confirm="goToStudio" @cancel="reconnectOpened = false"
    :footer-cancel-button-text="$t('user_reconnect.abort', native)"  :icon-large="false" :icon-stroke="true")
  rc-alert(
    :show="unsavedChanges" :title-text="$t('licences.unsaved.title')" :max-width-desc="'800px'" :icon-name="'exclamation'"
    :description="$t('licences.unsaved.desc') + ' ' + $t('licences.unsaved.desc2')"
    @confirm="quitWithoutSaving" :footer-confirm-button-text="$t('licences.unsaved.quit')")
    template(#cancel-btn)
      .btn.quit(@click="unsavedChanges = false") {{ $t('licences.unsaved.stay') }}
  rc-modal(
    :centered-title="true"
    :icon-name="'profil'"
    :title-text="$t('roadbook_edit.qualif', needTranslate)"
    :show="showQualif"
    :detect-click-outside="!calendarIsOpen"
    @close="toggleShowQualif" :is-flex="true")
      template(#content)
        ClientInfo(:is-in-modal="true" @close-modal="toggleShowQualif")
      template(#footer)
        span
  RcContactModal(v-if="showContact" :is-open="true" @close="closeContact")

</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount, inject } from 'vue';
import type { Ref } from 'vue';
import RcSvg from '@/components/medias/RcSvg.vue';
import RcRestartButton from '@/components/inputs/RcRestartButton.vue';
import RcLogoutButton from '@/components/inputs/RcLogoutButton.vue';
import RcAlert from '@/components/feedbacks/RcAlert.vue';
import RcPoppers from '@/components/data_displays/RcPoppers.vue';
import ClientInfo from '@/views/RoadBOOK/ClientInfo/ClientInfo.vue';
import RcModal from '@/components/navigations/RcModal.vue';
// import reportService from '@/services/api/reportService';
import {
  useTagManagerStore,
  useCalendarStore,
  useLicencesStore,
  useLicencesProStore,
  useRoadbookStore,
  useConfigStore,
  useUserStore,
} from '@/store';
import RcContactModal from '@/components/medias/RcContactModal.vue';
import { useRoute, useRouter } from 'vue-router';
import { useMixin } from '@/utils/mixins/useMixin.mixin';
import { isPro, roadbookUrl } from '@/utils/functions/utils';

//General
const props = defineProps({
  navOpen: {
    type: Boolean,
  },
});
const configStore = useConfigStore();
const router = useRouter();
const route = useRoute();
const { getLogoUrl, language, needTranslate } = useMixin();
const $t: any = inject('$t');

//Data
const isOpen: Ref<boolean> = ref(false);
const navIsOpen: Ref<boolean> = ref(props.navOpen);
const navIsInit: Ref<boolean> = ref(true);
const reconnectOpened: Ref<boolean> = ref(false);
const channelListIsOpen: Ref<boolean> = ref(false);
const languageListIsOpen: Ref<boolean> = ref(false);
const showQualif: Ref<boolean> = ref(false);
const showContact: Ref<boolean> = ref(false);
const unsavedChanges: Ref<boolean> = ref(false);
const reportUrl: Ref<string | null> = ref(null);

//Computed
const user = computed(() => useUserStore().user);
const office = computed(() => configStore.office);
const availableLicences = computed(() => useUserStore().licencesAccess);
const isRaccourci = computed(() => useUserStore().user.is_raccourci || false);
const isIleDeRe = computed(() => useUserStore().user.company_id == 16052); // Need that to force ile de ré to access internal admin features of roadbook pro onboarding
const availableWebhooks = computed(() => useUserStore().webhooksAccess);
const availableStats = computed(() => useUserStore().hasStatisticsAccess);
const userIsAPro = computed(() => useUserStore().user.product_shared || false);
const channel_categories = computed(() => {
  if (isPro()) {
    return [
      { id: 1, slug: 'email', name: { fr: 'Email', default: 'Email' }, icon: '' },
      { id: 2, slug: 'guichet', name: { fr: 'Guichet', default: 'Guichet' }, icon: '' },
      { id: 3, slug: 'telephone', name: { fr: 'Téléphone', default: 'Téléphone' }, icon: '' },
    ];
  }
  return configStore.config.channel_categories || [];
});
const selectedChannel = computed(() => useRoadbookStore().currentChannel);
const isQualif = computed(() => useRoadbookStore().metadata.is_qualif);
const calendarIsOpen = computed(() => useCalendarStore().opened);
const isFinished = computed(() => useRoadbookStore().metadata.is_finished);
const languages = computed(() => {
  return useConfigStore().config.languages || [];
});
const preproductionVersion = computed(() => useUserStore().user.is_preprod);

//Hooks
onBeforeMount(async () => {
  useUserStore().getStatisticsAvailability;
  // reportUrl.value = await reportService.getLookerReport();
});

//Methods
function getLangFlagUrl(code: string): string | URL {
  return code
    ? new URL(`../../assets/flags/${code}.png`, import.meta.url).href
    : new URL(`../../assets/flags/fr.png`, import.meta.url);
}

function goToAdvisors(): void {
  if (route.name !== 'advisors') {
    if (route.name !== 'offices' || !sessionStorage.getItem('opened_advisors_from')) {
      sessionStorage.setItem('opened_advisors_from', route.name as string);
    }
    if (useUserStore().noSessions) {
      useUserStore().noSessionsRoute = 'advisors';
    } else {
      router.push('/advisors');
    }
  }
  closeNav();
}

function goToQRCode(): void {
  if (route.name !== 'qr-code') router.push('/qr-code');
  closeNav();
}

function openContact(): void {
  showContact.value = true;
  useTagManagerStore().sendEvent({
    event: 'roadbook_open_contact',
  });
}

function closeContact(): void {
  showContact.value = false;
}

function toggleShowQualif(): void {
  showQualif.value = !showQualif.value;
  useTagManagerStore().sendEvent({
    event: 'roadbook_open_qualification',
  });
  useCalendarStore().setIsInQualifModal(!useCalendarStore().isInQualifModal);
}

function quitWithoutSaving(): void {
  unsavedChanges.value = false;
  router.push({
    path: window.history.state.back,
    params: {
      no_reload: true,
    },
  } as any);
}

function changeRoute() {
  if (
    route.name === 'advisors' ||
    route.name === 'report' ||
    route.name === 'licences' ||
    route.name === 'webhook' ||
    route.name === 'licences-pro' ||
    route.name === 'preview' ||
    route.name === 'roadbook_select'
  ) {
    return router.back();
  }
  if (isQualif.value && route.name === 'client_info') {
    return router.push({
      name: 'languages',
      params: {
        no_reload: true,
      },
    } as any);
  }

  if (route.name === 'languages') {
    return router.push({
      name: 'offices',
    });
  }

  if (isQualif.value && route.name === 'languages' && useRoadbookStore().id == null) {
    return router.push({
      name: 'offices',
    });
  }

  if (route.name === 'licences') {
    if (useLicencesStore().hasUnsavedChanges) {
      // open modal
      unsavedChanges.value = true;

      return;
    }
  }

  if (route.name === 'licences-pro') {
    if (useLicencesProStore().hasUnsavedChanges) {
      // open modal
      unsavedChanges.value = true;

      return;
    }
  }

  if (route.name === 'channel') {
    sessionStorage.setItem('opened_advisors_from', route.name);
    return router.push({ name: 'offices' });
  }

  if (route.meta.previous === '/edit' && route.name === 'client_info') {
    return router.push({
      name: 'roadbook_edit',
      params: {
        no_reload: true,
      },
    } as any);
  }

  if (route.name == 'qr-code') {
    return !isPro() ? router.push({ name: 'offices' }) : window.history.back();
  }

  if (typeof route.meta.previous == 'undefined') {
    // Es-t-on en train d'éditer un roadBOOK
    if (useRoadbookStore().isEditingRoadbook) {
      return router.push({
        name: 'roadbook_edit',
        params: {
          no_reload: true,
        },
      } as any);
    }

    // As-t-on déja choisi un office ?
    if (Object.keys(configStore.office).length === 0 || !configStore.office.id) {
      return router.push({
        name: 'offices',
      });
    }
  }

  router.push(route.meta.previous as any);
}

function selectChannel(channel_category: string): void {
  useRoadbookStore().updateChannel(channel_category);
  channelListIsOpen.value = false;
}

function selectLanguage(lang: string): void {
  useRoadbookStore().metadata.visitor.language = lang;
  useRoadbookStore().updateUrlRoadbook(lang);
  useRoadbookStore().setWoodyUrl(
    preproductionVersion.value
      ? $t('not_available_feature.desc', needTranslate)
      : roadbookUrl.value,
  );
  languageListIsOpen.value = false;
}

function goToLicences(): void {
  if (useUserStore().hasToDisconnect) {
    reconnectOpened.value = true;
  } else {
    if (useUserStore().noSessions) {
      useUserStore().noSessionsRoute = 'licences';
    } else {
      router.push('/licences');
    }
  }
  closeNav();
}

function goToLicencesPro(): void {
  if (useUserStore().hasToDisconnect) {
    reconnectOpened.value = true;
  } else {
    if (useUserStore().noSessions) {
      useUserStore().noSessionsRoute = 'licences';
    } else {
      router.push('/licences-pro');
    }
  }
  closeNav();
}

function goToWebHooks(): void {
  if (useUserStore().hasToDisconnect) {
    reconnectOpened.value = true;
  } else {
    router.push('/webhook');
  }
  closeNav();
}

function goToReport(): void {
  router.push('/report');
  closeNav();
}

async function logout(): Promise<void> {
  await useUserStore().logout();
}

function close(): void {
  isOpen.value = false;
}

function closeNav(): void {
  if (!navIsInit.value) {
    navIsOpen.value = false;
  }
}

function toggleNav(): void {
  navIsInit.value = true;
  navIsOpen.value = !navIsOpen.value;
  setTimeout(() => (navIsInit.value = false), 500); // Debounce
}

function disconnectIsOpen(): void {
  isOpen.value = !isOpen.value;
  closeNav();
}

async function goToStatistics(): Promise<void> {
  if (useUserStore().hasToDisconnect) {
    reconnectOpened.value = true;
  } else {
    if (reportUrl.value != null) {
      window.open(reportUrl.value, '_blank');
    } else {
      window.open(useUserStore().statisticsEskaladUrl);
    }
  }
  closeNav();
}

// function goToLookerCreator(): void {
//   router.push('looker');
// }

function goToOffice(): void {
  sessionStorage.setItem('opened_advisors_from', route.name as string);
  router.push('offices');
}
</script>

<style lang="postcss">
.channel-item {
  @apply w-full flex items-center justify-center flex-col my-standard mx-0 text-primary;

  svg {
    @apply fill-primary;
  }

  p {
    @apply text-[10px] mt-[15px] font-normal text-center w-[75px] overflow-hidden text-ellipsis mb-0 leading-[1.2];
  }

  @media screen and (max-height: theme('screens.sm-desktop')) {
    p {
      @apply hidden;
    }
  }
}
@media screen and (max-height: theme('screens.md-height')) {
  .channel-item {
    @apply my-[15px] mx-0;

    p {
      @apply mt-[10px];
    }
  }
}

#backdrop {
  @apply fixed top-0 left-0 h-[100vh] w-full bg-transparent z-[1];
  max-height: -webkit-fill-available;
}

#current-user {
  @apply flex flex-col w-full items-center justify-end h-full;

  #current-user-top-block {
    @apply mb-auto flex flex-col items-center;

    .navigation-btns {
      @apply w-full py-standard px-0 rounded-tl-normal box-border flex justify-center items-center flex-col bg-white whitespace-nowrap;
      row-gap: 15px;

      #previous-route-btn {
        @apply flex flex-col items-center justify-center w-full align-middle;

        .arrow-reverse {
          @apply border-[1px] border-solid border-grey;
          @apply rounded-[99px] h-[26px] w-[26px] opacity-80 align-middle flex items-center justify-center;
          @apply outline-[0.5px] outline-transparent;
          @apply transition duration-500 ease-in-out;

          svg {
            width: 6px !important;
            @apply mr-[10px] align-middle box-content;

            path {
              stroke: theme('colors.grey') !important;
              @apply stroke-[4px];
            }
          }
        }
        @apply text-[18px] flex items-center transition duration-300 font-medium;
        .arrow-left {
          @apply text-[45px];
        }

        .back {
          @apply text-[11px] mt-[5px] font-light text-center w-[50px] overflow-hidden text-ellipsis;
        }

        @media screen and (max-height: theme('screens.sm-desktop')) {
          .back {
            @apply hidden;
          }
        }

        @media (hover: hover) {
          &:hover {
            .arrow-reverse {
              @apply outline-[0.5px] outline-grey opacity-100;
            }
          }
        }
      }
    }
  }

  #current-user-office {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  #current-user-language {
    @apply flex flex-col-reverse text-primary items-center w-full;

    .language-logo {
      @apply flex flex-col items-center;
    }

    @media screen and (max-height: theme('screens.md-height')) {
      .language-logo {
        @apply mx-0 my-[15px];

        p {
          @apply mt-[10px];
        }
      }
    }

    .language-logo-list {
      @apply flex flex-col justify-end text-grey pt-standard w-full gap-y-standard;

      .language-logo {
        @apply mb-0 transition-[color] duration-300 ease-in-out;
      }
    }

    @media screen and (max-height: theme('screens.md-height')) {
      .language-logo-list {
        @apply gap-y-[10px];
      }
    }

    @media screen and (max-height: theme('screens.sm-desktop')) {
      .language-logo-list {
        height: calc(100vh - 236px);
      }
    }
  }

  #current-user-language {
    @apply w-full flex items-center justify-center mx-0 my-standard;
    .language-flag {
      @apply transition duration-300;
      @apply h-[26px] w-[26px] border-[2px] border-solid border-transparent;

      @media (hover: hover) {
        &:hover {
          @apply border-primary;
        }
      }
    }
  }

  @media screen and (max-height: theme('screens.md-height')) {
    #current-user-language {
      @apply mx-0 my-[15px];
    }
  }

  #current-user-channel {
    @apply flex flex-col text-primary items-center w-full;

    .channel-logo {
      @apply flex flex-col items-center mb-standard;

      svg {
        @apply fill-primary;
      }

      p {
        @apply text-[11px] mt-[15px] font-normal text-center w-[65px] overflow-hidden text-ellipsis mb-0 leading-[1.2];
      }

      @media screen and (max-height: theme('screens.sm-desktop')) {
        p {
          @apply hidden;
        }
      }
    }

    @media screen and (max-height: theme('screens.md-height')) {
      .channel-logo {
        @apply mx-0 my-[15px];

        p {
          @apply mt-[10px];
        }
      }
    }

    .channel-logo-list {
      @apply bg-white flex flex-col justify-end mb-standard text-grey pt-standard w-full gap-y-standard;

      .channel-logo {
        @apply text-grey mb-0 transition-[color] duration-300 ease-in-out;

        svg {
          @apply h-[21px] fill-grey transition-[fill] duration-300 ease-in-out;
        }

        @media (hover: hover) {
          &:hover {
            @apply text-primary;

            svg {
              @apply fill-primary;
            }
          }
        }
      }
    }

    @media screen and (max-height: theme('screens.md-height')) {
      .channel-logo-list {
        @apply gap-y-[10px];
      }
    }

    @media screen and (max-height: theme('screens.sm-desktop')) {
      .channel-logo-list {
        height: calc(100vh - 236px);
      }
    }
  }

  #current-user-preferences {
    @apply flex items-center flex-col text-primary cursor-pointer mx-0 my-standard;

    &__title {
      @apply font-medium text-[19px] mr-[15px];
    }

    .svg-icon {
      @apply fill-primary duration-500;

      @media (hover: hover) {
        &:hover {
          @apply duration-500;
          transform: rotate(-90deg);
        }

        &.turn {
          @apply duration-500;
          transform: rotate(90deg);
        }
      }
    }
  }

  @media screen and (max-height: theme('screens.md-height')) {
    #current-user-preferences {
      @apply mx-0 my-[15px];
    }
  }

  #current-user-avatar {
    @apply w-[40px] h-[40px] border-[1px] border-solid border-grey cursor-pointer;
  }

  .separator {
    @apply bg-grey/30 w-[40px] h-[1px] min-h-[1px];
  }

  .advisor {
    @apply flex flex-col w-full items-center cursor-pointer mt-standard;

    @media (hover: hover) {
      &:hover {
        #current-user-avatar {
          @apply border-primary border-[2px];
        }
      }
    }
  }

  @media screen and (max-height: theme('screens.md-height')) {
    .advisor {
      @apply mt-[15px];
    }
  }

  #current-user-nav {
    @apply absolute bg-white rounded-normal p-0 hidden flex-col bottom-[87px] left-[88px] w-[250px] duration-500 z-[100];
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    svg.svg-item {
      @apply my-auto mr-standard ml-0 w-[10px];

      path {
        @apply stroke-[2] transition duration-[250ms] ease-in-out;
        stroke: theme('colors.grey') !important;
      }
    }

    &.isOpen {
      @apply flex duration-500;
      animation: fadeIn 300ms ease-in-out;
    }

    .pic {
      @apply bg-white w-[20px] h-[20px] z-10 absolute bottom-[42px] left-[-10.5px];
      transform: rotate(45deg);
    }

    .current-user-nav__item {
      @apply p-[10px] w-full flex justify-between m-0 text-[18px] border-b-[1px] border-solid border-grey cursor-pointer transition duration-[250ms] ease-in-out z-20;

      @media (hover: hover) {
        &:hover {
          @apply transition duration-[400ms] ease-in-out text-primary;

          .svg-icon path {
            @apply transition duration-[400ms] ease-in-out;
            stroke: theme('colors.primary') !important;
          }
        }
      }
      &.disabled {
        @apply pointer-events-none text-primary;
        .svg-icon path {
          stroke: theme('colors.primary') !important;
        }
      }
    }

    .current-user-nav__item:last-child {
      @apply pb-[11px] border-b-0;
    }
  }
}

.logout-wrapper {
  @apply flex flex-col justify-center items-center;

  .warning {
    @apply font-bold text-center text-[35px] text-primary mx-[24px] my-[16px];
  }
  .prompt {
    @apply text-center text-[21px];

    span {
      @apply clear-both font-medium inline-block text-center w-full;
    }
  }

  .off {
    @apply mt-[50px] h-[50px] w-auto text-center fill-light-grey;
  }

  #cancel-logout-btn {
    @apply text-[15px] mx-auto mt-standard mb-[50px] cursor-pointer transition duration-[400ms] ease-in-out;

    &:hover {
      @apply text-primary;
    }
  }
}
</style>
