import { Licenses, LicensesContainer } from '@/models/interfaces/Licence.interface';
import { defineStore } from 'pinia';

export const useLicencesProStore = defineStore({
  id: 'licencesPro',
  state: () => ({
    lastYear: {} as Licenses,
    previousYearData: {} as LicensesContainer,
    currentYearData: {} as LicensesContainer,
    nextYearData: {} as LicensesContainer,
    afterNextYearData: {} as LicensesContainer,
    history: {} as {current?: number, next?: number, afterNextYear?: number},
    hasUnsavedChanges: false as boolean,
  }),
  actions: {
    setlastYear(lastYear: Licenses) {
      this.lastYear = lastYear;
    },
    setpreviousYearData(data: LicensesContainer) {
      this.previousYearData = data;
    },
    setcurrentYearData(data: LicensesContainer) {
      this.currentYearData = data;
    },
    setnextYearData(data: LicensesContainer) {
      this.nextYearData = data;
    },
    setAfterNextYearData(data: LicensesContainer) {
      this.afterNextYearData = data;
    },
    reset() {
      this.lastYear = {};
      this.previousYearData = {};
      this.currentYearData = {};
      this.nextYearData = {};
      this.history = {};
      this.hasUnsavedChanges = false;
    },
    setHistory(params: {key: string, value: string}) {
      this.history[params.key] = params.value;
    },
    setUnsavedChanges(params: boolean) {
      this.hasUnsavedChanges = params;
    },
    setOldState(oldState) {
      this.lastYear = oldState.lastYear;
      this.previousYearData = oldState.previousYearData;
      this.currentYearData = oldState.currentYearData;
      this.nextYearData = oldState.nextYearData;
      this.afterNextYearData = oldState.afterNextYearData;
      this.history = oldState.history;
    },
  },
});
