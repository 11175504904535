<template lang="pug">
.content(v-if="needTranslation && !fromLocale") / {{ translate(valueObject) }}
.content(v-else-if="needTranslation") / {{ value }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import mixin from '@/utils/mixins/RcMixin.vue';
import { useUserStore } from '@/store/user';
import { User } from '@/models/interfaces/User.interface';
import { Traduction } from '@/models/interfaces/Traduction.interface';

export default defineComponent({
  mixins: [mixin],
  props: {
    value: {
      type: String,
      default: '',
    },
    valueObject: {
      type: Object,
      default: () => {
        return;
      },
    },
    fromLocale: {
      //For text not coming from locale file
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // Return if we have to display the translated part
    needTranslation(): boolean {
      // In native language , never translate

      if (this.language == this.native) {
        return false;
      } else if (
        !this.currentUserWithLanguages[0] ||
        !this.currentUserWithLanguages[0].other_language_codes
      ) {
        //Lang not found -> translate
        return true;
      }

      // lang in user languages ?
      return (
        !!this.currentUserWithLanguages[0].other_language_codes &&
        !this.currentUserWithLanguages[0].other_language_codes.includes(this.language)
      );
    },
    currentUserWithLanguages(): Array<User> {
      return useUserStore().users.filter((user) => {
        return user.contact_id == useUserStore().user.contact_id;
      });
    },
  },
  methods: {
    translate(langs: Traduction): string {
      return langs[this.native] ? langs[this.native] : langs['default'];
    },
  },
});
</script>

<style lang="postcss">
.content {
  @apply text-[14px];
}
</style>
