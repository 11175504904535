export function outside(app) {
  // Take function as parameter, call function when user click outside the element
  // Generate bug on modale and submodale -> like with calendar in playlist in modale for example
  app.directive('outside', {
    mounted: function (el, binding) {
      el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          binding.value(event, el)
        }
      }
      document.addEventListener("click", el.clickOutsideEvent)
    },
    unmounted: function (el) {
      document.removeEventListener("click", el.clickOutsideEvent)
    },
  } as any);
}
