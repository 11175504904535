<template lang="pug">
#request-types-field
  .request-type(v-for="category in categories" :key="category.slug")
    label.request-checkbox-wrapper(:for="`request-cb-${category.slug}`") {{ category.name[native] || category.name['fr'] }}
      input.checkbox(:id="`request-cb-${category.slug}`" type="checkbox" @input="e => inputHandler(e.target.checked, category)" :checked="selectedTypes.includes(category.slug)")
      .checkbox-indicator
</template>

<script lang="ts" setup>
  import { computed, onMounted } from 'vue';
  import { useConfigStore, useRoadbookStore, useTagManagerStore } from '@/store';
  import apiEsService from '@/services/api/esService';
  import type { Category } from '@/models/interfaces';

  const requestTypes = new Set([
    'demande',
    'demandes',
    'type-de-demande',
    'types-de-demandes',
    'types-de-demande',
    'type-de-demandes',
    'type_de_demande',
    'types_de_demandes',
    'types_de_demande',
    'type_de_demandes',
  ]);

  // Stores
  const configStore = useConfigStore();
  const roadbookStore = useRoadbookStore();
  const tagManagerStore = useTagManagerStore();

  // Computed properties
  const categories = computed((): Category[] => {
    const filter =
      configStore.config.leaflet_categories?.find(
        (it: Category) => it.slug && requestTypes.has(it.slug),
      ) || {};
    return filter.children || [];
  });

  const selectedTypes = computed((): string[] => {
    return roadbookStore.metadata.trip.type_request || [];
  });

  // Methods
  function inputHandler(checked: boolean, category: Category) {
    const newTypes = new Set(selectedTypes.value);

    if (checked) {
      if (category.slug) newTypes.add(category.slug);
      tagManagerStore.sendEvent({
        event: 'roadbook_empty_qualification',
        category: `${category.name?.fr}`,
      });
    } else {
      if (category.slug) newTypes.delete(category.slug);
    }

    roadbookStore.setRoadbookModifiedDate();
    roadbookStore.updateMetadata({
      category: 'trip',
      field: 'type_request',
      value: Array.from(newTypes),
    });
  }

  // Lifecycle hook
  onMounted(async () => {
    if (roadbookStore.id) {
      const tags = await apiEsService.getLeafletTags(roadbookStore.id as string);

      roadbookStore.updateMetadata({
        category: 'trip',
        field: 'type_request',
        value: tags?.filter((tag) => categories.value.some((it: Category) => it.slug === tag)),
      });

      tags?.forEach((tag) => {
        const category = categories.value.find((it: Category) => it.slug === tag);
        if (category) inputHandler(true, category);
      });
    }
  });
</script>

<style lang="postcss" scoped>
  #request-types-field {
    @apply flex flex-wrap pt-[4px];

    .request-type {
      @apply mr-standard mb-[30px];

      .request-checkbox-wrapper {
        @apply big-checkbox ml-[16px];
      }
    }
  }
</style>
