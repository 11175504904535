<template lang="pug">
teleport(to="#app")
  transition(name='fade')
        #popup-container(v-if="show")
            #popup-wrapper(:class="{'flex': isFlex, 'mobile' : checkOnDevice()[0]}" :style="{'width': width}")
              #close(@click="close")
                rc-svg(name="croix")
              perfect-scrollbar
                  slot( name="header")
                      #popup-header
                        #popup-icon(v-if="iconName" :class="[{'large': iconLarge}, {'no-stroke': !iconStroke}]")
                            rc-svg(:name="iconName")
                        #title(v-if="titleText") {{ titleText }}
                  #content(class="flex flex-col")
                    slot( name="component")
                      component(:is="usedComponent")
                    slot( name="desc")
                      .description(v-if="description" :style="{'max-width': maxWidthDesc}" v-html="description")
                  slot( name="footer")
                      #footer(:class="{'centered-btn': centeredFooter}" )
                        RdbkButton#close-btn(:mode="'important'" @is-clicked="close" :label="footerButtonText")
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RcSvg from '@/components/medias/RcSvg.vue';
import RdbkButton from '@/components/inputs/RdbkButton.vue';
import mixin from '@/utils/mixins/RcMixin.vue';

export default defineComponent({
  components: {
    RcSvg,
    RdbkButton,
  },
  inheritAttrs: false,
  props: {
    width: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    maxWidthDesc: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    titleText: {
      type: String,
      default: '',
    },
    usedComponent: {
      type: String,
      default: '',
    },
    centeredFooter: {
      type: Boolean,
      default: true,
    },
    footerButtonText: {
      type: String,
      default: 'OK',
    },
    isFlex: {
      type: Boolean,
      default: false,
    },
    iconStroke: {
      type: Boolean,
      default: false,
    },
    iconLarge: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  mixins: [mixin],
  methods: {
    close(): void {
      this.$emit('close');
    },
  },
});
</script>

<style lang="postcss">
#popup-container {
  @apply absolute top-0 w-full h-full z-[1000] flex flex-col backdrop-blur-[5px] bg-real-black/50;
  .mobile {
    @apply min-h-[600px] !max-w-[90%];
    .ps {
      @apply !p-0;
      .ps__rail-y {
        @apply hidden;
      }
    }
  }
  #popup-wrapper {
    @apply relative m-auto p-standard rounded-normal bg-white flex;
    max-height: calc(100% - 160px);
    max-width: calc(100% - 160px);

    &.flex {
      @media screen and (min-width: 950px) {
        @apply w-[70%] flex-1;
      }
    }
    .ps {
      @apply flex-1 flex flex-col mt-standard p-standard;
      height: calc(100% - 20px);

      .ps__rail-x {
        @apply hidden;
      }
      .ps__rail-y {
        @apply my-standard;
      }
    }
    #close {
      @apply absolute cursor-pointer right-standard top-standard z-[300];
      @media (hover: hover) {
        &:hover {
          transition: transform 0.3s ease-out;
          transform: rotate(90deg);
        }
      }
      svg {
        @apply stroke-white stroke-[1.5px] h-[20px];
      }
    }
    #popup-header {
      @apply flex flex-col;
      #popup-icon {
        svg {
          @apply w-[50px] h-[50px] fill-black opacity-10 stroke-white;
        }
        @apply mt-0 mx-auto mb-standard;
        &.no-stroke {
          @apply stroke-white;
        }
        &.large {
          svg {
            @apply w-[110px] h-[110px];
          }
        }
      }
      #title {
        @apply text-center font-semibold text-[30px] text-primary mt-0 mb-standard mx-standard tracking-normal;
      }
    }
    #content {
      @apply flex-1 flex justify-center items-center;

      .description {
        @apply text-center font-light text-[21px] tracking-normal text-grey p-[40px] max-w-[600px];
      }
    }
  }
  #footer {
    @apply flex justify-end items-end;
    &.centered-btn {
      @apply justify-center;
    }
    #close-btn {
      @apply mx-0 mt-0 mb-standard;
    }
  }
}
</style>
