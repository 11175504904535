import { useConfigStore, useUserStore } from '@/store';

async function handleLogin(to, next, must) {
    const loggedIn = Object.keys(useUserStore().user).length > 0;
    if (must.beLogged && !loggedIn) {
      const user = await useUserStore().getCurrent();
      await useUserStore().updateSession();
      const notLoggedIn = user.status === 403;
      if (notLoggedIn) {
        return 'login';
      }
    }
    return null;
  }

  function handlePermissions(to, next, must) {
    if (must.havePermission) {
      const hasRole =
      Object.keys(useUserStore().user).length > 0 &&
        useUserStore().user.roles &&
        useUserStore().user.roles?.includes('roadbook_access');
      if (!hasRole) {
        return 'no_permission';
      }
    }
    return null;
  }

  function handleOnboardingPro(to, next, must) {
    if (must.onboardingPro) {
      const hasRole =
      Object.keys(useUserStore().user).length > 0 && useUserStore().user.is_raccourci
      if (!hasRole) {
        return 'no_permission';
      }
    }
    return null;
  }

  async function handleSessions(to, next, must) {
    if (must.haveSession) {
      const noSession = useUserStore().noSessions;
      if (noSession === true) {
        return 'no_available_sessions';
      }
    }
    return null;
  }

  function handleOffice(to, next, must) {
    if (must.haveOffice) {
      const hasOffice = Object.keys(useConfigStore().office).length > 0;
      if (!hasOffice) {
        return 'offices';
      }
    }
    return null;
  }

  function handleInitialization(to, next, must) {
    if (must.beInitialized) {
      const isInitialized =
        !!useConfigStore().config && JSON.stringify(useConfigStore().config) !== '{}';
      if (!isInitialized) {
        return 'init';
      }
    }
    return null;
  }

export { handleInitialization, handleLogin, handleOffice, handlePermissions, handleSessions, handleOnboardingPro}
