import client from './apiConnector';
import { useErrorHandlerStore } from '@/store/errorHandler';
import { Config } from '@/models/interfaces/Config.interface';

export default {
  async getConfig(): Promise<Config> {
    try {
      const configResponse = await client().get('/config');
      configResponse.data.main_countries = configResponse.data.main_countries
        ? Object.keys(configResponse.data.main_countries)
        : ['FRA'];
      return configResponse.data;
    } catch (e: any) {
      console.error('API Error Get Config: ' + e);
      useErrorHandlerStore().throwError({
        title: 'get_datas_failure',
        detail: `log_error.${e.response.data.error}`,
        originalError: e.response,
      });
      return {};
    }
  },
};
