import { createApp, h, markRaw } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import './theme/css/tailwind.css';
import moment from 'moment';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import 'moment/locale/fr';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import RcSvg from '@/components/medias/RcSvg.vue';
import Vue3TouchEvents from 'vue3-touch-events';
import { i18n } from './utils/i18n';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/de';
import 'moment/dist/locale/es';
import { loader, outside } from './utils/directives';

const app = createApp({
  render: () => h(App),
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

app.use(pinia);
app.use(PerfectScrollbar);
app.use(router);
app.use(i18n);

app.component('VSelect', vSelect);

vSelect.props.components.default = () => ({
  Deselect: h(RcSvg, {
    class: 'remove-icon',
    name: 'croix',
  }),
  OpenIndicator: h(RcSvg, { name: 'down' }),
});

app.use(Vue3TouchEvents, {
  disableClick: true,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
  namespace: 'touch',
});

// Global properties
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$timeoutLongLoading = 0;

(app.config as any).productionTip = false;

// INIT DIRECTIVES
loader(app, i18n);
outside(app);

app.mount('#app');
