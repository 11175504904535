<template lang="pug">
#mobility-category(v-if="mobility_categories.length > 0")
  .category-select
    .select-box(
      v-for="mobility_category in mobility_categories"
      :key="mobility_category.slug"
      :class="{ selected: mobility_category.slug === selectedMobility }"
      @click="selectMobility(mobility_category, mobility_category.slug !== selectedMobility)"
    ) {{ getName(mobility_category.name) }}

</template>
<script lang="ts">
import { defineComponent } from 'vue';
import mixin from '@/utils/mixins/RcMixin.vue';
import { useConfigStore, useRoadbookStore } from '@/store';
import { Category, Traduction } from '@/models/interfaces';

export default defineComponent({
  mixins: [mixin],
  computed: {
    mobility_categories(): Array<Category> {
      return useConfigStore().config.mobility_categories || [];
    },
    selectedMobility(): string {
      return useRoadbookStore().metadata.visitor.mobility_category;
    },
  },
  methods: {
    selectMobility(mobility_category: Category, value: boolean): void {
      useRoadbookStore().updateMetadata({
        category: 'visitor',
        field: 'mobility_category',
        value: value ? mobility_category.slug : '',
      });
      useRoadbookStore().setRoadbookModifiedDate();
    },
    getName(name: Traduction): string {
      return name[this.native] ? name[this.native] : name.default;
    },
  },
});
</script>

<style lang="postcss" scoped>
#mobility-category {
  @apply flex relative overflow-hidden mb-[15px] max-w-full;

  &:after {
    @apply top-0 right-[-5px] absolute w-[9px] bg-white h-full content-[''] blur-[6px];
  }
  &:before {
    @apply top-0 left-[-5px] absolute w-[9px] bg-white h-full content-[''] blur-[6px] z-[1];
  }
  .left-arrow,
  .right-arrow {
    @apply fill-grey h-[25px] w-[10px] min-w-[10px] cursor-pointer;
  }
  .left-arrow {
    @apply mt-[9px] mr-[6px] mb-0 ml-[12px];
  }
  .right-arrow {
    @apply mt-[9px] mr-[12px] mb-0 ml-[6px];
  }
  .category-select {
    @apply flex items-center overflow-x-scroll scroll-smooth flex-wrap;
    .select-box {
      margin-top: 15px;
    }

    &::-webkit-scrollbar {
      @apply hidden;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .category {
      @apply font-light whitespace-nowrap text-[14px] w-auto cursor-pointer bg-transparent transition duration-[400ms] ease-in-out text-grey opacity-50 my-0 mx-[8px] px-[16px] py-[9px];
      @apply border-[2px] border-solid border-grey;

      &.selected {
        @apply opacity-100 text-primary border-[2px] border-solid border-primary;
      }

      @media (hover: hover) {
        &:hover {
          @apply opacity-100 transition duration-[400ms] ease-in-out text-primary border-[2px] border-solid border-primary;
        }
      }
    }
  }
  @media screen and (max-width: 840px) {
    .category-select {
      @apply w-[90%];
    }
  }
}
</style>
