import { Router, useRoute, useRouter } from 'vue-router';
import { initStore } from '@/utils/initStore';

const routes = [
  {
    path: '/no_available_sessions',
    name: 'no_available_sessions',
    component: () => import('@/views/Sessions/NoAvailableSessions.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
      },
    },
  },
  {
    path: '/unauthorized',
    name: 'no_permission',
    component: () => import('@/views/Sessions/UnauthorizedUser.vue'),
    meta: {
      must: {
        beLogged: true,
      },
    },
  },
  {
    path: '/init',
    name: 'init',
    async beforeEnter() {
      const router = useRouter();
      const route = useRoute();
      initStore().then(() => {
        if (route.name == 'init') router.push({ name: 'offices' });
      });
    },
    component: () => import('@/views/Sessions/InitSession.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
      },
    },
  },
];

export default function (router: Router) {
  routes.map((value) => router.addRoute(value));
}
