<template lang="pug">
#app(
  :class="{rotate}"
v-rc-loader="{ loading: !(userStore.user || !userStore.noSessions) || loggingOut || $route.name === null || userStore.users.length === 0 }",

)
  #version-number(v-if="!isMobile && !isRotate && !isMinWidth") {{'v' + appVersion}}
  //- #mobile-wrapper(v-if="isMobile")
  //-   #mobile
  //-     img(src="@/assets/rotate.png")
  //-     h2 {{ t("please", needTranslate) }}
  //-       span.line-break {{ t("please_mobile", needTranslate) }}
  //-     p {{ t("for_a_better_start", needTranslate) }}
  //-     p {{ t("for_a_better_exp_mobile", needTranslate) }}
  //-       span {{ t("for_a_better_exp_mobile_end", needTranslate) }}
  //-     p {{ t("for_a_better_exp_mobile2", needTranslate) }}
  //-       span {{ t("for_a_better_exp_mobile2_end", needTranslate) }}
  //- #rotate-wrapper(v-else-if="isRotate")
  //-   #rotate
  //-     img(src="@/assets/rotate.png")
  //-     h2 {{ t("please", needTranslate) }}
  //-       span.line-break {{ t("please_turn", needTranslate) }}
  //-     p {{ t("for_a_better_start", needTranslate) }}
  //-     p {{ t("for_a_better_exp", needTranslate) }}
  //-       span {{ t("for_a_better_exp_end", needTranslate) }}
  //- #minwidth-wrapper(v-else-if="isMinWidth")
  //-   #minwidth
  //-     rc-svg(name="onglet")
  //-     h2 {{ t("please", needTranslate) }}
  //-       span.line-break {{ t("please_big", needTranslate) }}
  //-     p {{ t("for_a_better_start", needTranslate) }}
  //-     p {{ t("for_a_better_exp", needTranslate) }} {{ t("for_a_better_exp_big", needTranslate) }}
  //-       span {{ t("for_a_better_exp_big_end") }}
  #principal-container(v-if="!isMobile && !isRotate && !isMinWidth")
    #page
      #header.white(
        :class="{ 'editMode' : ['roadbook_edit'].includes($route.name.toString()), 'clientInfoMode': ['client_info','preview'].includes($route.name.toString())}"
        v-if="$route.name &&  !['no_permission'].includes($route.name.toString())"
      )
        .user(:class="{'extend': (['client_info', 'preview'].includes($route.name) && !isQualif)}")
          rc-current-user(
            v-if="!isPro()"
            :nav-open="navOpen"
          )
          rc-current-user-pro(
            v-else
            :nav-open="navOpen"
          )
        .border-right(v-if="['roadbook_edit', 'client_info', 'preview'].includes($route.name)")
      #main-block(
        :class="{'specialTransition' : ($route.name === 'languages' || $route.name === 'channel')}"
      )
        .topBand.topBand--alert(:class="{'down' : preproductionVersion }")
          .topText {{ "Version de préproduction" }}
        .topBand(:class="{'down' : deprecatedVersion }")
          .topText {{ t("deprecated_version.please", native) }}
          .topText
            a(@click="() => logout()") {{ t("deprecated_version.reconnect", native) }}
          .topText {{ t("deprecated_version.for", native) }}
        router-view(v-slot="{ Component }")
            transition(name="page-transition")
                component.main-content(:is="Component" v-if="$route.name")
  #principal-container(v-else)
    #page
      router-view(v-slot="{ Component }")
        transition(name="page-transition")
          component.main-content(:is="Component" v-if="$route.name")
  rc-popup(:show="!!errorHandlerStore.error.title" :title-text="errorHandlerStore.error.title || t('warning', native)" :centered-footer="true"  @close="closeErrorPopup()" :icon-name="'exclamation'")
        template(#desc)
          p.description(v-if="errorHandlerStore.error.detail" v-html="errorHandlerStore.error.detail")
          p.description(v-else) {{ t( 'log_error.ErrorStandard', native) }}
          hr(v-if="errorHandlerStore.countdown > 0")
          p(v-if="errorHandlerStore.countdown > 0" v-html="`Redirection dans ${errorHandlerStore.countdown} seconde${errorHandlerStore.countdown >= 2 ? 's' : ''}.`" class="pt-0 pb-10 max-w-[600px] text-[21px]")
  rc-popup(
:show="isOpen" :title-text="t('not_connected.title')"
    :centered-footer="true" :footer-button-text="t('not_connected.btn')"
    :description="t('not_connected.desc')" @close="restart")
  rc-alert(
    :show="unsavedChanges" :title-text="t('licences.unsaved.title')" :max-width-desc="'800px'" :icon-name="'exclamation'"
    :description="t('licences.unsaved.desc') + ' ' + t('licences.unsaved.desc2')"
    @confirm="quitWithoutSaving" :footer-confirm-button-text="t('licences.unsaved.quit')")
    template(#cancel-btn)
      .btn.quit(@click="unsavedChanges = false") {{ t('licences.unsaved.stay') }}
  rc-modalPWA.pwa(@close="closeBanner()" v-if="banner" :show="banner")
    .pwa-wrapper
      img.logo-roadbook(
        src="./../public/roadbook-favicon-rounded.png"
      )
      h2 {{ t("pwa.title") }}
      .desc(v-html="checkOnDevice()[0] ? t('pwa.mobile') : t('pwa.desc')")
      RdbkButton(:mode="'outline'" @is-clicked="installPWA" :icon-name="'next'" :label="t('pwa.install')")
      .close(@click="closeBanner()") {{ t("close") }}
  teleport(to="body")
    rc-calendar
</template>

<script setup lang="ts">
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import {
  useErrorHandlerStore,
  useConfigStore,
  usePlaylistStore,
  useRoadbookStore,
  useLeafletsStore,
  useLicencesStore,
  useUserStore,
  useTagManagerStore,
  useSheetsStore,
  useGtmDataGetterStore,
  useCalendarStore,
} from '@/store';
import { ref, provide, computed, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import type { Ref } from 'vue';
import RcModalPWA from '@/components/navigations/RcModalPWA.vue';

import RcCurrentUser from '@/components/navigations/RcCurrentUser.vue';
import RcCurrentUserPro from '@/components/navigations/RcCurrentUserPro.vue';
import RcAlert from '@/components/feedbacks/RcAlert.vue';
import RcCalendar from '@/components/inputs/pickers/calendar/RcCalendar.vue';
//import RcSvg from '@/components/medias/RcSvg.vue';
import RcPopup from '@/components/feedbacks/RcPopup.vue';
import RdbkButton from '@/components/inputs/RdbkButton.vue';
import { useRoute, useRouter } from 'vue-router';
import type { NavigationFailure } from 'vue-router';
import { useMixin } from './utils/mixins/useMixin.mixin';
import { deleteRoadBook, isPro } from './utils/functions/utils';

// General
provide('$moment', moment);
const { t, te, locale } = useI18n();
provide('$t', t);
provide('$te', te);
const roadbookStore = useRoadbookStore();
const configStore = useConfigStore();
const leafletsStore = useLeafletsStore();
const calendarStore = useCalendarStore();
const errorHandlerStore = useErrorHandlerStore();
const gtmDataGetterStore = useGtmDataGetterStore();
const licencesStore = useLicencesStore();
const playlistStore = usePlaylistStore();
const sheetsStore = useSheetsStore();
const tagManagerStore = useTagManagerStore();
const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const { native, checkOnDevice } = useMixin();

// Data
const navOpen: Ref<boolean> = ref(false);
const isOpen: Ref<boolean> = ref(false);
const unsavedChanges: Ref<boolean> = ref(false);
const banner: Ref<boolean> = ref(false);
const deferredPrompt: Ref<any> = ref(null);
const isMinWidth: Ref<boolean> = ref(false);
const isRotate: Ref<boolean> = ref(false);
const isMobile: Ref<boolean> = ref(false);
const rotate: Ref<boolean> = ref(false);
const idleStartDate: Ref<string> = ref(new Date().toISOString());
const appVersion: Ref<string> = ref(useUserStore().version);

// Computed
const isQualif = computed(() => roadbookStore.metadata.is_qualif);
const loggingOut = computed(() => userStore.loggingOut);
const deprecatedVersion = computed(() => userStore.isDeprecatedVersion);
const preproductionVersion = computed(() => userStore.user.is_preprod);

const isPWA = computed(() => {
  return ['fullscreen', 'standalone', 'minimal-ui'].some(
    (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches,
  );
});

// Hooks
onBeforeMount(() => {
  roadbookStore.$reset;
  configStore.$reset;
  leafletsStore.$reset;
  calendarStore.$reset;
  errorHandlerStore.$reset;
  gtmDataGetterStore.$reset;
  licencesStore.$reset;
  playlistStore.$reset;
  sheetsStore.$reset;
  tagManagerStore.$reset;
  userStore.$reset;
  ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((evt) =>
    document.addEventListener(evt, resetIdleTimer, false),
  );
});

onBeforeMount(() => {
  window.addEventListener('popstate', handleBrowserNavigation);
});

onMounted(() => {
  if (userStore.user.login === 'nicolas.roy@raccourci.fr') {
    rotate.value = true;
  }
  moment.locale(native.value);
  if (window.visualViewport) {
    window.visualViewport.addEventListener('resize', checkIfIpad);
  } else {
    window.addEventListener('resize', checkIfIpad);
  }
  checkIfIpad();
  if (!navigator.onLine) {
    isOpen.value = true;
  }

  if (!isPWA.value) {
    // check if user has already refused to install PWA
    let refused = false;
    const cookieName = 'pwarefused';
    document.cookie.split(';').forEach((cookie) => {
      if (cookie.includes(cookieName)) {
        refused = true;
      }
    });

    if (!refused) {
      window.addEventListener('appinstalled', () => {
        console.log('app has been installed on desktop !');
        banner.value = false;
      });

      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        deferredPrompt.value = e;

        window.addEventListener('officeViewReady', () => {
          banner.value = true;
          window.removeEventListener('officeViewReady', () => {
            return;
          });
        });
      });
    }
  }
  if (!window.ga_id) {
    window.ga_id = configStore?.config && configStore.config.ga_id ? configStore.config.ga_id : '';
  }

  setInterval(
    function () {
      updateSession();
      handleRoadbook();
      checkIdleTimer();
    }.bind(this),
    60000,
  );
  const user = useUserStore().users.filter(
    (u) => u.contact_id == useUserStore().user.contact_id,
  )[0];
  if (user?.original_language_code) {
    locale.value = user.original_language_code || 'fr';
  }
  document.documentElement.style.setProperty('--screenHeight', `${screen.height}px`);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkIfIpad);
  window.removeEventListener('popstate', handleBrowserNavigation);
  ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((evt) =>
    document.removeEventListener(evt, resetIdleTimer, false),
  );
});

// Methods
function closeErrorPopup(): void {
  errorHandlerStore.countdown = 0;
}

function resetIdleTimer() {
  idleStartDate.value = new Date().toISOString();
}

function checkIdleTimer() {
  const maxTimer = 240; // 4 hours = 240 minutes
  const now = new Date();
  const diffDate = now.getTime() - Date.parse(idleStartDate.value);
  const diffMinutes = Math.floor(diffDate / (1000 * 60));
  if (diffMinutes <= maxTimer) {
    console.warn(
      `Idling from ${diffMinutes} minutes, ${Math.floor(maxTimer - diffMinutes)} minutes left.`,
    );
  } else {
    userStore.logout();
  }
}

async function handleRoadbook(): Promise<NavigationFailure | void | null> {
  const hasRoadbook = !!roadbookStore.id;
  const lastModified = roadbookStore.last_modified_date;
  if (hasRoadbook && !!lastModified) {
    const now = new Date();
    const hasExpired = (now.getTime() - Date.parse(lastModified)) / (1000 * 60 * 60) > 1.5;
    if (hasExpired) {
      if (useRoadbookStore().id !== null && !useRoadbookStore().metadata.is_finished) {
        deleteRoadBook();
      }
      useRoadbookStore().resetRoadbook();
      return router.push('/languages');
    }
  }
  return null;
}

function checkIfIpad(event?: any): void {
  const scale: number = event?.target?.scale ? event?.target.scale : 1;
  const [isOnDevice, isOnLandscape] = checkOnDevice();
  const width: number = window.innerWidth;
  isMinWidth.value = isRotate.value = isMobile.value = false;
  isMobile.value =
    (isOnDevice && isOnLandscape && width < 950 && scale == 1) ||
    (isOnDevice && !isOnLandscape && width < 800);
  isRotate.value = isOnDevice && !isOnLandscape && width > 800 && !isMinWidth.value;
  isMinWidth.value =
    ((width < 950 && !isOnDevice) || (width < 850 && isOnDevice && scale == 1)) &&
    !isMinWidth.value &&
    !isMobile.value;
  document.documentElement.style.setProperty('--screenHeight', `${screen.height}px`);
}

function updateSession(): void {
  userStore.updateSession();
}

function restart(): void {
  roadbookStore.restart();
  leafletsStore.setLastDatePicked({ from: '', to: '' });
  playlistStore.emptyDatePresets();
  window.location.reload();
}

function quitWithoutSaving(): void {
  unsavedChanges.value = false;
  router.push({
    path: window.history.state.back,
    params: {
      no_reload: true,
    },
  } as any);
}

function installPWA(): void {
  deferredPrompt.value.prompt();
  deferredPrompt.value.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === 'accepted') {
      console.log('user accepted the A2HS prompt');
    }
    deferredPrompt.value = null;
  });
}

function closeBanner(): void {
  banner.value = false;

  // set cookie
  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  const expires: string = 'expires' + date.toUTCString();
  document.cookie = 'pwarefused' + '=1;' + expires + ';path=/';
}

async function logout(): Promise<void> {
  userStore.logout();
}

function handleBrowserNavigation(): void {
  if (
    !roadbookStore.id &&
    ['roadbook_edit', 'client_info', 'preview'].includes(route.name as string)
  ) {
    router.push('/'); //Hard reset browser state on back/forward browser button click
  }
}
</script>

<style lang="postcss">
@import '@/theme/css/roadbook_icon_font.css';
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-primary rounded-small;
}

.checkbox-indicator:after {
  @apply border-solid border-white;
  left: 8px;
  top: 3px;
  width: 4px;
  height: 10px;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.v-select {
  &.vs--open {
    .vs__dropdown-toggle {
      @apply rounded-t-large rounded-b-none;
    }

    .vs__actions {
      border-radius: 0 theme('borderRadius.large') 0 0 !important;
    }
  }

  .vs__dropdown-menu {
    border-radius: 0 0 theme('borderRadius.large') theme('borderRadius.large');
    width: 100%;
  }
}
.vs__dropdown-menu {
  @apply !bg-white-grey !max-w-[360px] !max-h-[220px];
  animation: fadeIn 500ms ease-in-out;
  border: none !important;
  font-family: Poppins !important;
  box-shadow: none;
  overflow: scroll;

  .vs__dropdown-option {
    @apply !bg-white-grey transition duration-300;
    font-family: Poppins !important;
    display: flex;
    align-items: center !important;
    align-content: center !important;

    @media (hover: hover) {
      &--highlight {
        color: theme('colors.primary') !important;
        background: theme('colors.white-grey') !important;
      }
    }

    .country-flag {
      @apply min-w-[24px] h-[16px] my-[4px] mr-[8px] ml-0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .vs__dropdown-option--selected {
    @apply !bg-ternary-blue;
  }
  .vs__no-options {
    padding: 16px 0 !important;
  }
}
.separator {
  @apply bg-grey/30 w-[40px] h-[1px] mx-auto my-0;
}

.ps-container > .ps-scrollbar-x-rail,
.ps-container > .ps-scrollbar-y-rail {
  opacity: 1 !important;
}
.ps__rail-x {
  @apply h-0;
}
.ps__rail-y,
.ps__rail-x {
  opacity: 1 !important;
  background-color: theme('colors.white-grey') !important;
  @apply rounded-small;
}
.ps__rail-y {
  width: 10px !important;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  @apply cursor-grab;
}
.ps__thumb-x,
.ps__thumb-y {
  background-color: theme('colors.primary') !important;
  opacity: 1 !important;
}
.ps__thumb-y {
  width: 6px !important;
}
#app {
  @apply w-full relative flex items-center text-grey bg-white-grey flex-col h-full;
  font-family: Poppins, sans-serif;

  @media screen and (max-height: theme('screens.sm-tablet')) {
    overflow: auto;
    height: calc(var(--screenHeight, 100vh));
  }

  .ps {
    @apply w-full h-full;

    &.scroll-transition {
      @apply scroll-auto;
    }

    &:has(.findRoadBOOKContainer) {
      @apply flex flex-col gap-4;
      padding: 0 1rem;
    }
  }

  #version-number {
    @apply absolute bottom-[10px] left-[25px] z-[35] text-xs;
  }
  #offices,
  #users,
  #licences-container,
  #languages {
    .ps__rail-y {
      @apply mx-0 my-standard;
    }
  }

  #mobile-wrapper {
    @apply flex items-center top-0 bottom-0 right-0 left-0 absolute z-30 max-w-[800px] mx-auto my-0 backdrop-blur-[5px];

    #mobile {
      @apply block mx-auto my-0 bg-white h-[500px] w-[90%] box-border p-[20px] text-center rounded-normal;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      h2 {
        @apply text-[25px] text-primary max-w-[380px] mx-auto mt-[40px] mb-standard leading-[1];

        .line-break {
          @apply mt-[-14px] mb-[5px];
        }
      }

      img {
        @apply h-[105px] w-[150px] block mx-auto mb-0 mt-[70px];
      }

      p {
        @apply mb-0 mt-0 text-[16px];

        span {
          @apply font-semibold;
        }
      }
    }
  }

  #rotate-wrapper {
    @apply flex items-center top-0 bottom-0 right-0 left-0 absolute z-30 max-w-[800px] mx-auto my-0 backdrop-blur-[5px];

    #rotate {
      @apply block mx-auto my-0 bg-white h-[500px] w-[90%] box-border p-standard text-center rounded-normal;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      h2 {
        @apply text-[25px] text-primary leading-[1] max-w-[380px] mx-auto mb-standard mt-[40px];

        .line-break {
          @apply mt-[-14px] mb-[5px];
        }
      }

      img {
        @apply h-[140px] w-[200px] block mx-auto mb-0 mt-[70px];
      }

      p {
        @apply mb-0 mt-0 text-[16px];

        span {
          @apply font-semibold;
        }
      }
    }
  }

  #minwidth-wrapper {
    @apply flex items-center top-0 left-0 right-0 bottom-0 absolute z-30 max-w-[800px] mx-auto my-0 backdrop-blur-[5px];

    #rotate,
    #minwidth {
      @apply block mx-auto my-0 bg-white h-[500px] w-[90%] box-border p-[20px] text-center rounded-normal;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      h2 {
        @apply text-[25px] text-primary mt-[40px] mx-auto mb-standard leading-[1.3] max-w-[380px] font-bold;

        .line-break {
          @apply mt-[-14px] mb-[5px];
        }
      }

      svg {
        @apply h-[140px] w-[200px] block mx-auto mb-0 mt-[70px];

        g {
          @apply fill-light-grey;
        }
      }

      p {
        @apply text-[16px] max-w-[65vw] mx-auto my-0;

        span {
          @apply font-semibold;
        }
      }
    }
  }

  .online,
  .warning-licences {
    @apply flex flex-col;
  }

  #channel-category {
    @apply bg-white;
  }

  #page {
    @apply flex flex-col w-full overflow-hidden bg-white-grey h-[100vh] relative;

    @media screen and (min-width: 950px) {
      @apply flex-row;
    }
  }

  #principal-container {
    #channel_category {
      @apply z-10;
    }
    @apply w-full flex h-[100vh] overflow-hidden relative;
    max-height: -webkit-fill-available;

    &.popup-mode {
      @apply blur-[2px];
    }
    &.principal-blur {
      @apply blur-[5px] pointer-events-none;

      #modal,
      #popup {
        @apply blur-[5px];
      }
    }
  }
  .topBand {
    @apply w-full bg-primary justify-center flex-row px-0 py-[10px] hidden transition duration-300 ease-in-out z-[5];

    &--alert {
      @apply bg-red-no-licences;

      .topText {
        @apply !text-[22px];
      }
    }

    &.down {
      display: inherit;
      animation: 0.7s slidedown;
      @-webkit-keyframes slidedown {
        from {
          transform: translateY(-50px);
        }
        to {
          transform: translateY(-0px);
        }
      }
      @keyframes slidedown {
        from {
          transform: translateY(-50px);
        }
        to {
          transform: translateY(-0px);
        }
      }
    }
    .topText {
      @apply text-white text-[12px];

      a {
        @apply my-0 mx-[4px] cursor-pointer border-b-[1px];
      }
    }
  }
  #header {
    @apply bg-white w-[85px] box-border flex flex-wrap flex-col z-10 h-full relative;
    box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.015);

    .border-right {
      @apply bg-light-grey/50 w-[1px] h-full relative;
    }

    .user {
      @apply box-border flex items-center justify-end pb-[45px] mt-auto w-full h-full bg-white;
    }
  }
  .toogle {
    @apply mx-auto mb-0 mt-[30px] w-fit h-[30px] leading-[30px] flex justify-center rounded-large opacity-100 relative;
    background: theme('colors.white-grey') 0 0 no-repeat padding-box;

    .slider {
      @apply w-[155px] h-[30px] leading-[30px] bg-grey rounded-large text-white-grey transition-[margin] duration-[400] ease-in-out absolute left-0;
    }
    p {
      @apply leading-[0] z-[5] text-middle-grey opacity-100 py-0 px-[35px] m-auto cursor-pointer transition-[color] duration-700 w-[78px] text-center;

      @media (hover: hover) {
        &:hover {
          @apply text-primary transition-[color] duration-700;
        }
      }

      &.selected {
        @apply pointer-events-none text-white-grey;
      }

      &.left.selected {
        + .right {
          + .slider {
            @apply ml-0;
          }
        }
      }

      &.right.selected {
        + .slider {
          @apply ml-[140px];
        }
      }
    }
  }
  #main-block {
    @apply overflow-x-hidden bg-white w-full;

    &.specialTransition {
      @apply bg-white-grey;
    }
  }

  .main-content {
    @apply flex-1 overflow-hidden;

    &:has(.selection) {
      @apply flex items-center justify-center p-0;
    }
  }
}

@media screen and (min-width: 950px) {
  #app {
    @apply overflow-hidden;

    #minwidth-wrapper {
      display: none !important;
    }
  }
}

.click {
  @apply cursor-pointer;
}
.bold {
  @apply font-bold;
}
.error {
  @apply bg-error-color;
}
.white {
  @apply bg-white;
}
.error--text {
  @apply text-error-color;
}
.white--text {
  @apply text-white;
}
.lightgrey {
  @apply bg-white-grey;
}
.arounded {
  @apply rounded-normal;
}
.round {
  @apply rounded-[50%];
}
.main-route {
  @apply flex flex-col;
}
.route-title {
  @apply text-primary text-center m-[60px] text-[26px];
}

.pwa {
  .modal-container {
    @apply text-center bg-primary text-white rounded-large;
    overflow: initial;
    width: 640px !important;

    .close svg {
      @apply fill-white stroke-primary;
    }
  }

  .pwa-wrapper {
    @apply relative flex flex-col;
    h2 {
      @apply font-bold m-standard;
    }
    .rdbkButton {
      @apply mx-auto my-[5%];
      &:hover {
        color: theme('colors.primary');
        border-color: theme('colors.primary');
        background: white;

        .svg-icon {
          fill: theme('colors.primary');
        }
      }
    }
  }

  .logo-roadbook {
    @apply h-[80px] absolute top-[-80px];
    left: calc(50% - 40px);
  }

  .desc {
    @apply max-w-[400px] m-auto;
  }

  .close {
    @apply cursor-pointer;
  }

  .arrow-right {
    @apply fill-primary ml-[6px] w-[16px];
  }
}

body {
  @apply m-0 h-[100vh];
  max-height: -webkit-fill-available;
}

.page-transition-enter-active {
  transition: opacity 0.6s ease-in-out;
}

.page-transition-enter-from,
.page-transition-leave-to {
  @apply opacity-0;
}

.arrow-left {
  @apply my-0 mr-0 ml-[8px] stroke-white stroke-[1px];
  width: 14px !important;
}

.btn {
  &.quit {
    @apply transition duration-300 text-grey px-0 py-[20px];

    @media (hover: hover) {
      &:hover {
        @apply text-primary cursor-pointer;
      }
    }
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes arrowslide {
  to {
    transform: translateX(6px);
  }
}
@-moz-keyframes finisharrowslide {
  from {
    transform: translateX(6px);
  }
  to {
    transform: translateX(0px);
  }
}
@-webkit-keyframes finisharrowslide {
  from {
    transform: translateX(6px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes finisharrowslide {
  from {
    transform: translateX(6px);
  }
  to {
    transform: translateX(0px);
  }
}

.load-wrapper.load-content.choice-load {
  .isPending:after {
    border-top: 6px solid theme('colors.primary') !important;
  }
  .isPending.small:after {
    border-top: 3px solid theme('colors.primary') !important;
  }
}

.long-load-content {
  @apply hidden;

  &.disabled {
    display: none !important;
  }
}

.load-wrapper.load-content {
  @apply relative pointer-events-none;
  > *:not(.isPending) {
    @apply opacity-0;
  }

  .isPending {
    @apply pointer-events-none;

    p {
      @apply absolute opacity-100;
      left: 50%;
      top: 60%;
      transform-origin: 50%;
      transform: translate(-50%, -50%);
    }
    .long-load-content {
      @apply flex flex-row justify-between items-center opacity-0 absolute p-0 overflow-hidden h-[40px] w-[230px] list-none;
      top: calc(50% + 50px);
      left: calc(50% - 96px);

      &.active {
        @apply transition duration-500 opacity-100;
        img {
          @apply absolute opacity-0 top-0 left-0 ml-[110px] transition-[transform] duration-300 ease-in-out;

          &.active {
            @apply opacity-100 animate-[change];
            animation-duration: 2s;
          }

          &.out {
            @apply opacity-100 animate-[out];
            animation-duration: 2s;
          }
        }
      }

      p {
        @apply mx-0 my-[10px];
      }
    }
  }
  .isPending > * {
    @apply opacity-0;
  }
  .isPending:after {
    @apply content-[''] absolute left-[50%] rounded-[50%] z-[5];
    top: var(--topOffset);
    -webkit-animation-name: spin;
    -moz-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    -moz-animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  .isPending.white:after {
    border-color: rgba(255, 255, 255, 0.2) !important;
    border-top-color: rgba(255, 255, 255, 0.4) !important;
  }
  .isPending:after {
    @apply w-[60px] h-[60px] ml-[-30px] mt-[-30px];
    border: 6px solid rgba(0, 0, 0, 0.2);
    border-top: 6px solid theme('colors.primary');
    -moz-box-shadow: 0 0 100px rgba(255, 255, 255, 0.12);
    box-shadow: 0 0 100px rgba(255, 255, 255, 0.12);
  }
  .isPending.small:after {
    @apply w-[30px] ml-[-30px] mt-[-30px];
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-top: 3px solid theme('colors.primary');
  }

  @keyframes change {
    0%,
    50% {
      transform: translateY(100%);
    }
    75%,
    100% {
      transform: translateY(0);
    }
  }
  @keyframes out {
    0%,
    50% {
      transform: translateY(0);
    }
    75%,
    100% {
      transform: translateY(-100%);
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  @apply transition duration-[600ms] ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  @apply transition duration-300 ease-in-out;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  @apply opacity-0;
}
.slide-fade-reverse-enter-active,
.slide-fade-reverse-leave-active {
  @apply transition duration-300 ease-in-out;
}
.slide-fade-reverse-enter-from,
.slide-fade-reverse-leave-to {
  transform: translateY(-20px);
  @apply opacity-0;
}
@media (prefers-reduced-motion: reduce) {
  .fade-enter-active,
  .fade-leave-active {
    @apply transition-none;
  }
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    @apply transition-none;
  }
  .slide-fade-reverse-enter-active,
  .slide-fade-reverse-leave-active {
    @apply transition-none;
  }
}
#app.rotate {
  transform: rotate(180deg);
}
</style>
