import { createWebHashHistory, createRouter, RouterOptions } from 'vue-router';
import { useUserStore } from '@/store';
import {
  handleInitialization,
  handleLogin,
  handleOffice,
  handlePermissions,
  handleSessions,
  handleOnboardingPro,
} from './handler';
import connectService from '@/services/api/connectService';

// Others routes imports
import RoadBOOK from '@/views/RoadBOOK/router';
import Sessions from '@/views/Sessions/router';
import { initStore } from '@/utils/initStore';

// DEFINE GLOBAL ROUTES
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      return Object.keys(useUserStore().user).length > 0
        ? { name: 'offices' }
        : { name: 'callback' };
    },
  },
  // {
  //   path: '/mail',
  //   name: 'mail',
  //   component: () => import('@/views/TemplateMail.vue'),
  //   meta: {
  //     must: {
  //       beLogged: true,
  //       havePermission: true,
  //       haveSession: true,
  //       beInitialized: true,
  //       onboardingPro: true,
  //     },
  //   },
  // },
  {
    path: '/login',
    name: 'login',
    beforeEnter() {
      window.sessionStorage.clear();
      window.location.assign(connectService.getLoginUrl());
    },
  },
  {
    path: '/callback',
    name: 'callback',
    async beforeEnter() {
      useUserStore().setVersion(__APP_VERSION__);
      window.sessionStorage.clear();
      return {
        name: 'init',
      };
    },
  },
  {
    path: '/advisors',
    name: 'advisors',
    component: () => import('@/views/Advisors/RcAdvisors.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
        beInitialized: true,
      },
    },
  },
  {
    path: '/licences',
    name: 'licences',
    component: () => import('@/views/Licences/LicencesVue.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
      },
    },
  },
  {
    path: '/licences-pro',
    name: 'licences-pro',
    component: () => import('@/views/Licences/LicencesPro.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
      },
    },
  },
  {
    path: '/webhook',
    name: 'webhook',
    component: () => import('@/views/Webhook/RcWebhook.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
      },
    },
  },
  {
    path: '/qr-code',
    name: 'qr-code',
    async beforeEnter() {
      await initStore();
    },
    component: () => import('@/views/QRCode/QRCode.vue'),
    meta: {
      previous: '/offices',
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
      },
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/Report/RcReport.vue'),
    meta: {
      must: {
        beLogged: true,
        havePermission: true,
        haveSession: true,
      },
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
} as RouterOptions);

// ADD OTHERS ROUTES
RoadBOOK(router);
Sessions(router);

// ADD ROUTER CHECK
router.beforeEach(async (to, from, next) => {
  let redirectRoute: any = null;
  const conditions = (to.meta && to.meta.must) || {};
  redirectRoute = await handleLogin(to, next, conditions);
  redirectRoute = redirectRoute || handlePermissions(to, next, conditions);
  redirectRoute = redirectRoute || (await handleSessions(to, next, conditions));
  redirectRoute = redirectRoute || handleOffice(to, next, conditions);
  redirectRoute = redirectRoute || handleInitialization(to, next, conditions);
  redirectRoute = redirectRoute || handleOnboardingPro(to, next, conditions);
  redirectRoute ? next({ name: redirectRoute, replace: true }) : next();
});

export default router;
